import React, { useContext, useEffect, useState, useRef } from "react";
import { Modal, Button, Spinner, Row, Col } from "react-bootstrap";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { post } from "utils/DeApi";
import { UserContext } from "contexts/UserProvider";
import VoteInstruction from "./VoteInstruction";

const voteOptions = ["100%", "75%", "50%", "25%", "0%", "Conflict of interest"];

const previousVotes = [
  "Likely to raise $500k",
  "Likely to raise some money",
  "50% chance of raising money",
  "25% chance of raising money",
  "Unlikely to raise money",
  "Conflict of interest",
];

function VoteCaster({ deal }) {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const user = useContext(UserContext);
  const [val, setVal] = useState();
  const [dealVote, setDealVote] = useState("");
  const subscribedPromises = useRef([]);

  const handleClose = () => {
    setShow(false);
    setError();
    setVal();
  };
  const handleShow = () => setShow(true);

  const sentVote = () => {
    setIsLoading(true);
    setError();
    const createVote = post("/votes", {
      venture_id: deal?.id,
      venture_round_id: deal?.currentVentureRound?.id,
      member_uuid: user?.member?.id || user?.id,
      choice: val,
    });
    createVote.promise
      .then((response) => {
        setDealVote(val);
        setIsLoading(false);
        setError();
        handleClose();
      })
      .catch((error) => {
        setIsLoading(false);
        setVal();
        setError(error);
      });
    subscribedPromises.current.push(createVote);
  };

  useEffect(() => {
    setDealVote(
      deal?.currentVentureRound?.votes?.filter(
        (vote, key) =>
          vote?.memberUuid === user.id ||
          vote.memberUuid === user.memberUuid ||
          vote.memberUuid === user?.member?.id
      )?.[0]?.choice
    );
  }, [deal]);

  return (
    <>
      {dealVote && !Number(deal?.currentVentureRound?.isRoundClosed) ? (
        <div className="text-dark d-flex justify-content-center flex-row">
          <strong>Choice:</strong>
          <p className="px-1">{dealVote}</p>
        </div>
      ) : (
        ""
      )}
      {!Number(deal?.currentVentureRound?.isRoundClosed) ? (
        <div className="mb-2 d-flex justify-content-center flex-column">
          <Button
            variant="outline-primary"
            size="sm"
            className="text-decoration-none"
            onClick={() => handleShow()}
          >
            Vote
          </Button>
        </div>
      ) : (
        ""
      )}

      <Modal show={show} backdrop={"static"} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="text-primary">
            Nominate for Presentation Slot
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <p>
              How likely do you feel investors (not necessarily you) will fill
              this round? Your answer helps us select which companies get
              presentation slots.
            </p>
            <VoteInstruction />
            <Row>
              {voteOptions.map((value, index) => {
                return (
                  <Col lg={6} key={index}>
                    <Button
                      key={index}
                      variant={
                        val
                          ? val === value
                            ? "primary text-white"
                            : "outline-primary"
                          : dealVote === value ||
                            dealVote === previousVotes[index]
                          ? "primary text-white"
                          : "outline-primary"
                      }
                      className={`my-2 w-100`}
                      onClick={() => {
                        setVal(value);
                      }}
                    >
                      {value}
                    </Button>
                  </Col>
                );
              })}
            </Row>
          </div>
          {error && <ErrorHandler error={error} />}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="link" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={sentVote}
            disabled={!val}
          >
            Vote{"  "}
            {isLoading && (
              <Spinner
                animation="border"
                variant="white"
                size="sm"
                className="ms-3"
              />
            )}
            {error && (
              <span className="material-icons-outlined md-18 text-danger">
                error
              </span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default VoteCaster;
