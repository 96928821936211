import React, { useEffect, useState, useContext } from "react";
import { Alert } from "react-bootstrap";

import "./ErrorHandler.scss";
import { useNavigate, Link } from "react-router-dom";
import { logout } from "utils/BeeApi";
import { UserDispatchContext } from "contexts/UserProvider";
import { UserContext } from "contexts/UserProvider";

const ErrorHandler = ({ error, message, className = "" }) => {
  const [status, setStatus] = useState("");
  const [statusText, setStatusText] = useState("Oh snap! You got an error!");
  const [errorMsg, setErrorMsg] = useState(
    "Something unexpected happened. Please try again."
  );
  const navigate = useNavigate();
  const setUser = useContext(UserDispatchContext);
  const user = useContext(UserContext);
  useEffect(() => {
    if (error) {
      if (error.status) {
        setStatus(error.status);
      }
      if (error.statusText) {
        setStatusText(error.statusText);
      }
      if (error.status === 500) {
        logout();
        setUser(null);
      }
      if (error.status === 401 && user !== null) {
        navigate("/logout");
      }
      if (error.data && error.data.error) {
        if ("message" in error.data.error) {
          setErrorMsg(error.data.error.message);
        } else {
          setErrorMsg(error.data.error[Object.keys(error.data.error)[0]]);
        }
      }
    }
  }, [error]);

  if (status === 403) {
    return (
      <Alert variant="success" className={`bg-white mt-5 mb-3 ${className}`}>
        <h4>Unauthorized</h4>
        <p>Sorry, you do not have access rights!</p>
        <small>{status}</small>
      </Alert>
    );
  }
  if (message?.status === 404) {
    return (
      <Alert variant="warning" className={`mt-3 mb-3 ${className}`}>
        <p>{message?.data?.error || message?.error}</p>
      </Alert>
    );
  } else {
    return (
      <Alert variant="warning" className={`mt-3 mb-3 ${className}`}>
        {typeof message === "string" && (
          <h4>
            {!message?.includes(
              "does not currently have investor access. Use the link below to request access"
            ) && statusText}
          </h4>
        )}
        <p>
          {typeof message === "string"
            ? message
            : message?.data?.message || errorMsg}
        </p>
        <small>{status || message?.status}</small>
      </Alert>
    );
  }
};

export default ErrorHandler;
