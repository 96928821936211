import { useState, useMemo, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import countryList from "react-select-country-list";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import FormLabel from "react-bootstrap/FormLabel";
import Button from "react-bootstrap/Button";

import { Formik } from "formik";
import CustomSelect from "components/App/Formik/CustomSelect";
import CustomFormControl from "components/App/Formik/CustomFormControl";
import CustomFormCheck from "components/App/Formik/CustomFormCheck";
import CustomPhoneInput from "components/App/Formik/CustomPhoneInput";
import CustomNumericFormat from "components/App/Formik/CustomNumericFormat";

import { get, post } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { UserContext } from "contexts/UserProvider";

import { validationSchema } from "./schema";
import {
  stateOptions,
  specialInterestQualifications,
  securityTypes,
  committedPercentage,
} from "shared/constants/venture";

import Header from "components/App/Header/Header";
import Footer from "components/App/Footer/Footer";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";

const CreateProfile = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState();
  const [formValues, setFormValues] = useState({});
  const [memberDetails, setMemberDetails] = useState();
  const [syndicationGroup, setSyndicationGroup] = useState();

  const subscribedPromises = useRef([]);

  const user = useContext(UserContext);

  const navigate = useNavigate();
  const countries = useMemo(() => countryList().getData(), []);

  useEffect(() => {
    setIsLoading(true);
    const fetchVentures = get(`/ventures`);
    const fetchSyndicationGroups = get(`/nominations/${user?.email}`);

    Promise.all([fetchVentures.promise, fetchSyndicationGroups.promise])
      .then(([venturesResponse, syndicationGroupsResponse]) => {
        if (!syndicationGroupsResponse?.data?.syndicationGroups?.length) {
          navigate("/");
        } else {
          setSyndicationGroup(
            syndicationGroupsResponse?.data?.syndicationGroups
          );
          setMemberDetails(syndicationGroupsResponse?.data?.member);

          // find current venture in venture list
          // if it is already created, we will redirect to entreprenuer dashboard
          const currentVenture = venturesResponse?.data?.find(
            (venture) => venture?.createdBy === user?.userId
          );
          currentVenture && navigate(`/ventures/${currentVenture.id}`);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(fetchSyndicationGroups);

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  // it will toggle and inverse the modal state
  const toggleModal = () => setIsShowModal((prevState) => !prevState);

  function convertAmountStringToNumber(amountString) {
    const cleanedString = amountString.replace(/[$,]/g, "");

    return parseFloat(cleanedString);
  }

  const submitData = (values) => {
    setIsSubmitting(true);
    const createProfile = post("/ventures", {
      firstName: values.firstName,
      lastName: values.lastName,
      amount: convertAmountStringToNumber(values.amount),
      ceoName: values.ceoName,
      committed: values.committed,
      companyWebsite: values.companyWebsite,
      companyName: values.companyName,
      country: values?.country,
      state: values.state,
      executiveSummaryLink: values?.executiveSummaryLink,
      pitchVideoLink: values?.pitchVideoLink,
      dealRoomLink: values?.dealRoomLink,
      gustProfileLink: values?.gustProfileLink,
      slideDeckLink: values.slideDeckLink,
      dealLeadEmail: values.dealLeadEmail,
      dealLeadName: values.dealLeadName,
      mobileNumber: values.mobileNumber,
      emailAddress: values.emailAddress,
      description: values.description,
      valuation: convertAmountStringToNumber(values.valuation),
      dealLeadGroup: values.dealLeadGroup,
      qualification: values.qualification,
      other: values.reason,
      dealStatus: values.dealStatus,
      securityType: values.securityType,
      isRound: false,

      currentVentureRound: {
        securityType: values.securityType,
        amount: convertAmountStringToNumber(values.amount),
        committed: values.committed,
        valuation: convertAmountStringToNumber(values.valuation),
        other: values.reason,
      },
    });

    createProfile.promise
      .then((response) => {
        setIsSubmitting(false);
        navigate(`/ventures/${response?.data?.id}`);
      })
      .catch((error) => {
        setError(error);
        setIsSubmitting(false);
      });
    subscribedPromises.current.push(createProfile);
  };

  const onSubmit = (values) => {
    // Open the modal and store form values
    setFormValues(values);
    setIsShowModal(true);
  };

  const handleModalSubmit = () => {
    submitData(formValues);
  };

  const renderCreateProfile = () => {
    if (isLoading) return <Loader />;
    if (error) <ErrorHandler error={error} />;

    return (
      <>
        {/* Application To ACA Syndication section */}
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <h2 className="text-center text-primary fs-3 my-2">
                  Application To ACA Syndication
                </h2>
                <div className="border-bottom border-dark w-75 m-auto"></div>

                <p className="text-center my-2">
                  A deal lead (an investor willing to coordinate due diligence
                  for syndication) is required to be included in the packet.
                  Your deal lead must be a member of the Angel Capital
                  Association, or a member of a member-group of the Angel
                  Capital Association.{" "}
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* formim section */}
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            emailAddress: user.email || "",
            companyName: "",
            companyWebsite: "",
            dealRoomLink: "",
            gustProfileLink: "",
            dealumProfileLink: "",
            country: "United States",
            ceoName: "",
            description: "",
            amount: "",
            mobileNumber: "",
            qualification: [],
            dealStatus: [],
            securityType: "",
            committed: "",
            dealLeadEmail: memberDetails?.[1] || "",
            dealLeadName: memberDetails?.[0] || "",
            dealLeadGroup: "",
            valuation: "",
            slideDeckLink: "",
            pitchVideoLink: "",
            executiveSummaryLink: "",
            state: "",
            reason: "",
          }}
          onSubmit={onSubmit}
        >
          {(props) => {
            return (
              <Form onSubmit={props.handleSubmit}>
                {/* company information section */}
                <Card className="mt-4">
                  <Card.Body>
                    <h4 className="text-primary">Company Information</h4>
                    <Row className="mt-4">
                      <Col sm={12} lg={6}>
                        <CustomFormControl
                          label="Company Name"
                          name="companyName"
                          id="companyName"
                          type="text"
                          required
                        />
                      </Col>
                      <Col sm={12} lg={6}>
                        <CustomFormControl
                          label="Website"
                          name="companyWebsite"
                          id="companyWebsite"
                          type="url"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col>
                        <CustomSelect
                          label="Country"
                          name="country"
                          onClick={(e) => {
                            if (e.target.value !== "United States")
                              props.setFieldValue("state", "");
                          }}
                          id="country"
                          required
                        >
                          <option value="">Please select country</option>
                          {countries.map((el) => {
                            return (
                              <option key={el.label} value={el.label}>
                                {el.label}
                              </option>
                            );
                          })}
                        </CustomSelect>
                      </Col>
                    </Row>
                    {props.values.country === "United States" && (
                      <Row className="mt-4">
                        <Col>
                          <CustomSelect label="State" name="state" id="state">
                            <option value="">Please select state</option>
                            {stateOptions.map((el) => {
                              return (
                                <option key={el.name} value={el.name}>
                                  {el.name}
                                </option>
                              );
                            })}
                          </CustomSelect>
                        </Col>
                      </Row>
                    )}
                    <Row className="mt-4">
                      <Col>
                        <CustomFormControl
                          label="Description"
                          secondaryLabel="Give us the 1-line (maximum of 140 characters) pitch for your venture"
                          name="description"
                          as="textarea"
                          id="description"
                          required
                        />
                      </Col>
                    </Row>
                    <Row className="mt-5">
                      <Col lg={12} md={12} sm={12}>
                        <h4 className="text-primary">Key Documents</h4>
                        <Form.Text>
                          Kindly ensure that the investor are able to access the
                          link.
                          <span className="text-danger mx-1">*</span>
                        </Form.Text>
                      </Col>

                      <Col lg={4}>
                        <CustomFormControl
                          label="Executive Summary Link"
                          name="executiveSummaryLink"
                          id="executiveSummaryLink"
                          type="url"
                        />
                      </Col>
                      <Col lg={4}>
                        <CustomFormControl
                          label="Pitch Deck Link"
                          name="slideDeckLink"
                          id="slideDeckLink"
                          type="url"
                          required
                        />
                      </Col>
                      <Col lg={4}>
                        <CustomFormControl
                          label="Pitch video Link"
                          name="pitchVideoLink"
                          id="pitchVideoLink"
                          type="url"
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* deal information section */}
                <Card className="mt-4">
                  <Card.Body>
                    <h4 className="text-primary">Deal Information</h4>
                    <Row className="mt-4">
                      <Col lg={4}>
                        <CustomFormControl
                          label="Deal Room Link"
                          name="dealRoomLink"
                          id="dealRoomLink"
                          type="url"
                        />
                      </Col>
                      <Col lg={4}>
                        <CustomFormControl
                          label="Gust Profile Link"
                          name="gustProfileLink"
                          id="gustProfileLink"
                          type="url"
                        />
                      </Col>
                      <Col lg={4}>
                        <CustomFormControl
                          label="Dealum Profile Link"
                          name="dealumProfileLink"
                          id="dealumProfileLink"
                          type="url"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col lg={6}>
                        <FormLabel className="text-secondary">
                          Special Interest Qualification
                        </FormLabel>
                        {specialInterestQualifications.map((qualification) => (
                          <CustomFormCheck
                            label={qualification.label}
                            name="qualification"
                            id={qualification.id}
                            value={qualification.value}
                            type="checkbox"
                            key={qualification.value}
                          />
                        ))}
                      </Col>
                      <Col lg={6}>
                        <FormLabel className="text-secondary">
                          Syndication Group
                          <span className="text-danger">*</span>
                        </FormLabel>
                        {syndicationGroup?.map((value) => (
                          <CustomFormCheck
                            label={value}
                            name="syndicationGroups"
                            id={value}
                            value={value}
                            checked={true}
                            type="checkbox"
                            key={value}
                            disabled
                          />
                        ))}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* ceo and nominating section */}
                <Card className="mt-4">
                  <Card.Body>
                    <h4 className="text-primary">CEO Contact Information</h4>
                    <Row className="mt-4">
                      <Col sm={12} lg={6}>
                        <CustomFormControl
                          label="CEO Name"
                          name="ceoName"
                          id="ceoName"
                          type="text"
                          required
                        />
                      </Col>
                      <Col sm={12} lg={6}>
                        <CustomPhoneInput
                          label="CEO Mobile Number"
                          name="mobileNumber"
                          id="mobileNumber"
                          required
                        />
                      </Col>
                    </Row>

                    <div className="mt-5">
                      <Row>
                        <h4 className="text-primary">
                          Nominating Investor Information
                        </h4>
                      </Row>
                      <Row>
                        <Col sm={12} lg={6}>
                          <CustomFormControl
                            label="Nominating Investor's Angel Group/Fund"
                            name="dealLeadGroup"
                            id="dealLeadGroup"
                            type="text"
                            required
                          />
                        </Col>
                        <Col sm={12} lg={6}>
                          <CustomFormControl
                            label="Nominating Investor's Name"
                            name="dealLeadName"
                            id="dealLeadName"
                            type="text"
                            required
                            disabled
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col>
                          <CustomFormControl
                            label="Nominating Investor's Email"
                            name="dealLeadEmail"
                            id="dealLeadEmail"
                            type="text"
                            required
                            disabled
                          />
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>

                {/* current round section */}
                <Card className="mt-4">
                  <Card.Body>
                    <Row>
                      <Col>
                        <h4 className="text-primary">
                          Current Round Information
                        </h4>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col sm={12} lg={6}>
                        <CustomNumericFormat
                          label="Raise Amount"
                          secondaryLabel="The total amount you are raising in this round"
                          name="amount"
                          id="amount"
                          required
                        />
                      </Col>
                      <Col sm={12} lg={6}>
                        <CustomNumericFormat
                          label="Valuation/Cap"
                          secondaryLabel="PRE-Money valuation or PRE-Money cap"
                          name="valuation"
                          id="valuation"
                          required
                        />
                      </Col>
                      <FormLabel className="text-primary fw-bold mt-1">
                        Please write out the full amount. Example: 1 million
                        should be entered as 1000000
                      </FormLabel>
                    </Row>
                    <Row className="mt-4">
                      <Col sm={12} lg={6}>
                        <FormLabel className="text-secondary">
                          %Committed
                          <span className="text-danger">*</span>
                        </FormLabel>
                        {committedPercentage.map((percentage) => (
                          <CustomFormCheck
                            key={percentage.value}
                            label={percentage.label}
                            name="committed"
                            value={percentage.value}
                            id={percentage.id}
                            type="radio"
                          />
                        ))}
                        {props.errors.committed && props.touched.committed ? (
                          <Form.Text type="invalid" className="text-danger">
                            {props.errors.committed}
                          </Form.Text>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col sm={12} lg={6}>
                        <FormLabel className="text-secondary">
                          Security Type
                          <span className="text-danger">*</span>
                        </FormLabel>

                        {securityTypes.map((security) => (
                          <CustomFormCheck
                            key={security.value}
                            label={security.label}
                            name="securityType"
                            value={security.value}
                            id={security.id}
                            type="radio"
                            onClick={(e) => {
                              if (e.target.value !== "Other")
                                props.setFieldValue("reason", "");
                            }}
                          />
                        ))}
                        {props.errors.securityType &&
                        props.touched.securityType ? (
                          <Form.Text type="invalid" className="text-danger">
                            {props.errors.securityType}
                          </Form.Text>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                    {props.values.securityType === "Other" && (
                      <Row className="mt-4">
                        <Col>
                          <CustomFormControl
                            label="Please specify"
                            name="reason"
                            id="reason"
                            type="text"
                            required
                          />
                        </Col>
                      </Row>
                    )}

                    <Col
                      sm={12}
                      lg={12}
                      className="mt-4 d-flex justify-content-middle"
                    >
                      <Button
                        className="btn btn-primary text-white w-50 m-auto"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Create
                      </Button>
                    </Col>
                  </Card.Body>
                </Card>
              </Form>
            );
          }}
        </Formik>
        {/* confirmation modal */}
        {isShowModal ? (
          <ConfirmationModal
            isShowModal={isShowModal}
            isSubmitting={isSubmitting}
            toggleModal={toggleModal}
            handleModalSubmit={handleModalSubmit}
          />
        ) : null}
      </>
    );
  };

  return (
    <>
      <Header />
      <Container className="my-5 MainContent">
        <Row className="d-flex justify-content-center">
          <Col lg={10}>{renderCreateProfile()}</Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default CreateProfile;
