import { useField } from "formik";
import FormGroup from "react-bootstrap/FormGroup";
import FormCheck from "react-bootstrap/FormCheck";

const CustomFormCheck = ({ label = "", ...props }) => {
  const [field, meta] = useField(props);

  // we can use in future for validation and invalidation
  const isValid = field.value && !meta.error ? true : false;
  const isInvalid = meta.error && meta.touched ? true : false;

  return (
    <FormGroup>
      <FormCheck label={label} {...field} {...props} />
    </FormGroup>
  );
};

export default CustomFormCheck;
