import axios from "axios";

async function loginVal(response) {
  let user;
  try {
    user = await axios.get(
      `https://oauth2.googleapis.com/tokeninfo?id_token=${response.credential}`
    );
  } catch (err) {
    console.log(err);
  }
  return user.data;
}
export { loginVal };
