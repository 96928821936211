import React, { useEffect, useRef, useState, useContext } from "react";
import { get, put } from "../../../../utils/DeApi";
import {
  Row,
  Col,
  Container,
  Button,
  Card,
  Table,
  Badge,
} from "react-bootstrap";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import { Link, useParams } from "react-router-dom";
import VoteSummary from "components/Dashboard/VoteSummary/VoteSummary";
import Footer from "components/App/Footer/Footer";
import Header from "components/App/Header/Header";
import { UserContext } from "contexts/UserProvider";
import { formatPhoneNumberWithCountryCode } from "utils/CountryCodes";
import RoundHistory from "components/Dashboard/RoundHistory/RoundHistory";
import NoUpdateModal from "../NoUpdateModal/NoUpdateModal";

const VentureDetails = () => {
  const user = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [error, setError] = useState();
  const [updateError, setUpdateError] = useState();
  const [profileData, setProfileData] = useState();
  const [nominations, setNominations] = useState([]);
  const { id } = useParams();
  const subscribedPromises = useRef([]);
  const viewProfile = () => {
    const createPromise = get(`/ventures/${id}`);
    const fetchNominations = get(`/nominations/${user?.email}`);

    Promise.all([createPromise.promise, fetchNominations.promise])
      // createPromise.promise
      .then((responses) => {
        setProfileData(responses?.[0]?.data);
        setNominations(responses?.[1]?.data?.member);
        setIsLoading(false);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(createPromise);
  };
  const formatPhone = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return phoneNumberString;
  };
  useEffect(() => {
    viewProfile();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return (
    <>
      <Header />
      <Container className="Main my-5">
        {isLoading && <Loader />}
        {error && <ErrorHandler error={error} />}
        {profileData && (
          <Row className="MainContent gap-4">
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Card>
                <Card.Body>
                  <h2 className="text-primary text-capitalize">
                    {user?.firstName} {user?.lastName}
                  </h2>
                  <hr />
                  <p>
                    <strong>Maximize your odds of success: </strong> Inquire
                    with your designated deal lead about any upcoming
                    syndication events, and if such events are scheduled,
                    request their support in advocating for you.
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Row className="justify-content-md-center">
                <Col lg={12}>
                  <Card>
                    <Card.Body>
                      <span className="float-end">
                        <Button
                          as={Link}
                          to={`/profile/${profileData?.id}/edit`}
                          className="px-3 py-1 text-decoration-none"
                        >
                          Edit Profile
                        </Button>
                      </span>
                      <h2 className="text-capitalize text-primary">
                        {profileData.companyName}
                      </h2>
                      <hr />

                      <p className="my-4">
                        <span className="fs-5 fw-bold">
                          What happens next:{" "}
                        </span>
                        <span>
                          Investors intrigued by your offer will reach out to
                          you or your designated deal lead using the contact
                          details provided in your profile. Remember to refresh
                          your profile bi-weekly to ensure it remains active on
                          the platform for a maximum of 90 days.
                        </span>
                      </p>
                      {/* <p>{profileData.description}</p>

                      <p>
                        {profileData.companyWebsite && (
                          <span className="me-4">
                            <span className="material-icons-outlined">
                              link
                            </span>{" "}
                            <a
                              href={profileData?.companyWebsite}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {profileData.companyWebsite || "n/a"}
                            </a>
                          </span>
                        )}

                        <span className="me-4 text-capitalize">
                          <span className="material-icons-outlined">
                            pin_drop
                          </span>{" "}
                          {profileData.state && `${profileData.state}, `}
                          {profileData.country}
                          {}
                        </span>
                      </p> */}

                      <Row className="justify-content-center">
                        <Col md={12} className="table-fs">
                          <h5 className="text-dark fw-bold my-2">
                            What your profile looks like to investors:
                          </h5>
                          <div className="border">
                            <Table className="desktop-table" size="sm">
                              <thead>
                                <tr>
                                  <th className="p-3">Company</th>
                                  <th className="p-3">Description</th>
                                  <th className="p-3">
                                    <div className="flex flex-row">
                                      <div>Lead Group </div>
                                      <div>Deal Lead Name</div>
                                    </div>
                                  </th>
                                  <th className="p-3">
                                    <div className="flex flex-row">
                                      <div>Security Type</div>
                                      <div>Raise at Valuation/Cap</div>
                                      <div>% Raised</div>
                                    </div>
                                  </th>
                                  <th className="p-3">
                                    <div className="flex flex-row">
                                      <div>Special Interest Qualifications</div>
                                      <div>Syndication Group</div>
                                    </div>
                                  </th>

                                  <th className="p-3">Links</th>
                                  <th className="p-3">Details</th>
                                </tr>
                              </thead>

                              <tbody>
                                {[profileData].map((deal) => {
                                  return (
                                    <tr key={deal.id} className="border-light">
                                      <td className="p-3 state-col">
                                        <div className="d-flex flex-column">
                                          {deal?.companyWebsite ? (
                                            <a
                                              href={
                                                deal?.companyWebsite?.startsWith(
                                                  "http"
                                                )
                                                  ? deal?.companyWebsite
                                                  : `https://${deal?.companyWebsite}`
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <strong>
                                                {deal.companyName}
                                              </strong>
                                            </a>
                                          ) : (
                                            <strong>{deal.companyName}</strong>
                                          )}
                                          <div className="flex flex-column">
                                            <i className="bi bi-envelope-at"></i>{" "}
                                            <a
                                              href={`mailto:${deal.emailAddress}?cc=${deal?.dealLeadEmail}`}
                                            >
                                              {deal.ceoName}
                                            </a>
                                          </div>
                                          {deal?.mobileNumber && (
                                            <div className="flex flex-column">
                                              <i className="bi bi-phone"></i>{" "}
                                              {deal.mobileNumber &&
                                                `${formatPhoneNumberWithCountryCode(
                                                  deal?.mobileNumber
                                                )}`}
                                            </div>
                                          )}
                                          {deal.state && (
                                            <>
                                              <div className="flex flex-column">
                                                <i className="bi bi-geo-alt"></i>{" "}
                                                {deal.state && `${deal.state}`}
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </td>

                                      <td className="p-3">
                                        {" "}
                                        <div>
                                          {deal.description.length > 155
                                            ? deal.description.slice(0, 155) +
                                              "..."
                                            : deal.description}
                                        </div>
                                      </td>
                                      <td className="p-3">
                                        <div className="mb-2 d-flex justify-content-center flex-column">
                                          <div className="flex flex-wrap">
                                            {deal.dealLeadGroup}
                                          </div>
                                          <div className="flex flex-wrap">
                                            <i className="bi bi-envelope-at"></i>{" "}
                                            <a
                                              href={`mailto:${deal?.dealLeadEmail?.replace(
                                                "|",
                                                ""
                                              )}`}
                                            >
                                              {deal?.dealLeadName}
                                            </a>
                                          </div>
                                          {deal.dealRoomLink && (
                                            <div className="flex flex-wrap">
                                              <a
                                                target="_blank"
                                                href={`${deal.dealRoomLink}`}
                                                rel="noreferrer"
                                              >
                                                Deal Room
                                              </a>
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                      <td className="p-3">
                                        {Number(
                                          deal?.currentVentureRound
                                            ?.isRoundClosed
                                        ) ? (
                                          <div className="flex flex-wrap my-2 fs-6">
                                            <Badge className="p-2 bg-dark">
                                              Round Closed
                                            </Badge>
                                          </div>
                                        ) : (
                                          <>
                                            <div className="flex flex-wrap">
                                              {
                                                deal?.currentVentureRound
                                                  ?.securityType
                                              }
                                            </div>
                                            <div className="flex flex-wrap text-wrap">
                                              {new Intl.NumberFormat("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                                currencyDisplay: "narrowSymbol",
                                                maximumFractionDigits: 0,
                                              }).format(
                                                Number(
                                                  deal?.currentVentureRound
                                                    ?.amount
                                                )
                                              )}
                                              <span className="mx-2">at</span>
                                              {new Intl.NumberFormat("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                                currencyDisplay: "narrowSymbol",
                                                maximumFractionDigits: 0,
                                              }).format(
                                                Number(
                                                  deal?.currentVentureRound
                                                    ?.valuation
                                                )
                                              )}
                                            </div>
                                            <div className="flex flex-wrap">
                                              <span
                                                className={`badge p-2 table-fs text-wrap ${
                                                  deal?.currentVentureRound
                                                    ?.committed ===
                                                  "Starting (0%)"
                                                    ? "bg-danger text-white"
                                                    : deal?.currentVentureRound
                                                        ?.committed ===
                                                      "At least (50%)"
                                                    ? "bg-warning text-white"
                                                    : deal?.currentVentureRound
                                                        ?.committed ===
                                                      "At least (75%)"
                                                    ? "bg-success text-white"
                                                    : deal?.currentVentureRound
                                                        ?.committed ===
                                                      "Over subscribing (100%)"
                                                    ? "bg-success text-white"
                                                    : "bg-warning text-white"
                                                }`}
                                              >
                                                {
                                                  deal?.currentVentureRound
                                                    ?.committed
                                                }
                                              </span>
                                            </div>
                                          </>
                                        )}
                                      </td>
                                      <td className="p-3">
                                        <div className="mb-2 d-flex flex-column">
                                          <div className="d-flex flex-wrap">
                                            {deal.qualification.length > 0 &&
                                              deal.qualification.map(
                                                (qualification, index) => (
                                                  <span
                                                    className="badge bg-secondary me-2 mb-2"
                                                    key={index}
                                                  >
                                                    {qualification}
                                                  </span>
                                                )
                                              )}
                                          </div>
                                          <div>
                                            {!!deal?.nominations?.length
                                              ? deal?.nominations?.map(
                                                  (item, index) => (
                                                    <span key={index}>
                                                      {
                                                        item?.syndicationGroup
                                                          ?.title
                                                      }
                                                      {index !==
                                                        deal?.nominations
                                                          ?.length -
                                                          1 && ", "}
                                                    </span>
                                                  )
                                                )
                                              : "Northeast"}
                                          </div>
                                        </div>
                                      </td>
                                      <td className="p-3">
                                        <div className="mb-2 d-flex justify-content-center flex-column">
                                          <div className="flex flex-wrap">
                                            {deal.slideDeckLink && (
                                              <a
                                                // href={deal.slideDeckLink}
                                                href={
                                                  deal?.slideDeckLink?.startsWith(
                                                    "http"
                                                  )
                                                    ? deal?.slideDeckLink
                                                    : `https://${deal?.slideDeckLink}`
                                                }
                                                className="text-decoration-none"
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                Slides
                                              </a>
                                            )}
                                          </div>
                                          <div className="flex flex-wrap">
                                            {deal.pitchVideoLink && (
                                              <a
                                                // href={deal.pitchVideoLink}
                                                href={
                                                  deal?.pitchVideoLink?.startsWith(
                                                    "http"
                                                  )
                                                    ? deal?.pitchVideoLink
                                                    : `https://${deal?.pitchVideoLink}`
                                                }
                                                className="text-decoration-none"
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                Video
                                              </a>
                                            )}
                                          </div>
                                          <div className="flex flex-wrap">
                                            {deal.executiveSummaryLink && (
                                              <a
                                                // href={deal.executiveSummaryLink}
                                                href={
                                                  deal?.executiveSummaryLink?.startsWith(
                                                    "http"
                                                  )
                                                    ? deal?.executiveSummaryLink
                                                    : `https://${deal?.executiveSummaryLink}`
                                                }
                                                target="_blank"
                                                className="text-decoration-none"
                                                rel="noreferrer"
                                              >
                                                Ex.Summary
                                              </a>
                                            )}
                                          </div>
                                          <div className="flex flex-wrap">
                                            {deal.gustProfileLink && (
                                              <a
                                                // href={deal.gustProfileLink}
                                                href={
                                                  deal?.gustProfileLink?.startsWith(
                                                    "http"
                                                  )
                                                    ? deal?.gustProfileLink
                                                    : `https://${deal?.gustProfileLink}`
                                                }
                                                target="_blank"
                                                className="text-decoration-none"
                                                rel="noreferrer"
                                              >
                                                Gust Profile
                                              </a>
                                            )}
                                          </div>
                                          <div className="flex flex-wrap">
                                            {deal.dealumProfileLink && (
                                              <a
                                                // href={deal.dealumProfileLink}
                                                href={
                                                  deal?.dealumProfileLink?.startsWith(
                                                    "http"
                                                  )
                                                    ? deal?.dealumProfileLink
                                                    : `https://${deal?.dealumProfileLink}`
                                                }
                                                target="_blank"
                                                className="text-decoration-none"
                                                rel="noreferrer"
                                              >
                                                Dealum Profile
                                              </a>
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                      <td className="p-3">
                                        <div className="mb-2 d-flex justify-content-center flex-column">
                                          {!!deal?.currentVentureRound
                                            ?.isPresenter && (
                                            <Badge className="px-3 py-2 bg-transparent border text-dark border-dark text-uppercase">
                                              Presenter
                                            </Badge>
                                          )}
                                          <RoundHistory
                                            roundHistory={deal?.ventureRounds}
                                          />
                                        </div>
                                        {/* <VoteCaster deal={deal} /> */}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                            {/* MOBILE  */}
                            <Table
                              className="mobile-table"
                              size="sm"
                              responsive
                            >
                              <thead className="bg-white">
                                <tr>
                                  <th className="p-3">Company</th>
                                  <th className="p-3">Description</th>
                                  <th className="p-3">
                                    <div className="flex flex-row">
                                      <div>Lead Group </div>
                                      <div>Deal Lead Name</div>
                                    </div>
                                  </th>
                                  <th className="p-3">
                                    <div className="flex flex-row">
                                      <div>Security Type</div>
                                      <div>Raise at Valuation/Cap</div>
                                      <div>% Raised</div>
                                    </div>
                                  </th>
                                  <th className="p-3">
                                    <div className="flex flex-row">
                                      <div>Special Interest Qualifications</div>
                                      <div>Syndication Group</div>
                                    </div>
                                  </th>

                                  <th className="p-3">Links</th>
                                  <th className="p-3">Details</th>
                                </tr>
                              </thead>

                              <tbody>
                                {[profileData].map((deal) => {
                                  return (
                                    <tr key={deal.id} className="border-light">
                                      <td className="p-3 state-col">
                                        <div className="d-flex flex-column">
                                          {deal?.companyWebsite ? (
                                            <a
                                              href={
                                                deal?.companyWebsite?.startsWith(
                                                  "http"
                                                )
                                                  ? deal?.companyWebsite
                                                  : `https://${deal?.companyWebsite}`
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <strong>
                                                {deal.companyName}
                                              </strong>
                                            </a>
                                          ) : (
                                            <strong>{deal.companyName}</strong>
                                          )}
                                          <div className="flex flex-column">
                                            <i className="bi bi-envelope-at"></i>{" "}
                                            <a
                                              href={`mailto:${deal.emailAddress}`}
                                            >
                                              {deal.ceoName}
                                            </a>
                                          </div>
                                          {deal?.mobileNumber && (
                                            <div className="flex flex-column">
                                              <i className="bi bi-phone"></i>{" "}
                                              {deal.mobileNumber &&
                                                `${formatPhoneNumberWithCountryCode(
                                                  deal?.mobileNumber
                                                )}`}
                                            </div>
                                          )}
                                          {deal.state && (
                                            <>
                                              <div className="flex flex-column">
                                                <i className="bi bi-geo-alt"></i>{" "}
                                                {deal.state && `${deal.state}`}
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </td>

                                      <td className="p-3">
                                        {" "}
                                        <div>
                                          {deal.description.length > 155
                                            ? deal.description.slice(0, 155) +
                                              "..."
                                            : deal.description}
                                        </div>
                                      </td>
                                      <td className="p-3">
                                        <div className="mb-2 d-flex justify-content-center flex-column">
                                          <div className="flex flex-wrap">
                                            {deal.dealLeadGroup}
                                          </div>
                                          <div className="flex flex-wrap">
                                            <i className="bi bi-envelope-at"></i>{" "}
                                            <a
                                              href={`mailto:${deal?.dealLeadEmail?.replace(
                                                "|",
                                                ""
                                              )}`}
                                            >
                                              {deal?.dealLeadName}
                                            </a>
                                          </div>
                                          {deal.dealRoomLink && (
                                            <div className="flex flex-wrap">
                                              <a
                                                target="_blank"
                                                href={`${deal.dealRoomLink}`}
                                                rel="noreferrer"
                                              >
                                                Deal Room
                                              </a>
                                            </div>
                                          )}
                                        </div>
                                      </td>
                                      <td className="p-3">
                                        {Number(
                                          deal?.currentVentureRound
                                            ?.isRoundClosed
                                        ) ? (
                                          <div className="flex flex-wrap my-2 fs-6">
                                            <Badge className="p-2 bg-dark">
                                              Round Closed
                                            </Badge>
                                          </div>
                                        ) : (
                                          <>
                                            <div className="flex flex-wrap">
                                              {
                                                deal?.currentVentureRound
                                                  ?.securityType
                                              }
                                            </div>
                                            <div className="flex flex-wrap text-wrap">
                                              {new Intl.NumberFormat("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                                currencyDisplay: "narrowSymbol",
                                                maximumFractionDigits: 0,
                                              }).format(
                                                Number(
                                                  deal?.currentVentureRound
                                                    ?.amount
                                                )
                                              )}
                                              <span className="mx-2">at</span>
                                              {new Intl.NumberFormat("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                                currencyDisplay: "narrowSymbol",
                                                maximumFractionDigits: 0,
                                              }).format(
                                                Number(
                                                  deal?.currentVentureRound
                                                    ?.valuation
                                                )
                                              )}
                                            </div>
                                            <div className="flex flex-wrap">
                                              <span
                                                className={`badge p-2 table-fs text-wrap ${
                                                  deal?.currentVentureRound
                                                    ?.committed ===
                                                  "Starting (0%)"
                                                    ? "bg-danger text-white"
                                                    : deal?.currentVentureRound
                                                        ?.committed ===
                                                      "At least (50%)"
                                                    ? "bg-warning text-white"
                                                    : deal?.currentVentureRound
                                                        ?.committed ===
                                                      "At least (75%)"
                                                    ? "bg-success text-white"
                                                    : deal?.currentVentureRound
                                                        ?.committed ===
                                                      "Over subscribing (100%)"
                                                    ? "bg-success text-white"
                                                    : "bg-warning text-white"
                                                }`}
                                              >
                                                {
                                                  deal?.currentVentureRound
                                                    ?.committed
                                                }
                                              </span>
                                            </div>
                                          </>
                                        )}
                                      </td>
                                      <td className="p-3">
                                        <div className="mb-2 d-flex flex-column">
                                          <div className="d-flex flex-wrap">
                                            {deal.qualification.length > 0 &&
                                              deal.qualification.map(
                                                (qualification, index) => (
                                                  <span
                                                    className="badge bg-secondary me-2 mb-2"
                                                    key={index}
                                                  >
                                                    {qualification}
                                                  </span>
                                                )
                                              )}
                                          </div>
                                          <div>
                                            {!!deal?.nominations?.length
                                              ? deal?.nominations?.map(
                                                  (item, index) => (
                                                    <span key={index}>
                                                      {
                                                        item?.syndicationGroup
                                                          ?.title
                                                      }
                                                      {index !==
                                                        deal?.nominations
                                                          ?.length -
                                                          1 && ", "}
                                                    </span>
                                                  )
                                                )
                                              : "Northeast"}
                                          </div>
                                        </div>
                                      </td>
                                      <td className="p-3">
                                        <div className="mb-2 d-flex justify-content-center flex-column">
                                          <div className="flex flex-wrap">
                                            {deal.slideDeckLink && (
                                              <a
                                                // href={deal.slideDeckLink}
                                                href={
                                                  deal?.slideDeckLink?.startsWith(
                                                    "http"
                                                  )
                                                    ? deal?.slideDeckLink
                                                    : `https://${deal?.slideDeckLink}`
                                                }
                                                className="text-decoration-none fs-6"
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                Slides
                                              </a>
                                            )}
                                          </div>
                                          <div className="flex flex-wrap">
                                            {deal.pitchVideoLink && (
                                              <a
                                                // href={deal.pitchVideoLink}
                                                href={
                                                  deal?.pitchVideoLink?.startsWith(
                                                    "http"
                                                  )
                                                    ? deal?.pitchVideoLink
                                                    : `https://${deal?.pitchVideoLink}`
                                                }
                                                className="text-decoration-none"
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                Video
                                              </a>
                                            )}
                                          </div>
                                          <div className="flex flex-wrap">
                                            {deal.executiveSummaryLink && (
                                              <a
                                                // href={deal.executiveSummaryLink}
                                                href={
                                                  deal?.executiveSummaryLink?.startsWith(
                                                    "http"
                                                  )
                                                    ? deal?.executiveSummaryLink
                                                    : `https://${deal?.executiveSummaryLink}`
                                                }
                                                target="_blank"
                                                className="text-decoration-none"
                                                rel="noreferrer"
                                              >
                                                Ex.Summary
                                              </a>
                                            )}
                                          </div>
                                          <div className="flex flex-wrap">
                                            {deal.gustProfileLink && (
                                              <a
                                                // href={deal.gustProfileLink}
                                                href={
                                                  deal?.gustProfileLink?.startsWith(
                                                    "http"
                                                  )
                                                    ? deal?.gustProfileLink
                                                    : `https://${deal?.gustProfileLink}`
                                                }
                                                target="_blank"
                                                className="text-decoration-none"
                                                rel="noreferrer"
                                              >
                                                Gust Profile
                                              </a>
                                            )}
                                          </div>
                                          <div className="flex flex-wrap">
                                            {deal.dealumProfileLink && (
                                              <a
                                                // href={deal.dealumProfileLink}
                                                href={
                                                  deal?.dealumProfileLink?.startsWith(
                                                    "http"
                                                  )
                                                    ? deal?.dealumProfileLink
                                                    : `https://${deal?.dealumProfileLink}`
                                                }
                                                target="_blank"
                                                className="text-decoration-none"
                                                rel="noreferrer"
                                              >
                                                Dealum Profile
                                              </a>
                                            )}
                                          </div>
                                        </div>
                                      </td>

                                      <td className="p-3">
                                        <div className="mb-2 d-flex justify-content-center flex-column">
                                          {!!deal?.currentVentureRound
                                            ?.isPresenter && (
                                            <Badge className="px-3 py-2 bg-transparent border text-dark border-dark text-uppercase">
                                              Presenter
                                            </Badge>
                                          )}
                                        </div>
                                        {/* <VoteCaster deal={deal} /> */}
                                      </td>
                                      {/* <td className="p-3">
                                  <div className="mb-2 d-flex justify-content-center flex-column">
                                    <Button
                                      as={Link}
                                      variant="outline-primary"
                                      size="sm"
                                      className="text-decoration-none "
                                      to={`/deals/${deal.id}`}
                                    >
                                      Vote{" "}
                                    </Button>
                                  </div>
                                </td> */}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                            {/* MOBILE VIEW END */}
                          </div>
                        </Col>
                      </Row>

                      {/* <hr /> */}
                      {/* <Table responsive hover className="my-3">
                        <thead>
                          <tr>
                            <th className="fs-4 fw-normal" colSpan={2}>
                              Key Documents
                            </th>
                          </tr>
                        </thead>
                        <tbody className="border-top-0">
                          <tr>
                            <td className="w-25">Executive Summary</td>
                            <td>
                              {profileData.executiveSummaryLink ? (
                                <a
                                  href={profileData.executiveSummaryLink}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {profileData.executiveSummaryLink}
                                </a>
                              ) : (
                                <span className="text-muted">N/A</span>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="w-25">Pitch Video</td>
                            <td>
                              {profileData.pitchVideoLink ? (
                                <a
                                  href={profileData.pitchVideoLink}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {profileData.pitchVideoLink}
                                </a>
                              ) : (
                                <span className="text-muted">N/A</span>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="w-25">Slide Deck</td>
                            <td>
                              {profileData.slideDeckLink ? (
                                <a
                                  href={profileData.slideDeckLink}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {profileData.slideDeckLink}
                                </a>
                              ) : (
                                <span className="text-muted">N/A</span>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                      <Table responsive hover className="my-3">
                        <thead>
                          <tr>
                            <th className="fs-4 fw-normal" colSpan={2}>
                              Deal Information
                            </th>
                          </tr>
                        </thead>
                        <tbody className="border-top-0">
                          <tr>
                            <td className="w-25">Deal Room</td>
                            <td>
                              {profileData.dealRoomLink ? (
                                <a
                                  href={profileData.dealRoomLink}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {profileData.dealRoomLink}
                                </a>
                              ) : (
                                <span className="text-muted">N/A</span>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="w-25">Gust Profile</td>
                            <td>
                              {profileData.gustProfileLink ? (
                                <a
                                  href={profileData.gustProfileLink}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {profileData.gustProfileLink}
                                </a>
                              ) : (
                                <span className="text-muted">N/A</span>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="w-25">Dealum Profile</td>
                            <td>
                              {profileData.dealumProfileLink ? (
                                <a
                                  href={profileData.dealumProfileLink}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {profileData.dealumProfileLink}
                                </a>
                              ) : (
                                <span className="text-muted">N/A</span>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="w-25">
                              Special Interest Qualifications
                            </td>
                            <td>
                              {profileData.qualification?.length > 0 ? (
                                <>
                                  {profileData.qualification.map(
                                    (qualification) => (
                                      <span
                                        className="px-3 py-1 me-2 bg-secondary bg-dark bg-opacity-10 me-1 rounded text-capitalize"
                                        key={qualification}
                                      >
                                        {qualification}
                                      </span>
                                    )
                                  )}
                                </>
                              ) : (
                                <span className="text-muted">N/A</span>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                      <Table responsive hover className="my-3">
                        <thead>
                          <tr>
                            <th className="fs-4 fw-normal" colSpan={2}>
                              Round Information
                            </th>
                          </tr>
                        </thead>
                        <tbody className="border-top-0">
                          <tr>
                            <td className="w-25">Raise Amount</td>
                            <td>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                                currencyDisplay: "narrowSymbol",
                                maximumFractionDigits: 0,
                              }).format(Number(profileData.amount))}
                            </td>
                          </tr>
                          <tr>
                            <td className="w-25">% of Round Committed</td>
                            <td>{profileData.committed}</td>
                          </tr>
                          <tr>
                            <td className="w-25">Valuation/Cap</td>
                            <td>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                                currencyDisplay: "narrowSymbol",
                                maximumFractionDigits: 0,
                              }).format(Number(profileData.valuation))}
                            </td>
                          </tr>
                          <tr>
                            <td className="w-25">Security Type</td>
                            <td>{profileData.securityType}</td>
                          </tr>
                        </tbody>
                      </Table>

                      <Table responsive hover className="my-3">
                        <thead>
                          <tr>
                            <th className="fs-4 fw-normal" colSpan={2}>
                              Nominating Investor Information
                            </th>
                          </tr>
                        </thead>
                        <tbody className="border-top-0">
                          <tr>
                            <td className="w-25">Lead Group</td>
                            <td>{profileData?.dealLeadGroup}</td>
                          </tr>
                          <tr>
                            <td className="w-25">Deal Lead Name</td>
                            <td>
                              {nominations?.[0] || profileData.dealLeadName}
                            </td>
                          </tr>
                          <tr>
                            <td className="w-25">Deal Lead Email</td>
                            <td>
                              {(nominations?.[1]?.length > 10 ||
                                profileData.dealLeadEmail?.length > 10) && (
                                <a
                                  href={`mailto:${
                                    nominations?.[1] ||
                                    profileData?.dealLeadEmail
                                  }`}
                                >
                                  {nominations?.[1] ||
                                    profileData.dealLeadEmail}
                                </a>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table> */}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <VoteSummary />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <NoUpdateModal isNoUpdate={profileData?.isNoUpdate} />
      </Container>
      <Footer />
    </>
  );
};

export default VentureDetails;
