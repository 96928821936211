import React from "react";
import Copyright from "components/App/Copyright/Copyright";
import { Container, Row, Col } from "react-bootstrap";
import "./Footer.scss";
import { Link } from "react-router-dom";
import ACAIntroduction from "components/Dashboard/ACAIntroduction/ACAIntroduction";
const Footer = () => {
  return (
    <div className="Footer bg-dark">
      <Container fluid>
        <Row>
          <Col
            xs={12}
            className="my-3 d-flex justify-content-between flex-wrap"
          >
            <p className="text-start text-white mb-0">
              <Copyright />
            </p>
            <p className="d-flex align-items-center text-start text-white mb-2">
              Disclaimer: All information is shared under the terms of the{" "}
              <ACAIntroduction />.
            </p>
            <p className="text-white text-center mb-2">
              Powered by
              <a target="_blank" href="https://www.pixeledge.io/" rel="noreferrer">
                <img src="/darkbgLogo.png" alt="Logo" className="logo-img" />
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
