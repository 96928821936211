import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import { UserContext, UserDispatchContext } from "contexts/UserProvider";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Nav, Form, FormSelect, NavDropdown } from "react-bootstrap";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { get, put } from "utils/DeApi";
const SyndicationGroup = () => {
  const [syndicationGroup, setSyndicationGroup] = useState([]);
  const [updatesyndication, setUpdateSyndication] = useState();
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const user = useContext(UserContext);
  const setUser = useContext(UserDispatchContext);
  const [defaultGroup, setDefaultGroup] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(defaultGroup);
  const subscribedPromises = useRef([]);
  const location = useLocation();
  const navigate = useNavigate();

  const updateSyndication = (event) => {
    const id = event;
    setDefaultGroup(id);
    setSelectedGroup(id);
    setIsLoading(true);
    const changeSyndication = put(
      `/members/${user?.member?.id || user?.id}/syndication-groups`,
      {
        syndicationGroupId: id,
      }
    );
    changeSyndication.promise
      .then((response) => {
        setUpdateSyndication(response);
        setUser((prevUser) => ({
          ...prevUser,
          syndicationGroupVentures: response?.ventures,
          group: response?.member?.syndicationGroup?.title || "Northeast",
          syndicationGroup: response?.member?.syndicationGroup,
        }));
        (location.pathname.includes("contents") ||
          location.pathname.includes("all-updates")) &&
          navigate("/");
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    setIsLoading(true);
    const fetchSyndicationGroups = get(`/syndication-groups
    `);
    fetchSyndicationGroups.promise
      .then((response) => {
        setSyndicationGroup(response.data);
        setUser((prevUser) => ({
          ...prevUser,
          syndicationGroupVentures: response?.ventures,
          group: user?.member?.syndicationGroup?.title
            ? user?.member?.syndicationGroup?.title
            : user?.syndicationGroup?.title
            ? user?.syndicationGroup?.title
            : user?.group
            ? user?.group
            : "Northeast",
          syndicationGroups: response.data,
        }));
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(fetchSyndicationGroups);

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  if (error) return <ErrorHandler />;
  return (
    <div>
      {syndicationGroup.length > 0 && (
        <NavDropdown
          title={
            <span>
              {user?.group ? user.group : user?.syndicationGroup?.title}
            </span>
          }
          className="bg-primary bg-opacity-10 text-dark px-2 rounded-2 mt-3 mt-lg-0"
        >
          {syndicationGroup?.map(({ title, id }) => {
            return (
              <NavDropdown.Item
                onClick={(event) => updateSyndication(event?.target?.id)}
                key={id}
                id={id}
                active={id === selectedGroup}
              >
                {title}
              </NavDropdown.Item>
            );
          })}
        </NavDropdown>
      )}
    </div>
  );
};

export default SyndicationGroup;
