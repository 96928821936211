import { useField } from "formik";
import { NumericFormat } from "react-number-format";

import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import FormControl from "react-bootstrap/FormControl";

const CustomNumericFormat = ({ label = "", secondaryLabel = "", ...props }) => {
  const [field, meta] = useField(props);

  const isValid = field.value && !meta.error ? true : false;
  const isInvalid = meta.error && meta.touched ? true : false;

  return (
    <FormGroup>
      <FormLabel>
        <span className="text-secondary">{label}</span>
        {props.required ? <span className="text-danger">*</span> : null}
      </FormLabel>
      {secondaryLabel ? (
        <FormLabel className="d-block">{secondaryLabel}</FormLabel>
      ) : null}
      <NumericFormat
        {...field}
        {...props}
        allowNegative={false}
        customInput={FormControl}
        thousandSeparator={true}
        decimalScale={0}
        prefix={"$"}
        isValid={isValid}
        isInvalid={isInvalid}
      />
      {isInvalid ? (
        <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>
      ) : null}
    </FormGroup>
  );
};

export default CustomNumericFormat;
