import React, { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";

import "./Logout.css";

import { logout } from "utils/BeeApi";
import { logout as logoutDE } from "utils/DeApi";

import Loader from "components/Loader/Loader";
import { UserDispatchDestroyContext, UserContext } from "contexts/UserProvider";

const Logout = () => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const clearUser = useContext(UserDispatchDestroyContext);
  const user = useContext(UserContext);

  useEffect(() => {
    if (localStorage.getItem("role") === "investor") {
      setIsLoggingOut(true);
      if (user?.tenantId) {
        logout()
          .then(() => {
            return logoutDE();
          })
          .then(() => {
            setIsLoggingOut(false);
            clearUser();
          })
          .catch((error) => {
            console.warn(error);
          });
      } else {
        logoutDE()
          .then(() => {
            setIsLoggingOut(false);
            clearUser();
          })
          .catch((error) => {
            console.warn(error);
          });
      }
    } else {
      setIsLoggingOut(true);
      logout()
        .then(() => {
          return logoutDE();
        })
        .then(() => {
          setIsLoggingOut(false);
          clearUser();
        })
        .catch((error) => {
          console.warn(error);
        });
    }
  }, [clearUser]);

  if (isLoggingOut) return <Loader />;
  return <span />;
};

export default Logout;
