import React, { createContext, useState, useEffect, useRef } from "react";
import { get } from "utils/BeeApi";
import { get as getDE } from "utils/DeApi";

import { googleLogout } from "@react-oauth/google";

import Loader from "components/Loader/Loader";
import { useParams } from "react-router-dom";

const UserContext = createContext(undefined);
const UserDispatchContext = createContext(undefined);
const UserDispatchDestroyContext = createContext(undefined);

function UserProvider({ children }) {
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState();
  const subscribedPromises = useRef([]);
  const [updatesyndication, setUpdateSyndication] = useState();
  const { id } = useParams();
  const logout = () => {
    if (localStorage.getItem("role") === "investor") googleLogout();
    setUser(null);
    localStorage.clear();
  };
  const fetchInvestor = () => {
    const investorPromise = getDE(`/login`);
    investorPromise.promise
      .then((response) => {
        if (response?.deMember) {
          setIsLoading(false);
          setUser({
            ...response?.deMember,
            beeId: response?.beeMember?.id,
            role: "investor",
          });
        } else {
          setIsLoading(false);
          logout();
        }
      })
      .catch((error) => {
        if (!error.isCanceled) {
          logout();
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(investorPromise);
  };

  const fetchEntreprenuer = () => {
    const userPromise = get("user/anon");

    userPromise.promise
      .then((response) => {
        setUser({ ...response.data, role: "entrepreneur" });

        setIsLoading(false);
      })

      .catch((error) => {
        if (!error.isCanceled) {
          logout();
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(userPromise);
  };

  useEffect(() => {
    setIsLoading(true);

    let role = localStorage.getItem("role");
    if (role === "investor") fetchInvestor();
    else fetchEntreprenuer();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  if (isLoading) return <Loader />;
  if (user === undefined) return <span />;

  return (
    <UserContext.Provider value={user}>
      <UserDispatchContext.Provider value={setUser}>
        <UserDispatchDestroyContext.Provider value={logout}>
          {children}
        </UserDispatchDestroyContext.Provider>
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
}

export {
  UserProvider,
  UserContext,
  UserDispatchContext,
  UserDispatchDestroyContext,
};
