import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { put } from "utils/DeApi";

const statuses = {
  idle: "idle",
  pending: "pending",
  success: "succcess",
  error: "error",
};

const NoUpdateModal = ({ isNoUpdate, ...props }) => {
  const [isShow, setIsShow] = useState(false);
  const [status, setStatus] = useState(statuses.idle);
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const { id: ventureId } = useParams();

  // it will toggle and inverse the state of modal
  const toggleModal = () => setIsShow((prevState) => !prevState);

  const EDIT_PROFILE_URL = `/profile/${ventureId}/edit`;
  const NO_UPDATE_URL = `venture-no-update`;

  useEffect(() => {
    if (isNoUpdate === 0) {
      toggleModal();
    }
  }, [isNoUpdate]);

  const handleNavigation = () => {
    toggleModal();
    navigate(EDIT_PROFILE_URL);
  };

  const noUpdateApi = () => {
    const api = put(NO_UPDATE_URL, {
      ventureId,
      isNoUpdate: true,
    });

    setStatus(statuses.pending);
    setError("");
    api.promise
      .then((_) => {
        setStatus(statuses.success);
        toggleModal();
        setError("");
      })
      .catch((error) => {
        setError(error);
      });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isShow}
    >
      <Modal.Header>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-primary "
        >
          Update Required
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {status === statuses.error ? <ErrorHandler error={error} /> : null}

        <p>
          It has been <strong>30 days</strong> since your last profile update.
          To make any changes click on <strong>Edit Profile</strong> button. If
          everything is up to date, plesae click on <strong>No Change</strong>{" "}
          button.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleNavigation}
          disabled={status === statuses.pending}
        >
          Edit Profile
        </Button>
        <Button disabled={status === statuses.pending} onClick={noUpdateApi}>
          {status === statuses.pending ? (
            <>
              <Spinner animation="border" size="sm" />{" "}
            </>
          ) : null}
          <span>No Change</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NoUpdateModal;
