import { useField } from "formik";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import FormControl from "react-bootstrap/FormControl";
import FormSelect from "react-bootstrap/FormSelect";

const CustomSelect = ({ label = "", ...props }) => {
  const [field, meta] = useField(props);

  const isValid = field.value && !meta.error ? true : false;
  const isInvalid = meta.error && meta.touched ? true : false;

  return (
    <FormGroup>
      <FormLabel>
        <span className="text-secondary">{label}</span>
        {props.required ? <span className="text-danger">*</span> : null}
      </FormLabel>
      <FormSelect
        {...field}
        {...props}
        isValid={isValid}
        isInvalid={isInvalid}
      />
      {isInvalid ? (
        <FormControl.Feedback type="invalid">{meta.error}</FormControl.Feedback>
      ) : null}
    </FormGroup>
  );
};

export default CustomSelect;
