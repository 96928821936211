import React, {
  useState,
  useCallback,
  createContext,
  useContext,
  useEffect,
  useRef,
} from "react";

import { get, put } from "utils/BeeApi";
import { get as getDE, put as putDE } from "utils/DeApi";

import { UserContext } from "contexts/UserProvider";

const ContentContext = createContext();

export const useContentContext = () => {
  return useContext(ContentContext);
};

const ContentProvider = ({ children }) => {
  const subscribedPromises = useRef([]);

  const user = useContext(UserContext);

  const [bookmarks, setBookmarks] = useState();

  const [contents, setContents] = useState([]);

  //First Id is for sandbox second is for production
  const contentGroupId = ["660d4edc41aed"];

  const filteredContent = contents
    ?.filter((filterContent) =>
      contentGroupId.includes(filterContent?.categories[0]?.groups[0]?.groupId)
    )
    .slice(0, 3);

  const allFilteredContent = contents?.filter((filterContent) =>
    contentGroupId.includes(filterContent?.categories[0]?.groups[0]?.groupId)
  );

  const bookmarkableIds = bookmarks?.map(
    ({ bookmarkableId }) => bookmarkableId
  );

  const notification = allFilteredContent?.filter(
    (item) => !bookmarkableIds?.includes(item.contentId)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const fetchContents = useCallback(() => {
    setError(null);
    setIsLoading(true);
    let getContents = get(`contents`, {
      params: {
        offset: 0,
        limit: 2000,
      },
    });

    getContents.promise
      .then((response) => {
        setIsLoading(false);
        setError(null);
        setContents(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(getContents);
  }, []);

  const fetchBookmarks = useCallback(
    (id) => {
      setError(null);
      setIsLoading(true);

      // const bookmarks = get(
      //   `user/${user?.beeId ? user?.beeId : user?.userId || user?.id}/bookmarks`
      // );
      const bookmarks = getDE(
        `member/${id ? id : user?.member?.id || user?.id}/bookmarks`
      );

      bookmarks.promise
        .then((response) => {
          // setBookmarks(response.data);
          setBookmarks(response.success.data);
          setIsLoading(false);
          setError(null);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });

      subscribedPromises.current.push(bookmarks);
    },
    [user?.userId]
  );

  const markBookmark = useCallback(
    (contentId) => {
      setError(null);

      // const bookmarks = put(
      //   `user/${
      //     user?.beeId ? user?.beeId : user?.userId || user?.id
      //   }/bookmarks`,
      //   {
      //     bookmarkableId: contentId,
      //   }
      // );

      const bookmarks = putDE(
        `member/${user?.member?.id || user?.id}/bookmarks`,
        {
          bookmarkableId: contentId,
        }
      );

      bookmarks.promise
        .then((response) => {
          setIsLoading(false);
          setError(null);
          fetchBookmarks();
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });

      subscribedPromises.current.push(bookmarks);
    },
    [user?.userId, fetchBookmarks]
  );

  useEffect(() => {
    if (user && user?.role === "investor") {
      fetchContents();
      fetchBookmarks();
    }

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchContents, fetchBookmarks]);

  const contextValue = {
    contents,
    filteredContent,
    allFilteredContent,
    bookmarkableIds,
    bookmarks,
    notification,
    isLoading,
    error,
    fetchBookmarks,
    markBookmark,
    fetchContents,
  };

  return (
    <ContentContext.Provider value={contextValue}>
      {children}
    </ContentContext.Provider>
  );
};

export default ContentProvider;
