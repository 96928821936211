import React from "react";
import { Link } from "react-router-dom";
import "./TextLogo.scss";

const TextLogo = () => (
  <div className="text-center Logo">
    <Link to="/">
      <img
        className="img-responsive mx-auto my-2 d-flex w-75"
        alt="Logo"
        src="/etiquetteLogo.png"
      />
    </Link>
    {localStorage.getItem("role") === "investor" && (
      <>
        <h2 className="text-danger">BETA</h2>
        <p className="text-muted">
          Free Trial (ends after 31st December, 2024)
        </p>
      </>
    )}
    {localStorage.getItem("role") === "entrepreneur" && (
      <>
        <h5 className="text-danger">Free For Entrepreneurs</h5>
      </>
    )}
  </div>
);

export default TextLogo;
