import { useNavigate } from "react-router-dom";
import { Container, Col, Row, Card, Button } from "react-bootstrap";
import "./Login.scss";
import RedirectIfAuthenticated from "../RedirectIfAuthenticated/RedirectIfAuthenticated";

const Login = () => {
  const navigate = useNavigate();
  return (
    <Container className="mt-4 mb-4">
      <RedirectIfAuthenticated />
      <Row className="justify-content-center">
        <Col xl={5} lg={6} md={8} sm={10}>
          <div className="text-center Logo">
            <h2 className="my-3 text-primary">
              <img
                className="img-responsive w-75 mx-auto"
                alt="Logo"
                src="/etiquetteLogo.png"
              />
            </h2>
          </div>
          <Card className="border-0 shadow-sm">
            <Card.Body>
              <Row className="my-3">
                <Col xs={12} sm={6}>
                  <div className="d-grid">
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        navigate("/entrepreneur-signin");
                      }}
                    >
                      Entrepreneur Sign In
                    </Button>
                  </div>
                </Col>
                <Col xs={12} sm={6}>
                  <div className="d-grid">
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        navigate("/investor-signin");
                      }}
                    >
                      Investor Sign In
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
