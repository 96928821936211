import React, { useEffect, useRef, useState, useCallback } from "react";
import "./ContentDetails.scss";
import { get } from "utils/BeeApi";

import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { useContentContext } from "contexts/ContentContext";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const ContentDetails = ({ contentId, view, onContentFetched }) => {
  const {
    allFilteredContent,
    bookmarkableIds,
    isLoading,
    error,
    markBookmark,
  } = useContentContext();
  const [content, setContent] = useState();
  const [isContentLoading, setIsLoading] = useState(false);
  const [contentError, setError] = useState("");
  const subscribedPromises = useRef([]);

  const contentGroupId = ["660d4edc41aed"];

  const isWhatsNew = content?.categories?.find((category) => {
    return category?.groups?.some((group) =>
      contentGroupId.includes(group?.groupId)
    );
  });

  useEffect(() => {
    if (isWhatsNew) {
      markBookmark(contentId);
    }
  }, [contentId, markBookmark, isWhatsNew]);

  useEffect(() => {
    const promises = subscribedPromises.current;
    fetchContent(contentId);
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, [contentId]);

  const setResponsiveness = useCallback((bodyNode) => {
    if (bodyNode !== null) {
      let aTags = bodyNode.getElementsByTagName("a");
      for (let aTag of aTags) {
        aTag.setAttribute("target", "_blank");
        aTag.setAttribute("rel", "noopener noreferrer");
      }

      let imgTags = bodyNode.getElementsByTagName("img");
      for (let img of imgTags) {
        img.setAttribute("class", "img-fluid");
      }

      let iframeTags = bodyNode.getElementsByTagName("iframe");
      for (let iframe of iframeTags) {
        iframe.removeAttribute("height");
        iframe.removeAttribute("width");
        let wrapper = document.createElement("div");
        wrapper.className = "ratio ratio-16x9";
        // insert wrapper before el in the DOM tree
        iframe.parentNode.insertBefore(wrapper, iframe);
        wrapper.appendChild(iframe);
      }

      let figureTags = bodyNode.getElementsByTagName("figureTags");
      for (let figure of figureTags) {
        figure.setAttribute("class", "img-fluid");
      }
    }
  }, []);

  function fetchContent(id) {
    setIsLoading(true);
    setError("");

    const createPromise = get(`contents/${id}`, {});
    createPromise.promise
      .then((response) => {
        setContent(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(createPromise);
  }
  if (isContentLoading) return <Loader />;
  if (contentError) return <ErrorHandler error={contentError} />;
  if (!content) return <span />;

  return (
    <>
      <Col xs={10} sm={6} md={12}>
        {isWhatsNew && (
          <div className="ContentDetails">
            <div className="float-end whatsnew ms-3 border border-2 rounded-2 border-primary">
              <Link
                to="/all-updates"
                className="float-end text-decoration-none"
              >
                <small>View all updates</small>
              </Link>
              <h4 className="pt-1 lh-1">What's New</h4>
              <hr className="divider" />
              {error && <ErrorHandler error={error} />}
              {isLoading ? (
                <Loader />
              ) : (
                <div>
                  {allFilteredContent.length !== 0 ? (
                    <div className="mt-3">
                      {allFilteredContent?.slice(0, 4)?.map((item, index) => (
                        <div className="mb-2" key={item.contentId}>
                          <div>
                            <span className="gray">
                              <small>
                                {new Date(item.createdAt).toLocaleString(
                                  "default",
                                  {
                                    month: "long",
                                    day: "numeric",
                                    year: "numeric",
                                  }
                                )}
                              </small>
                            </span>
                            <Link
                              to={`/contents/${item.contentId}`}
                              className="w-max d-flex p-0 text-decoration-none"
                            >
                              {" "}
                              <h4>
                                {item.title}
                                {!bookmarkableIds?.includes(item.contentId) && (
                                  <sup>
                                    <span className="fs-5 ms-1 text-orange">
                                      &#9679;
                                    </span>
                                  </sup>
                                )}
                              </h4>
                            </Link>
                          </div>
                          <span className="gray w-25">
                            <small>
                              {item?.description &&
                              item?.description?.length > 150
                                ? item?.description?.substring(0, 157) + "..."
                                : item?.description}
                            </small>
                          </span>
                          {index <
                            allFilteredContent.slice(0, 4).length - 1 && (
                            <hr className="divider" />
                          )}
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        )}
      </Col>

      {isWhatsNew ? (
        <Col xs={10} sm={6} md={8}>
          {isContentLoading ? (
            <Loader />
          ) : (
            <div>
              {view === "full" && (
                <>
                  <h4>{content.title}</h4>
                  <hr />
                </>
              )}

              <div
                className="ContentDetails "
                ref={setResponsiveness}
                dangerouslySetInnerHTML={{ __html: content.contentBody }}
              />
            </div>
          )}
        </Col>
      ) : isContentLoading ? (
        <Loader />
      ) : (
        <div>
          {view === "full" && (
            <>
              <h1>{content.title}</h1>
              <hr />
            </>
          )}
          <div
            className="ContentDetails"
            ref={setResponsiveness}
            dangerouslySetInnerHTML={{ __html: content.contentBody }}
          />
        </div>
      )}
    </>
  );
};

export default ContentDetails;
