import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import { UserDispatchContext } from "contexts/UserProvider";
import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import { get } from "utils/DeApi";

function GoogleCallback() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { setUser } = useContext(UserDispatchContext);
  const location = useLocation();
  const navigate = useNavigate();

  // On page load, we take "search" parameters
  // and proxy them to /api/auth/callback on our Laravel API
  useEffect(() => {
    const authCall = get(`/google-auth/callback${location.search}`);
    authCall.promise
      .then((response) => {
        setLoading(false);
        localStorage.setItem("email", response?.data?.member?.email);
        localStorage.setItem("firstName", response?.data?.memberName);
        setUser({
          firstName: response?.data?.memberName,
          role: "investor",
          syndicationGroup: response.data.member.syndicationGroup,
          ...response?.data?.member,
        });
      })
      .catch((err) => {
        setError(
          "Provide email does not currently have investor access. Go to login page by clicking below to use the link present on investor tab of login to request access."
        );
        setLoading(false);
      });
  }, []);

  if (loading) return <Loader />;
  if (error)
    return (
      <div className="d-flex flex-column align-items-center w-50 mx-auto my-0">
        <ErrorHandler message={error} />
        <Button onClick={() => navigate("/")}>Go to Login</Button>
      </div>
    );

  return <Navigate to={{ pathname: "/", state: { from: location } }} />;
}

export default GoogleCallback;
