import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { post, get } from "../../../../utils/DeApi";
import {
  Row,
  Col,
  Container,
  Button,
  Card,
  Badge,
  Table,
} from "react-bootstrap";
import Header from "../../../../components/App/Header/Header";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import VoteInstruction from "./VoteInstruction";
import { UserContext } from "contexts/UserProvider";
import Footer from "components/App/Footer/Footer";
import DataLayer from "utils/DataLayer";

const voteOptions = [
  "100%",
  "75%",
  "50%",
  "25% ",
  "0%",
  "Conflict of interest",
];

const previousVotes = [
  "Likely to raise $500k",
  "Likely to raise some money",
  "50% chance of raising money",
  "25% chance of raising money",
  "Unlikely to raise money",
  "Conflict of interest",
];

const VoteProfile = () => {
  const subscribedPromises = useRef([]);
  const { id } = useParams();

  const user = useContext(UserContext);

  const [val, setVal] = useState();
  const [voted, setVoted] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState();
  const [error, setError] = useState();

  const sentVote = (value) => {
    setVal(value);
    const createVote = post("/votes", {
      venture_id: id,
      member_uuid: user?.id || user?.userId,
      choice: value,
    });
    createVote.promise
      .then((response) => {
        setVoted(response.data.choice);

        setIsLoading(false);
        setSuccess(true);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error);
      });
    subscribedPromises.current.push(createVote);
  };

  const formatPhone = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return phoneNumberString;
  };

  let [voteData, setvoteData] = useState("");
  const [profileData, setProfileData] = useState();

  useEffect(() => {
    const profilePromise = get(`/ventures/${id}`);
    const votesPromise = get(
      `/votes?ventureId=${id}&memberUuid=${user?.member?.id || user?.id}`
    );

    Promise.all([profilePromise.promise, votesPromise.promise])
      .then((responses) => {
        setProfileData(responses[0].data);
        setvoteData(responses[1].data);
        setIsLoading(false);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(profilePromise, votesPromise);
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [id]);

  return (
    <>
      <Header />
      <Container className="MainContent">
        {isLoading && <Loader />}
        {error && <ErrorHandler error={error} />}
        {profileData && (
          <Row className="my-5">
            <Col lg={9} md={9}>
              <Card className="mb-5">
                <Card.Body>
                  <span className="float-end">
                    {!!profileData.isPresenter && (
                      <Badge className="px-3 py-2 bg-white border text-dark border-dark text-uppercase">
                        Presenter
                      </Badge>
                    )}
                  </span>
                  <h2 className="text-capitalize text-primary">
                    {profileData.companyName}
                  </h2>
                  <hr />
                  <p>{profileData.description}</p>

                  <p>
                    {profileData.companyWebsite && (
                      <span className="me-4">
                        <span className="material-icons-outlined">link</span>{" "}
                        <a
                          href={profileData?.companyWebsite}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {profileData.companyWebsite || "n/a"}
                        </a>
                      </span>
                    )}

                    <span className="me-4 text-capitalize">
                      <span className="material-icons-outlined">pin_drop</span>{" "}
                      {profileData.state && `${profileData.state}, `}
                      {profileData.country}
                      {}
                    </span>
                  </p>

                  <Table responsive hover className="my-3">
                    <thead>
                      <tr>
                        <th className="fs-4 fw-normal" colSpan={2}>
                          Key Documents
                        </th>
                      </tr>
                    </thead>
                    <tbody className="border-top-0">
                      <tr>
                        <td className="w-25">Executive Summary</td>
                        <td>
                          {profileData.executiveSummaryLink ? (
                            <a
                              href={profileData.executiveSummaryLink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {profileData.executiveSummaryLink}
                            </a>
                          ) : (
                            <span className="text-muted">N/A</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-25">Pitch Video</td>
                        <td>
                          {profileData.executiveSummaryLink ? (
                            <a
                              href={profileData.executiveSummaryLink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {profileData.executiveSummaryLink}
                            </a>
                          ) : (
                            <span className="text-muted">N/A</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-25">Slide Deck</td>
                        <td>
                          {profileData.slideDeckLink ? (
                            <a
                              href={profileData.slideDeckLink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {profileData.slideDeckLink}
                            </a>
                          ) : (
                            <span className="text-muted">N/A</span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  <Table responsive hover className="my-3">
                    <thead>
                      <tr>
                        <th className="fs-4 fw-normal" colSpan={2}>
                          Deal Information
                        </th>
                      </tr>
                    </thead>
                    <tbody className="border-top-0">
                      <tr>
                        <td className="w-25">Deal Room</td>
                        <td>
                          {profileData.dealRoomLink ? (
                            <a
                              href={profileData.dealRoomLink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {profileData.dealRoomLink}
                            </a>
                          ) : (
                            <span className="text-muted">N/A</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-25">Gust Profile</td>
                        <td>
                          {profileData.gustProfileLink ? (
                            <a
                              href={profileData.gustProfileLink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {profileData.gustProfileLink}
                            </a>
                          ) : (
                            <span className="text-muted">N/A</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-25">Dealum Profile</td>
                        <td>
                          {profileData.dealumProfileLink ? (
                            <a
                              href={profileData.dealumProfileLink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {profileData.dealumProfileLink}
                            </a>
                          ) : (
                            <span className="text-muted">N/A</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-25">
                          Special Interest Qualifications
                        </td>
                        <td>
                          {profileData.qualification?.length > 0 ? (
                            <>
                              {profileData.qualification.map(
                                (qualification) => (
                                  <span
                                    className="px-3 py-1 me-2 bg-secondary bg-dark bg-opacity-10 me-1 rounded text-capitalize"
                                    key={qualification}
                                  >
                                    {qualification}
                                  </span>
                                )
                              )}
                            </>
                          ) : (
                            <span className="text-muted">N/A</span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  <Table responsive hover className="my-3">
                    <thead>
                      <tr>
                        <th className="fs-4 fw-normal" colSpan={2}>
                          Round Information
                        </th>
                      </tr>
                    </thead>
                    <tbody className="border-top-0">
                      <tr>
                        <td className="w-25">Raise Amount</td>
                        <td>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            currencyDisplay: "narrowSymbol",
                            maximumFractionDigits: 0,
                          }).format(Number(profileData.amount))}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-25">% of Round Committed</td>
                        <td>{profileData.committed}</td>
                      </tr>
                      <tr>
                        <td className="w-25">Valuation/Cap</td>
                        <td>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            currencyDisplay: "narrowSymbol",
                            maximumFractionDigits: 0,
                          }).format(Number(profileData.valuation))}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-25">Security Type</td>
                        <td>{profileData.securityType}</td>
                      </tr>
                    </tbody>
                  </Table>

                  <Table responsive hover className="my-3">
                    <thead>
                      <tr>
                        <th className="fs-4 fw-normal" colSpan={2}>
                          CEO Contact Information
                        </th>
                      </tr>
                    </thead>
                    <tbody className="border-top-0">
                      <tr>
                        <td className="w-25">Name</td>
                        <td>{profileData.ceoName}</td>
                      </tr>
                      <tr>
                        <td className="w-25">Mobile Number</td>
                        <td>{formatPhone(profileData.mobileNumber)}</td>
                      </tr>
                      <tr>
                        <td className="w-25">Email</td>
                        <td>
                          {profileData.emailAddress && (
                            <a href={`mailto:${profileData.emailAddress}`}>
                              {profileData.emailAddress}
                            </a>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  <Table responsive hover className="my-3">
                    <thead>
                      <tr>
                        <th className="fs-4 fw-normal" colSpan={2}>
                          Nominating Investor Information
                        </th>
                      </tr>
                    </thead>
                    <tbody className="border-top-0">
                      <tr>
                        <td className="w-25">Lead Group</td>
                        <td>{profileData.dealLeadGroup}</td>
                      </tr>
                      <tr>
                        <td className="w-25">Deal Lead Name</td>
                        <td>{profileData.dealLeadName}</td>
                      </tr>
                      <tr>
                        <td className="w-25">Deal Lead Email</td>
                        <td>
                          {profileData.dealLeadEmail?.length > 10 && (
                            <a href={`mailto:${profileData?.dealLeadEmail}`}>
                              {profileData.dealLeadEmail}
                            </a>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={3} md={3}>
              <div className="my-3">
                <h3 className="text-primary">Nominate for Presentation Slot</h3>
                <p>
                  How likely do you feel investors (not necessarily you) will
                  fill this round? Your answer helps us select which companies
                  get presentation slots.
                </p>
                <VoteInstruction />
              </div>
              <Row>
                {voteOptions.map((value, index) => {
                  return (
                    <Col lg={12} key={index}>
                      <Button
                        key={index}
                        variant={
                          voteData.choice === value ||
                          value === val ||
                          voteData.choice === previousVotes[index]
                            ? "primary text-white"
                            : "outline-primary"
                        }
                        className={`my-2 w-100`}
                        onClick={() => {
                          setvoteData(value);
                          sentVote(value);
                        }}
                      >
                        {value}
                      </Button>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default VoteProfile;
