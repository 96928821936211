import { useState, useMemo, useRef, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import countryList from "react-select-country-list";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import FormLabel from "react-bootstrap/FormLabel";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import { Formik } from "formik";
import CustomSelect from "components/App/Formik/CustomSelect";
import CustomFormControl from "components/App/Formik/CustomFormControl";
import CustomFormCheck from "components/App/Formik/CustomFormCheck";
import CustomPhoneInput from "components/App/Formik/CustomPhoneInput";
import CustomNumericFormat from "components/App/Formik/CustomNumericFormat";

import RoundHistory from "components/Dashboard/RoundHistory/RoundHistory";
import { UserContext } from "contexts/UserProvider";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

import { post, put } from "../../../../utils/DeApi";
import { get } from "../../../../utils/DeApi";

import { validationSchema } from "./schema";
import {
  stateOptions,
  specialInterestQualifications,
  securityTypes,
  committedPercentage,
} from "shared/constants/venture";
import Header from "components/App/Header/Header";
import Footer from "components/App/Footer/Footer";

const NewEdidProfile = () => {
  const [apiData, setApiData] = useState({});
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const profiles = useRef([]);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [syndicationGroup, setSyndicationGroup] = useState();
  const [memberDetails, setMemberDetails] = useState();

  // it sets true when the component is rendered first time after api call
  // using isMounted to stop page loader on update
  const isMounted = useRef(false);
  // set timeout id
  const setTimeoutId = useRef(null);

  const { id } = useParams();
  const user = useContext(UserContext);
  const countries = useMemo(() => countryList().getData(), []);

  const subscribedPromises = useRef([]);

  useEffect(() => {
    setIsLoading(true);
    const fetchVentures = get(`/ventures/${id}`);
    const fetchSyndicationGroups = get(`/nominations/${user?.email}`);
    Promise.all([fetchVentures.promise, fetchSyndicationGroups.promise])
      .then((response) => {
        const ventureResponse = response[0];
        const syndicationGroupsResponse =
          response?.[1]?.data?.syndicationGroups;

        setSyndicationGroup(syndicationGroupsResponse);
        setMemberDetails(response?.[1]?.data?.member);
        setApiData(ventureResponse?.data);
        setIsLoading(false);
        isMounted.current = true;
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(fetchVentures, fetchSyndicationGroups);

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });

      // update unmount ref
      isMounted.current = false;
      // clear setTimeout
      clearInterval(setTimeoutId.current);
    };
  }, [id, user.email]);

  function convertAmountStringToNumber(amountString) {
    const cleanedString = amountString.replace(/[$,]/g, "");

    return parseFloat(cleanedString);
  }

  const hideSuccessAlert = (time = 3000) => {
    const id = setTimeout(() => {
      setHasUpdated(false);
    }, time);
    setTimeoutId.current = id;
  };

  const updateProfile = (values) => {
    const newVentureRound = values.isAddNewRound
      ? {
          isPresenter: false,
          securityType: values.newVentureRoundSecurityType,
          amount: convertAmountStringToNumber(values.newVentureRoundAmount),
          committed: values.newVentureRoundCommitted,
          valuation: convertAmountStringToNumber(
            values.newVentureRoundValuation
          ),
          other:
            values.newVentureRoundSecurityType === "Other" ||
            values.newVentureRoundSecurityType === "other"
              ? values.newVentureRoundReason
              : null,
        }
      : null;

    setIsLoading(true);
    const profilePromise = put(`/ventures/${id}`, {
      emailAddress: values?.emailAddress,
      amount: convertAmountStringToNumber(values?.amount),
      companyName: values?.companyName,
      companyWebsite: values?.companyWebsite,
      description: values?.description,
      country: values?.country,
      state: values?.state,
      executiveSummaryLink: values?.executiveSummaryLink,
      pitchVideoLink: values?.pitchVideoLink,
      slideDeckLink: values?.slideDeckLink,
      dealRoomLink: values?.dealRoomLink,
      gustProfileLink: values?.gustProfileLink,
      dealumProfileLink: values?.dealumProfileLink,
      qualification: values?.qualification,
      committed: values?.committed,
      valuation: convertAmountStringToNumber(values?.valuation),
      securityType: values?.securityType,
      other: values.reason,
      ceoName: values?.ceoName,
      mobileNumber: values?.mobileNumber,
      dealLeadEmail: values?.dealLeadEmail,
      dealLeadGroup: values?.dealLeadGroup,
      dealLeadName: values?.dealLeadName,
      dealStatus: values?.dealStatus,
      isRound: values?.isRound,
      currentVentureRound: {
        isRoundClosed: values?.isRound,
        securityType: values.securityType,
        amount: convertAmountStringToNumber(values.amount),
        committed: values.committed,
        valuation: convertAmountStringToNumber(values.valuation),
        other:
          values.securityType === "Other" || values.securityType === "other"
            ? values.reason
            : null,
      },
      newVentureRound: newVentureRound,
    });
    profilePromise.promise
      .then((response) => {
        setApiData(response?.data);
        const activeVenture = post("venture-active", {
          ventureId: id,
        });
        return activeVenture.promise;
      })
      .then(() => {
        setError(null);
        setIsLoading(false);
        setHasUpdated(true);
        hideSuccessAlert(5000);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    profiles.current.push(profilePromise);
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  };

  const renderEditProfile = () => {
    if (isLoading && !isMounted.current) return <Loader />;
    if (error) return <ErrorHandler error={error} />;

    return (
      <>
        {/* Application To ACA Syndication section */}
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <h2 className="text-center text-primary fs-3 my-2">
                  Application To ACA Syndication
                </h2>
                <div className="border-bottom border-dark w-75 m-auto"></div>

                <p className="text-center my-2">
                  A deal lead (an investor willing to coordinate due diligence
                  for syndication) is required to be included in the packet.
                  Your deal lead must be a member of the Angel Capital
                  Association, or a member of a member-group of the Angel
                  Capital Association.{" "}
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* formim section */}
        <Formik
          enableReinitialize
          initialValues={{
            companyName: apiData?.companyName || "",
            companyWebsite: apiData?.companyWebsite || "",
            country: apiData?.country,
            state: apiData?.state || "",
            description: apiData?.description || "",
            isRound: Number(apiData?.currentVentureRound?.isRoundClosed),
            emailAddress: apiData?.emailAddress || "",
            dealRoomLink: apiData?.dealRoomLink || "",
            gustProfileLink: apiData?.gustProfileLink || "",
            dealumProfileLink: apiData?.dealumProfileLink || "",
            ceoName: apiData?.ceoName || "",
            amount: String(apiData?.currentVentureRound?.amount),
            mobileNumber: apiData?.mobileNumber || "",
            qualification: apiData?.qualification || [],
            dealStatus: apiData?.dealStatus,
            securityType: apiData?.currentVentureRound?.securityType,
            committed: apiData?.currentVentureRound?.committed,
            dealLeadEmail: memberDetails?.[1] || apiData?.dealLeadEmail,
            dealLeadName: memberDetails?.[0] || apiData?.dealLeadName,
            dealLeadGroup: apiData?.dealLeadGroup || "",
            valuation: String(apiData?.currentVentureRound?.valuation),
            slideDeckLink: apiData?.slideDeckLink,
            pitchVideoLink: apiData?.pitchVideoLink || "",
            executiveSummaryLink: apiData?.executiveSummaryLink || "",
            reason: apiData?.currentVentureRound?.other,

            // new venture round
            newVentureRoundAmount: "",
            newVentureRoundValuation: "",
            newVentureRoundCommitted: "",
            newVentureRoundSecurityType: "",
            newVentureRoundReason: "",

            // new round boolean
            isAddNewRound: false,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            updateProfile(values);
          }}
        >
          {(props) => {
            return (
              <Form onSubmit={props.handleSubmit}>
                {/* company information section */}
                <Card className="mt-4">
                  <Card.Body>
                    <h4 className="text-primary">Company Information</h4>
                    <Row className="mt-4">
                      <Col sm={12} lg={6}>
                        <CustomFormControl
                          label="Company Name"
                          name="companyName"
                          id="companyName"
                          type="text"
                          required
                        />
                      </Col>
                      <Col sm={12} lg={6}>
                        <CustomFormControl
                          label="Website"
                          name="companyWebsite"
                          id="companyWebsite"
                          type="url"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col>
                        <CustomSelect
                          label="Country"
                          name="country"
                          onClick={(e) => {
                            if (e.target.value !== "United States")
                              props.setFieldValue("state", "");
                          }}
                          id="country"
                          required
                        >
                          <option value="">Please select country</option>
                          {countries.map((el) => {
                            return (
                              <option key={el.label} value={el.label}>
                                {el.label}
                              </option>
                            );
                          })}
                        </CustomSelect>
                      </Col>
                    </Row>
                    {props.values.country === "United States" && (
                      <Row className="mt-4">
                        <Col>
                          <CustomSelect label="State" name="state" id="state">
                            <option value="">Please select state</option>
                            {stateOptions.map((el) => {
                              return (
                                <option key={el.name} value={el.name}>
                                  {el.name}
                                </option>
                              );
                            })}
                          </CustomSelect>
                        </Col>
                      </Row>
                    )}
                    <Row className="mt-4">
                      <Col>
                        <CustomFormControl
                          label="Description"
                          secondaryLabel="Give us the 1-line (maximum of 140 characters) pitch for your venture"
                          name="description"
                          as="textarea"
                          id="description"
                          required
                        />
                      </Col>
                    </Row>
                    <Row className="mt-5">
                      <Col lg={12} md={12} sm={12}>
                        <h4 className="text-primary">Key Documents</h4>
                        <Form.Text>
                          Kindly ensure that the investor are able to access the
                          link.
                          <span className="text-danger mx-1">*</span>
                        </Form.Text>
                      </Col>

                      <Col lg={4} className="mt-2">
                        <CustomFormControl
                          label="Executive Summary Link"
                          name="executiveSummaryLink"
                          id="executiveSummaryLink"
                          type="url"
                        />
                      </Col>
                      <Col lg={4}>
                        <CustomFormControl
                          label="Pitch Deck Link"
                          name="slideDeckLink"
                          id="slideDeckLink"
                          type="url"
                          required
                        />
                      </Col>
                      <Col lg={4}>
                        <CustomFormControl
                          label="Pitch video Link"
                          name="pitchVideoLink"
                          id="pitchVideoLink"
                          type="url"
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* deal information section */}
                <Card className="mt-4">
                  <Card.Body>
                    <h4 className="text-primary">Deal Information</h4>
                    <Row className="mt-4">
                      <Col lg={4}>
                        <CustomFormControl
                          label="Deal Room Link"
                          name="dealRoomLink"
                          id="dealRoomLink"
                          type="url"
                        />
                      </Col>
                      <Col lg={4}>
                        <CustomFormControl
                          label="Gust Profile Link"
                          name="gustProfileLink"
                          id="gustProfileLink"
                          type="url"
                        />
                      </Col>
                      <Col lg={4}>
                        <CustomFormControl
                          label="Dealum Profile Link"
                          name="dealumProfileLink"
                          id="dealumProfileLink"
                          type="url"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col lg={6}>
                        <FormLabel className="text-secondary">
                          Special Interest Qualification
                        </FormLabel>
                        {specialInterestQualifications.map((qualification) => (
                          <CustomFormCheck
                            label={qualification.label}
                            name="qualification"
                            id={qualification.id}
                            value={qualification.value}
                            type="checkbox"
                            key={qualification.value}
                          />
                        ))}
                      </Col>
                      <Col lg={6}>
                        <FormLabel className="text-secondary">
                          Syndication Group
                          <span className="text-danger">*</span>
                        </FormLabel>
                        {syndicationGroup?.map((value) => (
                          <CustomFormCheck
                            label={value}
                            name="syndicationGroups"
                            id={value}
                            value={value}
                            checked={true}
                            type="checkbox"
                            key={value}
                            disabled
                          />
                        ))}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* ceo and nominating section */}
                <Card className="mt-4">
                  <Card.Body>
                    <h4 className="text-primary">CEO Contact Information</h4>
                    <Row className="mt-4">
                      <Col sm={12} lg={6}>
                        <CustomFormControl
                          label="CEO Name"
                          name="ceoName"
                          id="ceoName"
                          type="text"
                          required
                        />
                      </Col>
                      <Col sm={12} lg={6}>
                        <CustomPhoneInput
                          label="CEO Mobile Number"
                          name="mobileNumber"
                          id="mobileNumber"
                          required
                        />
                      </Col>
                    </Row>

                    <div className="mt-5">
                      <Row>
                        <h4 className="text-primary">
                          Nominating Investor Information
                        </h4>
                      </Row>
                      <Row>
                        <Col sm={12} lg={6}>
                          <CustomFormControl
                            label="Nominating Investor's Angel Group/Fund"
                            name="dealLeadGroup"
                            id="dealLeadGroup"
                            type="text"
                            required
                          />
                        </Col>
                        <Col sm={12} lg={6}>
                          <CustomFormControl
                            label="Nominating Investor's Name"
                            name="dealLeadName"
                            id="dealLeadName"
                            type="text"
                            required
                            disabled
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col>
                          <CustomFormControl
                            label="Nominating Investor's Email"
                            name="dealLeadEmail"
                            id="dealLeadEmail"
                            type="text"
                            required
                            disabled
                          />
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>

                {/* current round section */}
                <Card className="mt-4">
                  <Card.Body>
                    <div
                      className="p-2 rounded"
                      style={{
                        background: props.values.isRound ? "#F5F5F5" : "",
                      }}
                    >
                      <Row>
                        <Col className="d-flex justify-content-between align-items-center">
                          <h4 className="text-primary">
                            Current Round Information
                          </h4>
                          <RoundHistory roundHistory={apiData?.ventureRounds} />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col sm={12} lg={6}>
                          <CustomNumericFormat
                            label="Raise Amount"
                            secondaryLabel="The total amount you are raising in this round"
                            name="amount"
                            id="amount"
                            disabled={props.values.isRound}
                            required
                          />
                        </Col>
                        <Col sm={12} lg={6}>
                          <CustomNumericFormat
                            label="Valuation/Cap"
                            secondaryLabel="PRE-Money valuation or PRE-Money cap"
                            name="valuation"
                            id="valuation"
                            disabled={props.values.isRound}
                            required
                          />
                        </Col>
                        <FormLabel className="text-primary fw-bold mt-1">
                          Please write out the full amount. Example: 1 million
                          should be entered as 1000000
                        </FormLabel>
                      </Row>
                      <Row className="mt-4">
                        <Col sm={12} lg={6}>
                          <FormLabel className="text-secondary">
                            %Committed
                            <span className="text-danger">*</span>
                          </FormLabel>
                          {committedPercentage.map((percentage) => (
                            <CustomFormCheck
                              key={percentage.value}
                              label={percentage.label}
                              name="committed"
                              value={percentage.value}
                              id={percentage.id}
                              disabled={props.values.isRound}
                              type="radio"
                            />
                          ))}
                          {props.errors.committed && props.touched.committed ? (
                            <Form.Text type="invalid" className="text-danger">
                              {props.errors.committed}
                            </Form.Text>
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col sm={12} lg={6}>
                          <FormLabel className="text-secondary">
                            Security Type
                            <span className="text-danger">*</span>
                          </FormLabel>

                          {securityTypes.map((security) => (
                            <CustomFormCheck
                              key={security.value}
                              label={security.label}
                              name="securityType"
                              value={security.value}
                              id={security.id}
                              disabled={props.values.isRound}
                              type="radio"
                              onClick={(e) => {
                                if (e.target.value !== "Other")
                                  props.setFieldValue("reason", "");
                              }}
                            />
                          ))}
                          {props.errors.securityType &&
                          props.touched.securityType ? (
                            <Form.Text type="invalid" className="text-danger">
                              {props.errors.securityType}
                            </Form.Text>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                      {props.values.securityType === "Other" && (
                        <Row className="mt-4">
                          <Col>
                            <CustomFormControl
                              label="Please specify"
                              name="reason"
                              id="reason"
                              type="text"
                              disabled={props.values.isRound}
                              required
                            />
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col>
                          <hr />
                          <CustomFormCheck
                            type="checkbox"
                            label="Round is closed"
                            name="isRound"
                            onChange={(e) => {
                              // Update Formik's value directly to avoid array as checkbox sets the value in array
                              props.setFieldValue("isRound", e.target.checked);
                            }}
                            onClick={(e) => {
                              if (e.target.checked === false) {
                                props.setFieldValue("isAddNewRound", false);
                                props.setFieldValue(
                                  "newVentureRoundAmount",
                                  ""
                                );
                                props.setFieldValue(
                                  "newVentureRoundValuation",
                                  ""
                                );
                                props.setFieldValue(
                                  "newVentureRoundCommitted",
                                  ""
                                );
                                props.setFieldValue(
                                  "newVentureRoundSecurityType",
                                  ""
                                );
                              }
                            }}
                            disabled={
                              !apiData?.currentVentureRound?.isEditable === true
                            }
                            id="isRound"
                          />
                          {props.values.isRound &&
                          apiData?.currentVentureRound?.isEditable === true ? (
                            <p className="text-danger">
                              * Deselect to reopen the current round.
                              <br />
                              * Once a new round is created, the previous round
                              cannot be reopened.
                              <br />* A round cannot be reopened after 120 days
                              have passed since its creation.
                            </p>
                          ) : null}

                          {apiData?.currentVentureRound?.isEditable ===
                            false && (
                            <p className="text-danger">
                              * The system has concluded the round as 120 days
                              have elapsed since its creation.
                              <br />* Hit the "Add New Round" button to update
                              the venture.
                            </p>
                          )}
                          <hr />
                        </Col>
                      </Row>
                    </div>

                    {/* new round section */}
                    {props.values.isRound ? (
                      <Button
                        className="my-4"
                        onClick={() =>
                          props.setFieldValue("isAddNewRound", true)
                        }
                      >
                        Add New Round
                      </Button>
                    ) : null}

                    {props.values.isAddNewRound ? (
                      <>
                        <h4 className="text-primary">New Round Information</h4>

                        <Row className="mt-4">
                          <Col sm={12} lg={6}>
                            <CustomNumericFormat
                              label="Raise Amount"
                              secondaryLabel="The total amount you are raising in this round"
                              name="newVentureRoundAmount"
                              id="newVentureRoundAmount"
                              required
                            />
                          </Col>
                          <Col sm={12} lg={6}>
                            <CustomNumericFormat
                              label="Valuation/Cap"
                              secondaryLabel="PRE-Money valuation or PRE-Money cap"
                              name="newVentureRoundValuation"
                              id="newVentureRoundValuation"
                              required
                            />
                          </Col>
                          <FormLabel className="text-primary fw-bold mt-1">
                            Please write out the full amount. Example: 1 million
                            should be entered as 1000000
                          </FormLabel>
                        </Row>
                        <Row className="mt-4">
                          <Col sm={12} lg={6}>
                            <FormLabel className="text-secondary">
                              %Committed
                              <span className="text-danger">*</span>
                            </FormLabel>
                            {committedPercentage.map((percentage) => (
                              <CustomFormCheck
                                key={percentage.value}
                                label={percentage.label}
                                name="newVentureRoundCommitted"
                                value={percentage.value}
                                id={percentage.id + "nvr"}
                                type="radio"
                              />
                            ))}
                            {props.errors.newVentureRoundCommitted &&
                            props.touched.newVentureRoundCommitted ? (
                              <Form.Text type="invalid" className="text-danger">
                                {props.errors.newVentureRoundCommitted}
                              </Form.Text>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Col sm={12} lg={6}>
                            <FormLabel className="text-secondary">
                              Security Type
                              <span className="text-danger">*</span>
                            </FormLabel>

                            {securityTypes.map((security) => (
                              <CustomFormCheck
                                key={security.value}
                                label={security.label}
                                name="newVentureRoundSecurityType"
                                value={security.value}
                                id={security.id + "nvr"}
                                onClick={(e) => {
                                  if (e.target.value !== "Other")
                                    props.setFieldValue(
                                      "newVentureRoundReason",
                                      ""
                                    );
                                }}
                                type="radio"
                              />
                            ))}
                            {props.errors.newVentureRoundSecurityType &&
                            props.touched.newVentureRoundSecurityType ? (
                              <Form.Text type="invalid" className="text-danger">
                                {props.errors.newVentureRoundSecurityType}
                              </Form.Text>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                        {props.values.newVentureRoundSecurityType ===
                          "Other" && (
                          <Row className="mt-4">
                            <Col>
                              <CustomFormControl
                                label="Please specify"
                                name="newVentureRoundReason"
                                id="newVentureRoundReason"
                                type="text"
                                required
                              />
                            </Col>
                          </Row>
                        )}
                      </>
                    ) : null}

                    <Row className="mb-4">
                      <Col sm={12} lg={12}>
                        {hasUpdated && (
                          <Alert variant="success mx-auto ">
                            <Alert.Heading>Success</Alert.Heading>
                            Profile updated successfully!
                          </Alert>
                        )}
                      </Col>
                      <Col
                        sm={12}
                        lg={12}
                        className="mt-4 d-flex justify-content-middle"
                      >
                        {(apiData?.currentVentureRound?.isEditable ||
                          (!apiData?.currentVentureRound?.isEditable &&
                            props.values?.isAddNewRound)) && (
                          <Button
                            className="btn btn-primary text-white w-50 m-auto"
                            disabled={isLoading}
                            type="submit"
                          >
                            Update
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  };

  return (
    <>
      <Header />

      <Container className="my-5 MainContent">
        <Row className="d-flex justify-content-center">
          <Col lg={10}>{renderEditProfile()}</Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default NewEdidProfile;
