import { useContext } from "react";

import { Col, Container, Row } from "react-bootstrap";

import Header from "components/App/Header/Header";

import { UserContext } from "contexts/UserProvider";

import Footer from "components/App/Footer/Footer";
import InvestorDashboard from "components/InvestorDashboard/InvestorDashboard";

import Venture from "./VentureProfile/Venture/Venture";
import DataLayer from "utils/DataLayer";

const Dashboard = () => {
  const user = useContext(UserContext);

  const isInvestor = user.role === "investor";

  return (
    <>
      <Header />
      <DataLayer />
      <Container className="MainContent" fluid={isInvestor}>
        <Row>
          <Col>{isInvestor ? <InvestorDashboard /> : <Venture />}</Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Dashboard;
