import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import RedirectIfAuthenticated from "components/App/RedirectIfAuthenticated/RedirectIfAuthenticated";
import Copyright from "components/App/Copyright/Copyright";
import TextLogo from "components/App/TextLogo/TextLogo";

const LoginWrapper = ({ children }) => {
  return (
    <>
      <RedirectIfAuthenticated />
      <Container className="Login mt-4 mb-4">
        <Row className="justify-content-center">
          <Col xl={5} lg={6} md={8} sm={10}>
            <TextLogo />
            <Card className="border-0 shadow-sm">
              <Card.Body>{children}</Card.Body>
            </Card>
            <div className="my-3 text-center">
              <p className="text-dark mb-1">
                <small>
                  <Copyright />
                </small>
              </p>
              <p className="text-dark my-2">Powered by PixelEdge.io</p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LoginWrapper;
