import React, { useState } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import "./RoundHistory.scss";

function RoundHistory({ roundHistory }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {roundHistory?.length && roundHistory?.length > 1 ? (
        <>
          <Button
            variant="outline-primary"
            size="sm"
            className="text-decoration-none mt-2"
            onClick={() => handleShow()}
            disabled={!roundHistory?.length || roundHistory?.length < 2}
          >
            {/* <span className="material-icons-outlined md-18">history</span> */}
            Rounds History
          </Button>

          <Modal show={show} backdrop={"static"} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>Round History</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="historyList">
                <Table size="sm" responsive>
                  <thead>
                    <tr>
                      <th className="text-nowrap">Round</th>
                      <th className="text-end text-nowrap">Amount</th>
                      <th className="text-end text-nowrap">Valuation/Cap</th>
                      <th className="text-nowrap">% Committed</th>
                      <th className="text-nowrap">Security Type</th>
                      <th className="text-wrap">Other</th>
                      <th className="text-nowrap">Presenter</th>
                      <th className="text-nowrap">Round Closed</th>
                      <th className="text-end text-nowrap">Votes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {roundHistory?.map((round) => (
                      <tr key={round?.id}>
                        <td className="text-nowrap align-middle">
                          {round?.name}
                        </td>
                        <td className="text-end text-nowrap align-middle">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            currencyDisplay: "narrowSymbol",
                            maximumFractionDigits: 0,
                          }).format(Number(round?.amount))}
                        </td>
                        <td className="text-end text-nowrap align-middle">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            currencyDisplay: "narrowSymbol",
                            maximumFractionDigits: 0,
                          }).format(Number(round?.valuation))}
                        </td>
                        <td className="text-nowrap align-middle">
                          {round?.committed}
                        </td>
                        <td className="text-nowrap align-middle">
                          {round?.securityType}
                        </td>
                        <td className="text-wrap align-middle">
                          {round?.other}
                        </td>
                        <td className="text-nowrap align-middle">
                          {round?.isPresenter ? "Yes" : "No"}
                        </td>
                        <td className="text-nowrap align-middle">
                          {round?.isRoundClosed ? "Yes" : "No"}
                        </td>
                        <td className="text-end text-nowrap align-middle">
                          {round?.votes?.length}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button size="sm" variant="link" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default RoundHistory;
