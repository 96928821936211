import React from "react";
import "./Content.scss";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Routes, Route, useParams } from "react-router-dom";

import Header from "../App/Header/Header";
import NoMatch from "../App/NoMatch/NoMatch";
import ContentDetails from "./ContentDetails/ContentDetails";
import DataLayer from "utils/DataLayer";

const Content = () => {
  return (
    <>
      <Header title="" />
      <Container className="mb-5 mt-3 MainContent">
        <Row>
          <Col xs={12} md={12}>
            <Card className="mt-3 mb-3 py-3">
              <Card.Body>
                <Routes>
                  <Route path="/:id" element={<ContentWrapper />} />
                  <Route component={NoMatch} />
                </Routes>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const ContentWrapper = () => {
  const { id } = useParams();
  return <ContentDetails contentId={id} view={"full"} />;
};

Content.propTypes = {};

export default Content;
