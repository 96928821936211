import React, { useMemo, useState, useRef, useEffect, useContext } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  FormLabel,
  FormControl,
  Card,
  Alert,
  FormGroup,
} from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import countryList from "react-select-country-list";
import Header from "components/App/Header/Header";
import { post, put } from "../../../../utils/DeApi";
import { useParams } from "react-router-dom";
import { get } from "../../../../utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Footer from "components/App/Footer/Footer";
import ProfileInformation from "components/Dashboard/ProfileInformation/ProfileInformation";
import { UserContext } from "contexts/UserProvider";
import "./EditProfile.scss";
import RoundHistory from "components/Dashboard/RoundHistory/RoundHistory";

// security type options
const securityTypes = [
  { value: "Priced round", label: "Priced round", id: "priced-round" },
  {
    value: "Convertible note",
    label: "Convertible note",
    id: "convertible-note",
  },
  { value: "SAFE", label: "SAFE", id: "safe" },
  { value: "Other", label: "Other", id: "other" },
];

// committed options
const committedPercentage = [
  {
    value: "Over subscribing (100%)",
    label: "Over subscribing (100%)",
    id: "over-subscribing-100",
  },
  { value: "At least (75%)", label: "At least (75%)", id: "atleast-75" },
  { value: "At least (50%)", label: "At least (50%)", id: "atleast-50" },
  { value: "Less than half", label: "Less than half", id: "less-than-half" },
  { value: "Starting (0%)", label: "Starting (0%)", id: "starting-zero" },
];

// interset qualification options
const specialInterestQualifications = [
  {
    value: "LGBTQ co-founder(s)",
    label: "LGBTQ co-founder(s)",
    id: "lgbtq-co-founders",
  },
  {
    value: "People of color co-founder(s)",
    label: "People of color co-founder(s)",
    id: "people-of-color-co-founders",
  },
  {
    value: "Veteran co-founder(s)",
    label: "Veteran co-founder(s)",
    id: "veteran-co-founders",
  },
  {
    value: "Women co-founder(s)",
    label: "Women co-founder(s)",
    id: "women-co-founders",
  },
  {
    value: "Impact investment",
    label: "Impact investment",
    id: "impact-investment",
  },
];

const options = [
  { name: "Alabama", abbreviation: "AL" },
  { name: "Alaska", abbreviation: "AK" },
  { name: "Arizona", abbreviation: "AZ" },
  { name: "Arkansas", abbreviation: "AR" },
  { name: "California", abbreviation: "CA" },
  { name: "Colorado", abbreviation: "CO" },
  { name: "Connecticut", abbreviation: "CT" },
  { name: "Delaware", abbreviation: "DE" },
  { name: "Florida", abbreviation: "FL" },
  { name: "Georgia", abbreviation: "GA" },
  { name: "Hawaii", abbreviation: "HI" },
  { name: "Idaho", abbreviation: "ID" },
  { name: "Illinois", abbreviation: "IL" },
  { name: "Indiana", abbreviation: "IN" },
  { name: "Iowa", abbreviation: "IA" },
  { name: "Kansas", abbreviation: "KS" },
  { name: "Kentucky", abbreviation: "KY" },
  { name: "Louisiana", abbreviation: "LA" },
  { name: "Maine", abbreviation: "ME" },
  { name: "Maryland", abbreviation: "MD" },
  { name: "Massachusetts", abbreviation: "MA" },
  { name: "Michigan", abbreviation: "MI" },
  { name: "Minnesota", abbreviation: "MN" },
  { name: "Mississippi", abbreviation: "MS" },
  { name: "Missouri", abbreviation: "MO" },
  { name: "Montana", abbreviation: "MT" },
  { name: "Nebraska", abbreviation: "NE" },
  { name: "Nevada", abbreviation: "NV" },
  { name: "New Hampshire", abbreviation: "NH" },
  { name: "New Jersey", abbreviation: "NJ" },
  { name: "New Mexico", abbreviation: "NM" },
  { name: "New York", abbreviation: "NY" },
  { name: "North Carolina", abbreviation: "NC" },
  { name: "North Dakota", abbreviation: "ND" },
  { name: "Ohio", abbreviation: "OH" },
  { name: "Oklahoma", abbreviation: "OK" },
  { name: "Oregon", abbreviation: "OR" },
  { name: "Pennsylvania", abbreviation: "PA" },
  { name: "Rhode Island", abbreviation: "RI" },
  { name: "South Carolina", abbreviation: "SC" },
  { name: "South Dakota", abbreviation: "SD" },
  { name: "Tennessee", abbreviation: "TN" },
  { name: "Texas", abbreviation: "TX" },
  { name: "Utah", abbreviation: "UT" },
  { name: "Vermont", abbreviation: "VT" },
  { name: "Virginia", abbreviation: "VA" },
  { name: "Washington", abbreviation: "WA" },
  { name: "West Virginia", abbreviation: "WV" },
  { name: "Wisconsin", abbreviation: "WI" },
  { name: "Wyoming", abbreviation: "WY" },
];
const EditProfileSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .email("Invalid email")
    .required("This is a required field"),
  companyName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("This is a required field"),
  companyWebsite: Yup.string().url("Please Include http:// or https://"),
  country: Yup.string()
    .min(2, "Too Short")
    .max(50, "Too Long")
    .required("This is a required field  "),
  state: Yup.string(),
  description: Yup.string()
    .min(10, "Enter at least 10 characters")
    .max(140, "You can enter only 140 characters")
    .required("This is a required field"),

  executiveSummaryLink: Yup.string().url("Please Include http:// or https://"),
  slideDeckLink: Yup.string()
    .url("Please Include http:// or https://")
    .required("This is a required field"),
  pitchVideoLink: Yup.string().url("Please Include http:// or https://"),

  dealRoomLink: Yup.string().url("Please Include http:// or https://"),
  gustProfileLink: Yup.string().url("Please Include http:// or https://"),
  dealumProfileLink: Yup.string().url("Please Include http:// or https://"),

  // current venture round
  amount: Yup.string().required("This is a required field"),
  valuation: Yup.string().required("This is a required field"),
  committed: Yup.string().required("This is a required field"),
  securityType: Yup.string().required("This is a required field"),
  reason: Yup.string()
    .min(10, "Enter at least 10 characters")
    .max(140, "you can enter only 140 characters")
    .required("This is a required field"),
  isRound: Yup.boolean(),

  ceoName: Yup.string()
    .min(2, "Too Short")
    .max(50, "To Long")
    .required("This is a required field"),
  mobileNumber: Yup.string().min(8).required("This is a required field"),
  dealLeadEmail: Yup.string()
    .email("Invalid email")
    .required("This is a required field"),
  dealLeadName: Yup.string()
    .min(2, "Too Short")
    .max(50, "Too Long")
    .required("This is a required field"),
  dealLeadGroup: Yup.string()
    .min(2, "Too Short")
    .max(50, "Too Long")
    .required("This is a required field"),

  // new venture schema

  newVentureRoundAmount: Yup.string().when("isAddNewRound", {
    is: (value) => value === true,
    then: (schema) => schema.required("This is a required field"),
    otherwise: (schema) => schema,
  }),
  newVentureRoundValuation: Yup.string().when("isAddNewRound", {
    is: (value) => value,
    then: (schema) => schema.required("This is a required field"),
    otherwise: (schema) => schema,
  }),
  newVentureRoundCommitted: Yup.string().when("isAddNewRound", {
    is: (value) => value,
    then: (schema) => schema.required("This is a required field"),
    otherwise: (schema) => schema,
  }),
  newVentureRoundSecurityType: Yup.string().when("isAddNewRound", {
    is: (value) => value,
    then: (schema) => schema.required("This is a required field"),
    otherwise: (schema) => schema,
  }),

  newVentureRoundReason: Yup.string().when("isAddNewRound", {
    is: (value) => value,
    then: (schema) =>
      schema
        .min(10, "Enter at least 10 characters")
        .max(140, "you can enter only 140 characters")
        .required("This is a required field"),
    otherwise: (schema) => schema,
  }),

  // new round boolean
  isAddNewRound: Yup.boolean(),
});

export const EditProfile = () => {
  const [value, setValue] = useState("");
  const [stateVal, setStateVal] = useState("");
  const [ceoPhone, setCeoPhone] = useState("");
  let country = useMemo(() => countryList().getData(), []);
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const profiles = useRef([]);
  const { id } = useParams();
  const [hasUpdated, setHasUpdated] = useState(false);
  const [apiData, setApiData] = useState();
  const [syndicationGroup, setSyndicationGroup] = useState();
  const [memberDetails, setMemberDetails] = useState();

  // it sets true when the component is rendered first time after api call
  // using isMounted to stop page loader on update
  const isMounted = useRef(false);
  // set timeout id
  const setTimeoutId = useRef(null);

  const refs = {
    companyName: "companyName",
    companyWebsite: "companyWebsite",
    description: "description",
    executiveSummaryLink: "executiveSummaryLink",
    slideDeckLink: "slideDeckLink",
    pitchVideoLink: "pitchVideoLink",
    dealRoomLink: "dealRoomLink",
    gustProfileLink: "gustProfileLink",
    dealumProfileLink: "dealumProfileLink",
    amount: "amount",
    valuation: "valuation",
    ceoName: "ceoName",
    mobileNumber: "mobileNumber",
    dealLeadGroup: "dealLeadGroup",
    reason: "reason",
  };

  const user = useContext(UserContext);
  function convertAmountStringToNumber(amountString) {
    const cleanedString = amountString.replace(/[$,]/g, "");

    return parseFloat(cleanedString);
  }
  function phoneHandler(phone) {
    setCeoPhone(phone);
  }
  useEffect(() => {
    setIsLoading(true);
    const fetchVentures = get(`/ventures/${id}`);
    const fetchSyndicationGroups = get(`/nominations/${user?.email}`);
    Promise.all([fetchVentures.promise, fetchSyndicationGroups.promise])
      .then((response) => {
        const ventureResponse = response[0];
        const syndicationGroupsResponse =
          response?.[1]?.data?.syndicationGroups;

        setSyndicationGroup(syndicationGroupsResponse);
        setMemberDetails(response?.[1]?.data?.member);
        setApiData(ventureResponse?.data);
        setIsLoading(false);
        isMounted.current = true;
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(fetchVentures, fetchSyndicationGroups);

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });

      // update unmount ref
      isMounted.current = false;
      // clear setTimeout
      clearInterval(setTimeoutId.current);
    };
  }, [id, user.email]);

  function changeHandler(value) {
    setValue(value);
  }
  function stateHandler(state) {
    setStateVal(state);
  }

  const hideSuccessAlert = (time = 3000) => {
    const id = setTimeout(() => {
      setHasUpdated(false);
    }, time);
    setTimeoutId.current = id;
  };

  const orderErrors = (errors, schema) => {
    const orderedErrors = {};
    const schemaKeys = Object.keys(schema.fields);

    schemaKeys.forEach((key) => {
      if (errors[key]) {
        orderedErrors[key] = errors[key];
      }
    });

    return orderedErrors;
  };

  const focusFirstErrorField = (errors, setFieldTouched) => {
    const errorKeys = Object.keys(errors);

    // forcefully touching error fiedls to throw valiation on update click button
    for (let i = 0; i < errorKeys.length; i++) {
      setFieldTouched(errorKeys[i], true);
    }

    if (errorKeys.length > 0) {
      const firstErrorKey = errorKeys[0];
      const firstErrorElement = document.getElementById(firstErrorKey);
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  };

  const updateProfile = (values) => {
    const newVentureRound = values.isAddNewRound
      ? {
          isPresenter: false,
          securityType: values.newVentureRoundSecurityType,
          amount: convertAmountStringToNumber(values.newVentureRoundAmount),
          committed: values.newVentureRoundCommitted,
          valuation: convertAmountStringToNumber(
            values.newVentureRoundValuation
          ),
          other:
            values.newVentureRoundSecurityType === "Other" ||
            values.newVentureRoundSecurityType === "other"
              ? values.newVentureRoundReason
              : null,
        }
      : null;

    setIsLoading(true);
    const profilePromise = put(`/ventures/${id}`, {
      emailAddress: values?.emailAddress,
      amount: convertAmountStringToNumber(values?.amount),
      companyName: values?.companyName,
      companyWebsite: values?.companyWebsite,
      description: values?.description,
      country: values?.country,
      state: values?.state,
      executiveSummaryLink: values?.executiveSummaryLink,
      pitchVideoLink: values?.pitchVideoLink,
      slideDeckLink: values?.slideDeckLink,
      dealRoomLink: values?.dealRoomLink,
      gustProfileLink: values?.gustProfileLink,
      dealumProfileLink: values?.dealumProfileLink,
      qualification: values?.qualification,
      committed: values?.committed,
      valuation: convertAmountStringToNumber(values?.valuation),
      securityType: values?.securityType,
      other:
        values.securityType === "Other" || values.securityType === "other"
          ? values.reason
          : null,
      ceoName: values?.ceoName,
      mobileNumber: values?.mobileNumber,
      dealLeadEmail: values?.dealLeadEmail,
      dealLeadGroup: values?.dealLeadGroup,
      dealLeadName: values?.dealLeadName,
      dealStatus: values?.dealStatus,
      isRound: values?.isRound,
      currentVentureRound: {
        isRoundClosed: values?.isRound,
        securityType: values.securityType,
        amount: convertAmountStringToNumber(values.amount),
        committed: values.committed,
        valuation: convertAmountStringToNumber(values.valuation),
        other:
          values.securityType === "Other" || values.securityType === "other"
            ? values.reason
            : null,
      },
      newVentureRound: newVentureRound,
    });
    profilePromise.promise
      .then((response) => {
        setApiData(response?.data);
        const activeVenture = post("venture-active", {
          ventureId: id,
        });
        return activeVenture.promise;
      })
      .then(() => {
        setError(null);
        setIsLoading(false);
        setHasUpdated(true);
        hideSuccessAlert(5000);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    profiles.current.push(profilePromise);
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  };

  function validate(values) {
    const { securityType } = values;
    if (securityType !== "Other") {
      values.reason = "";
    }
    // const { executiveSummaryLink, pitchVideoLink, slideDeckLink } = values;
    // if (!executiveSummaryLink && !pitchVideoLink && !slideDeckLink) {
    //   return { required: "At least one field is required" };
    // }
  }

  const renderEditProfile = () => {
    if (isLoading && !isMounted.current) return <Loader />;
    if (error) return <ErrorHandler error={error} />;

    return (
      <Row className="justify-content-md-center my-5">
        <Col lg={2}>
          <ProfileInformation />
        </Col>

        <Col xl={10} lg={10} md={10} sm={10} xs={10}>
          <Row className="justify-content-md-center">
            <Col lg={10}>
              <Row>
                <Card>
                  <Card.Body>
                    <h2 className="text-center text-primary fs-3 my-2 ">
                      Application To ACA Syndication
                    </h2>
                    <div className="border-bottom border-dark w-75 m-auto"></div>

                    <p className="text-center  m-auto my-2">
                      A deal lead (an investor willing to coordinate due
                      diligence for syndication) is required to be included in
                      the packet. Your deal lead must be a member of the Angel
                      Capital Association, or a member of a member-group of the
                      Angel Capital Association.{" "}
                    </p>
                  </Card.Body>
                </Card>
              </Row>

              {/* formik starts here */}
              <Row>
                <Formik
                  enableReinitialize
                  validationSchema={EditProfileSchema}
                  validate={validate}
                  initialValues={{
                    isRound: Number(
                      apiData?.currentVentureRound?.isRoundClosed
                    ),
                    emailAddress: apiData?.emailAddress,
                    companyName: apiData?.companyName,
                    companyWebsite: apiData?.companyWebsite || "",
                    dealRoomLink: apiData?.dealRoomLink || "",
                    gustProfileLink: apiData?.gustProfileLink || "",
                    dealumProfileLink: apiData?.dealumProfileLink || "",
                    country: apiData?.country,
                    ceoName: apiData?.ceoName,
                    description: apiData?.description,
                    amount: String(apiData?.currentVentureRound?.amount),
                    mobileNumber: apiData?.mobileNumber,
                    qualification: apiData?.qualification,
                    dealStatus: apiData?.dealStatus,
                    securityType: apiData?.currentVentureRound?.securityType,
                    committed: apiData?.currentVentureRound?.committed,
                    dealLeadEmail: memberDetails?.[1] || apiData?.dealLeadEmail,
                    dealLeadName: memberDetails?.[0] || apiData?.dealLeadName,
                    dealLeadGroup: apiData?.dealLeadGroup,
                    valuation: String(apiData?.currentVentureRound?.valuation),
                    slideDeckLink: apiData?.slideDeckLink,
                    pitchVideoLink: apiData?.pitchVideoLink || "",
                    executiveSummaryLink: apiData?.executiveSummaryLink || "",
                    state: apiData?.state || "",
                    reason: apiData?.currentVentureRound?.other,

                    // new venture round
                    newVentureRoundAmount: "",
                    newVentureRoundValuation: "",
                    newVentureRoundCommitted: "",
                    newVentureRoundSecurityType: "",
                    newVentureRoundReason: "",

                    // new round boolean
                    isAddNewRound: false,
                  }}
                  onSubmit={(values, { resetForm }) => {
                    updateProfile(values);

                    setValue(null);
                    resetForm({ values: "" });
                    setApiData(null);
                  }}
                >
                  {({
                    errors,
                    touched,
                    isValid,
                    values,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    resetForm,
                    handleReset,
                    validateForm,
                    setFieldValue,
                    setFieldTouched,
                  }) => (
                    <Form className="p-3" onSubmit={handleSubmit}>
                      {/* company and key documents */}
                      <Row className="mb-4">
                        <Card>
                          <Card.Body>
                            {/* company information statrs here */}
                            <Row className={"mb-5"}>
                              <Col xs={12}>
                                <h4 className="text-primary">
                                  Company Information
                                </h4>
                              </Col>
                              <Form.Group
                                as={Col}
                                sm={12}
                                lg={6}
                                className="my-2"
                              >
                                <FormLabel className="text-secondary">
                                  Company Name
                                  <span className="text-danger mx-1">*</span>
                                </FormLabel>

                                <Field
                                  as={FormControl}
                                  name="companyName"
                                  className="form-control"
                                  type="text"
                                  isInvalid={
                                    errors.companyName && touched.companyName
                                  }
                                  isValid={
                                    values.companyName && !errors.companyName
                                  }
                                  value={values?.companyName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  id={refs.companyName}
                                />
                                {errors.companyName && touched.companyName ? (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.companyName}
                                  </Form.Control.Feedback>
                                ) : null}
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                lg={6}
                                sm={12}
                                className="my-2"
                              >
                                <FormLabel className="text-secondary">
                                  Website
                                </FormLabel>
                                <Field
                                  name="companyWebsite"
                                  as={FormControl}
                                  isInvalid={
                                    errors.companyWebsite ||
                                    (values?.companyWebsite &&
                                      !values?.companyWebsite?.startsWith(
                                        "http"
                                      ))
                                    // &&
                                    // touched.companyWebsite
                                  }
                                  isValid={
                                    values.companyWebsite &&
                                    !errors.companyWebsite &&
                                    values?.companyWebsite?.startsWith("http")
                                  }
                                  value={values?.companyWebsite}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  id={refs.companyWebsite}
                                />
                                {errors.companyWebsite ? (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.companyWebsite}
                                  </Form.Control.Feedback>
                                ) : values?.companyWebsite &&
                                  !values?.companyWebsite?.startsWith(
                                    "http"
                                  ) ? (
                                  <Form.Text
                                    type="invalid"
                                    className="text-danger"
                                  >
                                    Please Include http:// or https://
                                  </Form.Text>
                                ) : (
                                  ""
                                )}
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                sm={12}
                                lg={12}
                                className="my-2"
                              >
                                <FormLabel className=" text-secondary">
                                  Country
                                  <span className="text-danger mx-1">*</span>
                                </FormLabel>
                                <Form.Select
                                  as={Form.Control}
                                  value={values?.country}
                                  name="country"
                                  isInvalid={errors.country && touched.country}
                                  isValid={
                                    values.country !== "" && !errors.country
                                  }
                                  onChange={(event) => {
                                    changeHandler(event.target.value);
                                    setFieldValue(
                                      "country",
                                      event.target.value
                                    );
                                  }}
                                >
                                  {country.map((el, key) => {
                                    return <option>{el?.label}</option>;
                                  })}
                                  {errors.country && touched.country && (
                                    <Form.Control.Feedback type="invalid">
                                      {errors.country}
                                    </Form.Control.Feedback>
                                  )}
                                </Form.Select>
                              </Form.Group>
                              {values.country === "United States" && (
                                <Form.Group as={Col} sm={12} className="my-2">
                                  <FormLabel className="text-secondary">
                                    Enter State
                                  </FormLabel>
                                  <Form.Select
                                    as={Form.Control}
                                    value={values?.state}
                                    name="state"
                                    isInvalid={errors.state && touched.state}
                                    isValid={
                                      values.state !== "" &&
                                      !errors.state &&
                                      values?.state !== null
                                    }
                                    onChange={(event) => {
                                      stateHandler(event.target.value);
                                      setFieldValue(
                                        "state",
                                        event.target.value
                                      );
                                    }}
                                  >
                                    <option value="" disabled={values?.state}>
                                      Select State
                                    </option>
                                    {options.map((el, key) => {
                                      return <option>{el?.name}</option>;
                                    })}
                                    {errors.state && touched.state && (
                                      <Form.Control.Feedback type="invalid">
                                        {errors.state}
                                      </Form.Control.Feedback>
                                    )}
                                  </Form.Select>
                                </Form.Group>
                              )}

                              <Form.Group
                                as={Col}
                                lg={12}
                                sm={12}
                                className="my-2"
                              >
                                <FormLabel className="text-secondary">
                                  Description{" "}
                                  <span className="text-danger mx-1">*</span>
                                </FormLabel>
                                <p>
                                  Give us the 1-line (maximum of 140 characters)
                                  pitch for your venture.
                                </p>
                                <FormControl
                                  as={"textarea"}
                                  style={{ resize: "none" }}
                                  name="description"
                                  placeholder="Give us the 1-line (maximum of 140 characters)"
                                  isInvalid={errors.description}
                                  isValid={
                                    values.description && !errors.description
                                  }
                                  value={values.description}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  id={refs.description}
                                />
                                {errors.description ? (
                                  <Form.Text
                                    type="invalid"
                                    className="text-danger"
                                  >
                                    {errors.description}
                                  </Form.Text>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                            </Row>
                            {/* company information ends here */}

                            {/* key documents starts here */}
                            <Row>
                              <Col xs={12}>
                                <h4 className="text-primary">Key Documents</h4>
                              </Col>
                              <Form.Text>
                                Kindly ensure that the investor are able to
                                access the link.
                                <span className="text-danger mx-1">*</span>
                              </Form.Text>

                              <Form.Group
                                as={Col}
                                lg={4}
                                sm={12}
                                className="my-2"
                              >
                                <FormLabel className="text-secondary">
                                  Executive Summary Link
                                </FormLabel>
                                <Field
                                  name="executiveSummaryLink"
                                  as={FormControl}
                                  isInvalid={
                                    errors.executiveSummaryLink &&
                                    touched.executiveSummaryLink
                                  }
                                  isValid={
                                    values.executiveSummaryLink &&
                                    !errors.executiveSummaryLink
                                  }
                                  value={values?.executiveSummaryLink}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  id={refs.executiveSummaryLink}
                                />
                                {errors.executiveSummaryLink &&
                                  touched.executiveSummaryLink && (
                                    <Form.Control.Feedback type="invalid">
                                      {errors.executiveSummaryLink}
                                    </Form.Control.Feedback>
                                  )}
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                lg={4}
                                sm={12}
                                className="my-2"
                              >
                                <FormLabel className=" text-secondary">
                                  Pitch Deck Link
                                  <span className="text-danger mx-1">*</span>
                                </FormLabel>
                                <Field
                                  as={FormControl}
                                  name="slideDeckLink"
                                  isInvalid={
                                    errors.slideDeckLink ||
                                    !values?.slideDeckLink?.startsWith("http")
                                    // &&
                                    // touched.slideDeckLink
                                  }
                                  isValid={
                                    values.slideDeckLink &&
                                    !errors.slideDeckLink &&
                                    values?.slideDeckLink?.startsWith("http")
                                  }
                                  value={values?.slideDeckLink}
                                  id={refs.slideDeckLink}
                                />
                                {errors.slideDeckLink ? (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.slideDeckLink}
                                  </Form.Control.Feedback>
                                ) : !values?.slideDeckLink?.startsWith(
                                    "http"
                                  ) ? (
                                  <Form.Text
                                    type="invalid"
                                    className="text-danger"
                                  >
                                    Please Include http:// or https://
                                  </Form.Text>
                                ) : (
                                  ""
                                )}
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                sm={12}
                                lg={4}
                                className="my-2"
                              >
                                <FormLabel className="text-secondary">
                                  Pitch Video Link
                                </FormLabel>
                                <Field
                                  name="pitchVideoLink"
                                  as={FormControl}
                                  value={values?.pitchVideoLink}
                                  isInvalid={
                                    touched.pitchVideoLink &&
                                    errors.pitchVideoLink
                                  }
                                  isValid={
                                    values.pitchVideoLink &&
                                    !errors.pitchVideoLink
                                  }
                                  id={refs.pitchVideoLink}
                                />
                                {touched.pitchVideoLink &&
                                  errors.pitchVideoLink && (
                                    <Form.Control.Feedback type="invalid">
                                      {errors.pitchVideoLink}
                                    </Form.Control.Feedback>
                                  )}
                              </Form.Group>
                              {errors.required &&
                                touched.executiveSummaryLink &&
                                touched.pitchVideoLink &&
                                touched.slideDeckLink && (
                                  <small className="text-danger">
                                    {errors.required}
                                  </small>
                                )}
                            </Row>
                            {/* key documents ends here */}
                          </Card.Body>
                        </Card>
                      </Row>

                      {/* deal information */}
                      <Row className="my-4">
                        <Card>
                          <Card.Body>
                            {/* deal information stars here */}
                            <Row>
                              <Col xs={12}>
                                <h4 className="text-primary ">
                                  Deal Information
                                </h4>
                              </Col>

                              <Form.Group
                                as={Col}
                                lg={12}
                                sm={12}
                                className="my-2"
                              >
                                <FormLabel className="text-secondary">
                                  Deal Room Link
                                </FormLabel>
                                <Field
                                  as={FormControl}
                                  name="dealRoomLink"
                                  isInvalid={
                                    errors.dealRoomLink && touched.dealRoomLink
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.dealRoomLink}
                                  isValid={
                                    values.dealRoomLink && !errors.dealRoomLink
                                  }
                                  id={refs.dealRoomLink}
                                />

                                {errors.dealRoomLink && touched.dealRoomLink ? (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.dealRoomLink}
                                  </Form.Control.Feedback>
                                ) : (
                                  ""
                                )}
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                lg={12}
                                sm={12}
                                className="my-2"
                              >
                                <FormLabel className="text-secondary">
                                  Gust Profile Link
                                </FormLabel>
                                <Field
                                  as={FormControl}
                                  name="gustProfileLink"
                                  isInvalid={
                                    errors.gustProfileLink &&
                                    touched.gustProfileLink
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.gustProfileLink}
                                  isValid={
                                    values.gustProfileLink &&
                                    !errors.gustProfileLink
                                  }
                                  id={refs.gustProfileLink}
                                />

                                {errors.gustProfileLink &&
                                touched.gustProfileLink ? (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.gustProfileLink}
                                  </Form.Control.Feedback>
                                ) : (
                                  ""
                                )}
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                lg={12}
                                sm={12}
                                className="my-2"
                              >
                                <FormLabel className="text-secondary">
                                  Dealum Profile Link
                                </FormLabel>
                                <Field
                                  as={FormControl}
                                  name="dealumProfileLink"
                                  isInvalid={
                                    errors.dealumProfileLink &&
                                    touched.dealumProfileLink
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.dealumProfileLink}
                                  isValid={
                                    values.dealumProfileLink &&
                                    !errors.dealumProfileLink
                                  }
                                  id={refs.dealumProfileLink}
                                />

                                {errors.dealumProfileLink &&
                                touched.dealumProfileLink ? (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.dealumProfileLink}
                                  </Form.Control.Feedback>
                                ) : (
                                  ""
                                )}
                              </Form.Group>

                              <Form.Group as={Col} lg={12} className="my-2">
                                <FormLabel className="text-secondary">
                                  Special Interest Qualification
                                </FormLabel>
                                <div
                                  role="group"
                                  aria-labelledby="checkbox-group"
                                >
                                  <Row>
                                    {specialInterestQualifications.map(
                                      (qualifiation, key) => (
                                        <Form.Group as={Col} lg={6} sm={12}>
                                          <Form.Check
                                            type={"checkbox"}
                                            id={qualifiation.id}
                                            value={qualifiation.value}
                                            label={qualifiation.label}
                                            name="qualification"
                                            checked={values?.qualification?.includes(
                                              qualifiation.value
                                            )}
                                            isValid={
                                              !errors.qualification &&
                                              touched.qualification
                                            }
                                            onChange={handleChange}
                                          ></Form.Check>
                                          <Form.Control.Feedback type="invalid">
                                            {errors.qualification}
                                          </Form.Control.Feedback>
                                        </Form.Group>
                                      )
                                    )}
                                  </Row>
                                </div>
                              </Form.Group>

                              <Form.Group as={Col} lg={12} className="my-2">
                                <FormLabel className="text-secondary">
                                  Syndication Group{" "}
                                  <span className="text-danger mx-1">*</span>
                                </FormLabel>
                                <div
                                  role="group"
                                  aria-labelledby="checkbox-group"
                                >
                                  <Row>
                                    {syndicationGroup?.map((val, key) => (
                                      <Form.Group
                                        as={Col}
                                        lg={6}
                                        sm={12}
                                        key={key}
                                      >
                                        <Form.Check
                                          type={"checkbox"}
                                          name="syndicationGroups"
                                          key={key}
                                          label={val}
                                          value={val}
                                          checked={true}
                                          disabled
                                        ></Form.Check>
                                      </Form.Group>
                                    ))}
                                  </Row>
                                </div>
                              </Form.Group>
                            </Row>
                            {/* deal infomation ends here */}
                          </Card.Body>
                        </Card>
                      </Row>

                      {/* ceo and nominating information */}
                      <Row className="my-4">
                        <Card>
                          <Card.Body>
                            {/* ceo information starts here */}
                            <Row>
                              <Col xs={12}>
                                <h4 className="text-primary">
                                  CEO Contact Information
                                </h4>
                              </Col>

                              <Form.Group
                                as={Col}
                                sm={12}
                                lg={6}
                                className="my-2"
                              >
                                <FormLabel className="text-secondary">
                                  CEO Name
                                  <span className="text-danger mx-1">*</span>
                                </FormLabel>
                                <Field
                                  name="ceoName"
                                  type="text"
                                  as={FormControl}
                                  isInvalid={errors.ceoName && touched.ceoName}
                                  isValid={values.ceoName && !errors.ceoName}
                                  value={values?.ceoName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  id={refs.ceoName}
                                />
                                {errors.ceoName && touched.ceoName ? (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.ceoName}
                                  </Form.Control.Feedback>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                lg={6}
                                sm={12}
                                className="my-2"
                              >
                                <FormLabel className="text-secondary">
                                  CEO Mobile Number
                                  <span className="text-danger mx-1">*</span>
                                </FormLabel>
                                <PhoneInput
                                  as={FormControl}
                                  country={"us"}
                                  inputStyle={{ height: "38px", width: "100%" }}
                                  value={values?.mobileNumber}
                                  name="mobileNumber"
                                  onChange={(ceoPhone) => {
                                    phoneHandler(ceoPhone);
                                    setFieldValue("mobileNumber", ceoPhone);
                                  }}
                                  onBlur={handleBlur}
                                  inputProps={{
                                    id: refs.mobileNumber,
                                  }}
                                />

                                {errors.mobileNumber && touched.mobileNumber ? (
                                  <Form.Text
                                    type="invalid"
                                    className="text-danger"
                                  >
                                    {errors.mobileNumber}
                                  </Form.Text>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                            </Row>
                            {/* ceo information ends here */}

                            {/* nominating infor starts here */}
                            <Row className="mt-5">
                              <Col xs={12}>
                                <h4 className="text-primary ">
                                  Nominating Investor Information
                                </h4>
                              </Col>
                              <Form.Group
                                as={Col}
                                lg={6}
                                sm={12}
                                className="my-2"
                              >
                                <FormLabel className="text-secondary">
                                  Nominating Investor's Angel Group/Fund
                                  <span className="text-danger mx-1">*</span>
                                </FormLabel>
                                <Field
                                  name="dealLeadGroup"
                                  type="text"
                                  as={FormControl}
                                  isInvalid={
                                    errors.dealLeadGroup &&
                                    touched.dealLeadGroup
                                  }
                                  isValid={
                                    values.dealLeadGroup &&
                                    !errors.dealLeadGroup
                                  }
                                  value={values?.dealLeadGroup}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  id={refs.dealLeadGroup}
                                />

                                {errors.dealLeadGroup &&
                                touched.dealLeadGroup ? (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.dealLeadGroup}
                                  </Form.Control.Feedback>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                lg={6}
                                sm={12}
                                className="my-2"
                              >
                                <FormLabel className="text-secondary">
                                  Nominating Investor's Name
                                  <span className="text-danger mx-1">*</span>
                                </FormLabel>
                                <Field
                                  name="dealLeadName"
                                  type="text"
                                  as={FormControl}
                                  isInvalid={
                                    errors.dealLeadName && touched.dealLeadName
                                  }
                                  value={values?.dealLeadName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isValid={
                                    values.dealLeadName && !errors.dealLeadName
                                  }
                                  disabled={values?.dealLeadName}
                                />

                                {errors.dealLeadName && touched.dealLeadName ? (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.dealLeadName}
                                  </Form.Control.Feedback>
                                ) : (
                                  ""
                                )}
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                sm={12}
                                lg={12}
                                className="my-2"
                              >
                                <FormLabel className="text-secondary">
                                  Nominating Investor's Email
                                  <span className="text-danger mx-1">*</span>
                                </FormLabel>
                                <Field
                                  as={FormControl}
                                  name="dealLeadEmail"
                                  type="email"
                                  isInvalid={
                                    errors.dealLeadEmail &&
                                    touched.dealLeadEmail
                                  }
                                  isValid={
                                    values.dealLeadEmail &&
                                    !errors.dealLeadEmail
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.dealLeadEmail}
                                  disabled={values?.dealLeadEmail}
                                />
                                {errors.dealLeadEmail &&
                                touched.dealLeadEmail ? (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.dealLeadEmail}
                                  </Form.Control.Feedback>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                            </Row>
                            {/* nominating info ends here */}
                          </Card.Body>
                        </Card>
                      </Row>

                      {/* rounds information */}
                      <Row className="my-4">
                        <Card>
                          <Card.Body>
                            {/* current venture round information starts here */}
                            <Row
                              className="py-2 rounded"
                              style={{
                                background: values.isRound ? "#F5F5F5" : "",
                              }}
                            >
                              <Col xs={12}>
                                <div className="d-flex justify-content-between align-items-center">
                                  <h4 className="text-primary">
                                    Current Round Information
                                  </h4>
                                  <RoundHistory
                                    roundHistory={apiData?.ventureRounds}
                                  />
                                </div>
                              </Col>
                              <Form.Group
                                as={Col}
                                lg={6}
                                sm={12}
                                className="my-2"
                              >
                                <FormLabel className=" text-secondary">
                                  Raise Amount
                                  <span className="text-danger mx-1">*</span>
                                </FormLabel>
                                <p className="text-dark">
                                  The total amount you are raising in this
                                  round.
                                </p>

                                <NumericFormat
                                  disabled={values.isRound}
                                  allowNegative={false}
                                  customInput={Form.Control}
                                  thousandSeparator={true}
                                  decimalScale={0}
                                  prefix={"$"}
                                  onChange={handleChange}
                                  name="amount"
                                  placeholder="Enter Amount"
                                  onBlur={handleBlur}
                                  isInvalid={errors.amount && touched.amount}
                                  isValid={values.amount && !errors.amount}
                                  value={values.amount}
                                  id={refs.amount}
                                />
                                {errors.amount && touched.amount ? (
                                  <Form.Control.Feedback type="invalid">
                                    {errors.amount}
                                  </Form.Control.Feedback>
                                ) : (
                                  ""
                                )}
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                lg={6}
                                sm={12}
                                className="my-2"
                              >
                                <FormLabel className="text-secondary">
                                  Valuation/Cap
                                  <span className="text-danger mx-1">*</span>
                                </FormLabel>
                                <p className="text-dark">
                                  PRE-Money valuation or PRE-Money cap
                                </p>
                                <FormGroup>
                                  <NumericFormat
                                    disabled={values.isRound}
                                    allowNegative={false}
                                    customInput={Form.Control}
                                    decimalScale={0}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    onChange={handleChange}
                                    name="valuation"
                                    placeholder="Enter Valuation/Cap"
                                    onBlur={handleBlur}
                                    isInvalid={
                                      errors.valuation && touched.valuation
                                    }
                                    isValid={
                                      values.valuation && !errors.valuation
                                    }
                                    value={values.valuation}
                                    id={refs.valuation}
                                  />
                                  {errors.valuation && touched.valuation ? (
                                    <Form.Control.Feedback type="invalid">
                                      {errors.valuation}
                                    </Form.Control.Feedback>
                                  ) : (
                                    ""
                                  )}
                                </FormGroup>
                              </Form.Group>
                              <FormLabel className="text-danger">
                                Please write out the full amount. Example: 1
                                million should be entered as 1000000.
                                {/* <span className="text-danger mx-1">*</span> */}
                              </FormLabel>
                              <Form.Group
                                as={Col}
                                lg={12}
                                sm={12}
                                className="mb-3 mt-2"
                              >
                                <FormLabel className="text-secondary">
                                  %Committed
                                  <span className="text-danger mx-1">*</span>
                                </FormLabel>
                                <Row>
                                  {committedPercentage.map(
                                    (percentage, key) => (
                                      <Form.Group as={Col} sm={6} key={key}>
                                        <Form.Check
                                          disabled={values.isRound}
                                          inline
                                          type="radio"
                                          name="committed"
                                          id={percentage.id}
                                          checked={
                                            values?.committed ===
                                            percentage.value
                                          }
                                          value={percentage.value}
                                          label={percentage.label}
                                          onChange={(e) => {
                                            e.currentTarget.blur();
                                            handleChange(e);
                                            setFieldValue(
                                              "committed",
                                              percentage.value
                                            );
                                          }}
                                          onBlur={handleBlur}
                                        />
                                      </Form.Group>
                                    )
                                  )}
                                </Row>
                                {errors.committed && touched.committed ? (
                                  <Form.Text
                                    type="invalid"
                                    className="text-danger"
                                  >
                                    {errors.committed}
                                  </Form.Text>
                                ) : (
                                  ""
                                )}
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                sm={12}
                                lg={12}
                                className="mb-3"
                              >
                                <FormLabel className="text-secondary">
                                  Security Type
                                  <span className="text-danger mx-1">*</span>
                                </FormLabel>
                                <Row>
                                  {securityTypes.map((type, key) => (
                                    <Form.Group as={Col} sm={6} key={key}>
                                      <Form.Check
                                        disabled={values.isRound}
                                        inline
                                        type="radio"
                                        name="securityType"
                                        id={type.id}
                                        value={type.value}
                                        label={type.label}
                                        checked={
                                          values.securityType === type.value
                                        }
                                        onChange={(e) => {
                                          e.currentTarget.blur();
                                          handleChange(e);
                                          setFieldValue(
                                            "securityType",
                                            type.value
                                          );
                                          setFieldValue(
                                            "securityType",
                                            e.target.value
                                          );
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Form.Group>
                                  ))}
                                  {errors.securityType &&
                                  touched.securityType ? (
                                    <Form.Text
                                      type="invalid"
                                      className="text-danger"
                                    >
                                      {errors.securityType}
                                    </Form.Text>
                                  ) : (
                                    ""
                                  )}
                                  {values?.securityType === "Other" && (
                                    <Form.Group
                                      as={Col}
                                      sm={12}
                                      className="mb-3"
                                    >
                                      <FormLabel className="mt-2 text-secondary">
                                        Please specify:{" "}
                                        <span className="text-danger mx-1">
                                          *
                                        </span>
                                      </FormLabel>
                                      <Field
                                        disabled={values.isRound}
                                        type="text"
                                        name="reason"
                                        as={Form.Control}
                                        isValid={
                                          !errors.reason &&
                                          touched.reason &&
                                          values.reason
                                        }
                                        isInvalid={errors.reason}
                                        value={
                                          values?.securityType === "other"
                                            ? "Other"
                                            : values?.reason
                                        }
                                        onChange={(e) => {
                                          handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        id={refs.reason}
                                      />

                                      {errors.reason && (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.reason}
                                        </Form.Control.Feedback>
                                      )}
                                    </Form.Group>
                                  )}
                                  <hr className="mt-4" />
                                  <Form.Group as={Col} sm={12} lg={6}>
                                    <Form.Check
                                      disabled={
                                        !apiData?.currentVentureRound
                                          ?.isEditable === true
                                      }
                                      type="checkbox"
                                      id="round-is-closed"
                                      value={values.isRound}
                                      label="Round is closed"
                                      name="isRound"
                                      checked={values.isRound}
                                      onClick={(e) => {
                                        if (e.target.checked === false) {
                                          setFieldValue("isAddNewRound", false);
                                        }
                                      }}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "isRound",
                                          e.target.checked
                                        );
                                      }}
                                    />
                                  </Form.Group>
                                </Row>
                              </Form.Group>
                              {values.isRound &&
                              apiData?.currentVentureRound?.isEditable ===
                                true ? (
                                <p className="text-danger">
                                  * Deselect to reopen the current round.
                                  <br />
                                  * Once a new round is created, the previous
                                  round cannot be reopened.
                                  <br />* A round cannot be reopened after 90
                                  days have passed since its creation.
                                </p>
                              ) : null}
                              <hr />

                              {apiData?.currentVentureRound?.isEditable ===
                                false && (
                                <p className="text-danger">
                                  * The system has concluded the round as 90
                                  days have elapsed since its creation.
                                  <br />* Hit the "Add New Round" button to
                                  update the venture.
                                </p>
                              )}
                            </Row>
                            {/* current venture round information ends here */}

                            {/* new venture round information starts here */}

                            {values.isRound ? (
                              <Button
                                className="my-4"
                                onClick={() =>
                                  setFieldValue("isAddNewRound", true)
                                }
                              >
                                Add New Round
                              </Button>
                            ) : null}
                            {values.isAddNewRound ? (
                              <Row className="my-2 py-2">
                                <Col xs={12}>
                                  <h4 className="text-primary">
                                    New Round Information
                                  </h4>
                                  <FormLabel className="text-danger">
                                    Please write out the full amount. Example: 1
                                    million should be entered as 1000000.
                                    {/* <span className="text-danger mx-1">*</span> */}
                                  </FormLabel>
                                </Col>
                                <Form.Group
                                  as={Col}
                                  lg={6}
                                  sm={12}
                                  className="my-2"
                                >
                                  <FormLabel className=" text-secondary">
                                    Raise Amount
                                    <span className="text-danger mx-1">*</span>
                                  </FormLabel>
                                  <p className="text-dark">
                                    The total amount you are raising in this
                                    round.
                                  </p>

                                  <NumericFormat
                                    allowNegative={false}
                                    customInput={Form.Control}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                    prefix={"$"}
                                    onChange={handleChange}
                                    name="newVentureRoundAmount"
                                    placeholder="Enter Amount"
                                    onBlur={handleBlur}
                                    isInvalid={
                                      errors.newVentureRoundAmount &&
                                      touched.newVentureRoundAmount
                                    }
                                    isValid={
                                      values.newVentureRoundAmount &&
                                      !errors.newVentureRoundAmount
                                    }
                                    value={values.newVentureRoundAmount}
                                    id="newVentureRoundAmount"
                                  />

                                  {errors?.newVentureRoundAmount &&
                                  touched?.newVentureRoundAmount ? (
                                    <Form.Text
                                      type="invalid"
                                      className="text-danger"
                                    >
                                      {errors?.newVentureRoundAmount}
                                    </Form.Text>
                                  ) : (
                                    ""
                                  )}
                                </Form.Group>

                                <Form.Group
                                  as={Col}
                                  lg={6}
                                  sm={12}
                                  className="my-2"
                                >
                                  <FormLabel className="text-secondary">
                                    Valuation/Cap
                                    <span className="text-danger mx-1">*</span>
                                  </FormLabel>
                                  <p className="text-dark">
                                    PRE-Money valuation or PRE-Money cap
                                  </p>
                                  <FormGroup>
                                    <NumericFormat
                                      allowNegative={false}
                                      customInput={Form.Control}
                                      decimalScale={0}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                      onChange={handleChange}
                                      name="newVentureRoundValuation"
                                      placeholder="Enter Valuation/Cap"
                                      onBlur={handleBlur}
                                      isInvalid={
                                        errors.newVentureRoundValuation &&
                                        touched.newVentureRoundValuation
                                      }
                                      isValid={
                                        values.newVentureRoundValuation &&
                                        !errors.newVentureRoundValuation
                                      }
                                      value={values.newVentureRoundValuation}
                                      id="newVentureRoundValuation"
                                    />
                                    {errors.newVentureRoundValuation &&
                                    touched.newVentureRoundValuation ? (
                                      <Form.Control.Feedback type="invalid">
                                        {errors.newVentureRoundValuation}
                                      </Form.Control.Feedback>
                                    ) : (
                                      ""
                                    )}
                                  </FormGroup>
                                </Form.Group>
                                <Form.Group
                                  as={Col}
                                  lg={12}
                                  sm={12}
                                  className="mb-3"
                                >
                                  <FormLabel className="text-secondary">
                                    %Committed
                                    <span className="text-danger mx-1">*</span>
                                  </FormLabel>
                                  <Row>
                                    {committedPercentage.map(
                                      (percentage, key) => (
                                        <Form.Group as={Col} sm={6} key={key}>
                                          <Form.Check
                                            inline
                                            type="radio"
                                            name="newVentureRoundCommitted"
                                            id={percentage.id + key}
                                            checked={
                                              values?.newVentureRoundCommitted ===
                                              percentage.value
                                            }
                                            value={percentage.value}
                                            label={percentage.label}
                                            onChange={(e) => {
                                              e.currentTarget.blur();
                                              handleChange(e);
                                              setFieldValue(
                                                "newVentureRoundCommitted",
                                                percentage.value
                                              );
                                            }}
                                            onBlur={handleBlur}
                                          />
                                        </Form.Group>
                                      )
                                    )}
                                  </Row>
                                  {errors.newVentureRoundCommitted &&
                                  touched.newVentureRoundCommitted ? (
                                    <Form.Text
                                      type="invalid"
                                      className="text-danger"
                                    >
                                      {errors.newVentureRoundCommitted}
                                    </Form.Text>
                                  ) : (
                                    ""
                                  )}
                                </Form.Group>

                                <Form.Group
                                  as={Col}
                                  sm={12}
                                  lg={12}
                                  className="mb-3"
                                >
                                  <FormLabel className="text-secondary">
                                    Security Type
                                    <span className="text-danger mx-1">*</span>
                                  </FormLabel>
                                  <Row>
                                    {securityTypes.map((type, key) => (
                                      <Form.Group as={Col} sm={6} key={key}>
                                        <Form.Check
                                          inline
                                          type="radio"
                                          name="newVentureRoundSecurityType"
                                          id={type.id + key}
                                          value={type.value}
                                          label={type.label}
                                          checked={
                                            values.newVentureRoundSecurityType ===
                                            type.value
                                          }
                                          onChange={(e) => {
                                            e.currentTarget.blur();
                                            handleChange(e);
                                            setFieldValue(
                                              "newVentureRoundSecurityType",
                                              type.value
                                            );
                                          }}
                                          onBlur={handleBlur}
                                        />
                                      </Form.Group>
                                    ))}
                                    {errors.newVentureRoundSecurityType &&
                                    touched.newVentureRoundSecurityType ? (
                                      <Form.Text
                                        type="invalid"
                                        className="text-danger"
                                      >
                                        {errors.newVentureRoundSecurityType}
                                      </Form.Text>
                                    ) : (
                                      ""
                                    )}
                                    {values.newVentureRoundSecurityType ===
                                      "Other" && (
                                      <Form.Group
                                        as={Col}
                                        sm={12}
                                        className="mb-3"
                                      >
                                        <FormLabel className="mt-2 text-secondary">
                                          Please specify:{" "}
                                          <span className="text-danger mx-1">
                                            *
                                          </span>
                                        </FormLabel>
                                        <Field
                                          type="text"
                                          name="newVentureRoundReason"
                                          as={Form.Control}
                                          isValid={
                                            !errors.newVentureRoundReason &&
                                            touched.newVentureRoundReason &&
                                            values.newVentureRoundReason
                                          }
                                          isInvalid={
                                            errors.newVentureRoundReason
                                          }
                                          value={
                                            values?.newVentureRoundReason ===
                                            "other"
                                              ? "Other"
                                              : values?.newVentureRoundReason
                                          }
                                          onChange={(e) => {
                                            handleChange(e);
                                          }}
                                          onBlur={handleBlur}
                                          id={refs.reason}
                                        />

                                        {errors.newVentureRoundReason && (
                                          <Form.Control.Feedback type="invalid">
                                            {errors.newVentureRoundReason}
                                          </Form.Control.Feedback>
                                        )}
                                      </Form.Group>
                                    )}
                                  </Row>
                                </Form.Group>
                                <hr />
                              </Row>
                            ) : null}

                            <div className="d-grid gap-3">
                              {hasUpdated && (
                                <Alert variant="success mx-auto ">
                                  <Alert.Heading>Success</Alert.Heading>
                                  Profile updated successfully!
                                </Alert>
                              )}

                              {(apiData?.currentVentureRound?.isEditable ||
                                (!apiData?.currentVentureRound?.isEditable &&
                                  values?.isAddNewRound)) && (
                                <Button
                                  disabled={isLoading}
                                  type="submit"
                                  className="btn btn-primary text-white w-50 m-auto"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    validateForm().then((errors) => {
                                      const updatedErrors = { ...errors };
                                      if (
                                        values.securityType !== "Other" ||
                                        values.newVentureRoundSecurityType !==
                                          "Other"
                                      ) {
                                        delete updatedErrors.reason;
                                        delete updatedErrors.newVentureRoundReason;
                                      }
                                      if (
                                        !!Object.keys(updatedErrors)?.length &&
                                        Object.values(updatedErrors).every(
                                          (value) => value
                                        )
                                      ) {
                                        focusFirstErrorField(
                                          orderErrors(
                                            updatedErrors,
                                            EditProfileSchema
                                          ),
                                          setFieldTouched
                                        );
                                      } else {
                                        if (
                                          values?.country !== "United States"
                                        ) {
                                          values.state = "";
                                        }
                                        updateProfile(values);
                                      }
                                    });
                                  }}
                                >
                                  Update
                                </Button>
                              )}
                            </div>
                            {/* new venture round information ends here */}
                          </Card.Body>
                        </Card>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Header />
      <Container className="mb-3 MainContent">{renderEditProfile()}</Container>
      <Footer />
    </>
  );
};
