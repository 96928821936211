import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  companyName: Yup.string()
    .min(2, "Too short!")
    .max(50, "Too Long!")
    .required("This is a required field"),
  companyWebsite: Yup.string().url("Please Include http:// or https://"),
  country: Yup.string()
    .min(2, "Too short")
    .max(50, "Too Long")
    .required("This is a required field"),
  state: Yup.string().when("country", {
    is: "United States",
    then: (schema) =>
      schema
        .min(2, "State name is too short")
        .max(50, "State name is too long"),
    otherwise: (schema) => schema.nullable(),
  }),
  description: Yup.string()
    .min(10, "Enter at least 10 characters")
    .max(140, "You can enter only 140 characters")
    .required("This is a required field"),
  executiveSummaryLink: Yup.string().url("Please Include http:// or https://"),
  slideDeckLink: Yup.string()
    .url("Please Include http:// or https://")
    .required("This is a required field"),
  pitchVideoLink: Yup.string().url("Please Include http:// or https://"),

  dealRoomLink: Yup.string().url("Please Include http:// or https://"),
  gustProfileLink: Yup.string().url("Please Include http:// or https://"),
  dealumProfileLink: Yup.string().url("Please Include http:// or https://"),
  qualification: Yup.array(),

  ceoName: Yup.string()
    .min(2, "Too short")
    .max(50, "To Long")
    .required("This is a required field"),
  mobileNumber: Yup.string().min(8).required("This is a required field"),
  dealLeadGroup: Yup.string()
    .min(2, "Too short")
    .max(50, "Too Long")
    .required("This is a required field"),
  dealLeadEmail: Yup.string()
    .email("Invalid email")
    .required("This is a required field"),
  dealLeadName: Yup.string()
    .min(2, "Too short")
    .max(50, "Too Long")
    .required("This is a required field"),

  amount: Yup.string().required("This is a required field"),
  valuation: Yup.string().required("This is a required field"),
  committed: Yup.string().required("This is a required field"),
  securityType: Yup.string().required("This is a required field"),
  reason: Yup.string().when("securityType", {
    is: (value) => value === "Other",
    then: (schema) =>
      schema
        .min(10, "Enter at least 10 characters")
        .max(140, "you can enter only 140 characters")
        .required("This is a required field"),
    otherwise: (schema) => schema.nullable(),
  }),
  isRound: Yup.boolean(),

  newVentureRoundAmount: Yup.string().when("isAddNewRound", {
    is: (value) => value === true,
    then: (schema) => schema.required("This is a required field"),
    otherwise: (schema) => schema,
  }),
  newVentureRoundValuation: Yup.string().when("isAddNewRound", {
    is: (value) => value,
    then: (schema) => schema.required("This is a required field"),
    otherwise: (schema) => schema,
  }),
  newVentureRoundCommitted: Yup.string().when("isAddNewRound", {
    is: (value) => value,
    then: (schema) => schema.required("This is a required field"),
    otherwise: (schema) => schema,
  }),
  newVentureRoundSecurityType: Yup.string().when("isAddNewRound", {
    is: (value) => value,
    then: (schema) => schema.required("This is a required field"),
    otherwise: (schema) => schema,
  }),
  newVentureRoundReason: Yup.string().when("newVentureRoundSecurityType", {
    is: (value) => value === "Other",
    then: (schema) =>
      schema
        .min(10, "Enter at least 10 characters")
        .max(140, "you can enter only 140 characters")
        .required("This is a required field"),
    otherwise: (schema) => schema.nullable(),
  }),

  // new round boolean
  isAddNewRound: Yup.boolean(),
});
