import React, { useState, useContext, useEffect } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { post } from "utils/DeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { loginVal } from "utils/googleauth";
import { UserDispatchContext } from "contexts/UserProvider";
import { Button, Form, InputGroup, Spinner } from "react-bootstrap";
import { linkedInOAuthLogin } from "utils/LinkedInAuth";
import { Link, useNavigate } from "react-router-dom";
import { login, get } from "utils/BeeApi";
import { login as loginDE } from "utils/DeApi";
import { isValidEmail } from "utils/StringUtils";
import { logout } from "utils/BeeApi";
import { logout as logoutDE } from "utils/DeApi";
import { useContentContext } from "contexts/ContentContext";
import LoginWrapper from "../LoginWrapper/LoginWrapper";

export default function InvestorSignin() {
  const [error, setError] = useState();
  const setUser = useContext(UserDispatchContext);
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { fetchBookmarks, fetchContents } = useContentContext();

  const navigate = useNavigate();

  const handleLinkedInLogin = async () => {
    linkedInOAuthLogin()
      .then((authCode) => {
        const getMember = post(`/linkedin-auth`, {
          authCode: authCode,
        });
        getMember.promise
          .then((response) => {
            localStorage.setItem("role", "investor");
            setUser({
              firstName: response?.data?.deMember?.name,
              role: "investor",
              beeId: response?.data?.beeMember?.id,
              syndicationGroup: response.data.deMember.syndicationGroup,
              ...response?.data?.deMember,
            });
            fetchContents();
            fetchBookmarks(response?.data?.deMember?.id);
          })
          .catch((err) => setError(err));
      })
      .catch((err) => setError(err));
  };

  function handleEmailChange(event) {
    let _email = event.target.value;
    setEmail(_email);
    setEmailValid(isValidEmail(_email));
    setFormSubmitted(false);
  }

  function handlePasswordChange(event) {
    let password = event.target.value;
    setPassword(password);
    setPasswordValid(!!password.length);
    setFormSubmitted(false);
  }

  function toggleVisiblePassword() {
    setShowPassword(!showPassword);
  }
  function handleSubmit(event) {
    event.preventDefault();

    if (emailValid && passwordValid) {
      setError("");
      setIsLoading(true);
      login({ email: email, password: password })
        .then((response) => {
          return loginDE({ ...response.data, email: email });
        })
        .then((response) => {
          const base64Url = response.data.token.split(".")[1];
          const base64 = base64Url.replace("-", "+").replace("_", "/");
          const decoded = JSON.parse(atob(base64));
          return {
            role: decoded.role,
            isPolicyRead: decoded.isPolicyRead,
            member: decoded.member,
          };
        })
        .then(({ role, isPolicyRead, member }) => {
          if (role === "member") {
            return get("user/anon").promise.then((response) => {
              return {
                role: "investor",
                ...response.data,
                isPolicyRead,
                member,
              };
            });
          }
        })
        .then((user) => {
          if (user) {
            setError(null);
            setIsLoading(false);
            setUser({ ...user });
          } else {
            setError("User not exist!");
            logout().then(() => {
              return logoutDE();
            });

            setIsLoading(false);
          }
        })
        .catch((error) => {
          setError("User not exist!");
          logout().then(() => {
            return logoutDE();
          });

          setIsLoading(false);
        });
    }
  }

  useEffect(() => {
    localStorage.setItem("role", "investor");
  }, []);

  return (
    <LoginWrapper>
      <div>
        <h3 className="my-3 text-center">Investor Sign In</h3>
        <hr />
        {/*  */}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="my-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={handleEmailChange}
              isInvalid={!emailValid && formSubmitted}
              placeholder="Enter email"
              autoFocus
            />
            <Form.Control.Feedback type="invalid">
              This should be a valid email address
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                isInvalid={!passwordValid && formSubmitted}
                placeholder="Password"
              />
              <Button
                variant="light"
                className="border-top border-end border-start pt-0 border-bottom"
                onClick={toggleVisiblePassword}
              >
                {!showPassword ? (
                  <span className="material-icons-outlined">
                    visibility_off
                  </span>
                ) : (
                  <span className="material-icons-outlined">visibility</span>
                )}
              </Button>
            </InputGroup>
            <Form.Control.Feedback type="invalid">
              This is required
            </Form.Control.Feedback>
          </Form.Group>
          <div className="d-grid gap-2 mb-3">
            <Button
              type="submit"
              className="text-white"
              disabled={isLoading || email === "" || password === ""}
            >
              {isLoading && (
                <Spinner
                  className="me-2"
                  animation="border"
                  size="sm"
                  variant="light"
                />
              )}
              Sign in
            </Button>
          </div>
          <p className="text-center">
            <Link to="/forgot-password">Forgot Password?</Link>
          </p>
        </Form>

        <div className="d-flex flex-row justify-content-evenly">
          <div className="d-flex justify-content-center">
            <GoogleLogin
              onSuccess={async (credentialResponse) => {
                let { email } = await loginVal(credentialResponse);
                const getLoginUrl = post(`/google-auth`, {
                  idToken: credentialResponse?.credential,
                });
                getLoginUrl.promise
                  .then((response) => {
                    if (response?.data) {
                      localStorage.setItem("role", "investor");
                      setUser({
                        firstName: response?.data?.deMember?.name,
                        role: "investor",
                        beeId: response?.data?.beeMember?.id,
                        ...response?.data?.deMember,
                      });
                      fetchContents();
                      fetchBookmarks(response?.data?.deMember?.id);
                    } else {
                      setError(
                        `${email} does not currently have investor access. Use the link below to request access.`
                      );
                    }
                  })
                  .catch((error) =>
                    setError(
                      `${email} does not currently have investor access. Use the link below to request access.`
                    )
                  );
              }}
              width="200px"
              onError={() => {
                console.log("Login Failed");
              }}
            />
          </div>
          <div className="d-flex justify-content-center">
            <img
              onClick={handleLinkedInLogin}
              src="/download.png"
              alt="Sign in with Linked In"
              style={{ maxWidth: "200px", cursor: "pointer" }}
            />
          </div>
        </div>
        {error && <ErrorHandler message={error} />}
        <p className="text-center mt-4">
          Don't have an account on the Syndication Platform?
          <br />
          <a
            target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLSdg0sm7a_ABu9POFbkpMUCy3FrFf26l1IdYJC1Iurtr3CmQ_g/viewform"
            rel="noreferrer"
          >
            Request An Investor Account
          </a>
        </p>
        <p className="text-center mt-4">
          <Button
            onClick={() => {
              navigate("/entrepreneur-signin");
            }}
          >
            Entrepreneur Sign In
          </Button>
        </p>
      </div>
    </LoginWrapper>
  );
}
