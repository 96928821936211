import { useContext, useEffect, useState, useRef } from "react";
import { UserContext, UserDispatchContext } from "contexts/UserProvider";
import { Container, Row, Col, Form, Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { get, post } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

const UnsubscribeEmail = () => {
  const subscribedPromises = useRef([]);
  const [selectedOptions, setSelectedOptions] = useState("");
  const [comments, setComments] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const user = useContext(UserContext);
  const setUser = useContext(UserDispatchContext);
  const navigate = useNavigate();

  const handleRadioChange = (option) => {
    setSelectedOptions(option);
    // option !== "Platform is not working for them, Why?" && setComments("");
  };

  const handleUnsubscribeEmail = () => {
    const unsubscribeEmail = post("unsub-update-profile-email", {
      ventureId: user?.ventureId,
      reason: selectedOptions,
      reasonDescription:
        selectedOptions === "Platform is not working for them, Why?"
          ? comments
          : null,
    });
    setIsLoading(true);
    setError("");
    unsubscribeEmail.promise
      .then(() => {
        setIsLoading(false);
        setError("");
        navigate("/");
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
        console.error(error);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchVentures = get(`/ventures`);
    fetchVentures.promise
      .then((response) => {
        const userVal = response.data?.filter(
          (data) =>
            data?.createdBy === user?.userId || data?.createdBy === user?.id
        );
        if (!!userVal?.length) {
          setUser((prevUser) => ({
            ...prevUser,
            ventureId: userVal?.[0]?.id,
          }));
        } else {
          navigate("/");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(fetchVentures);

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return (
    <Container>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <ErrorHandler error={error} />
      ) : (
        <Row>
          <Col
            xl={{ span: 5, offset: 3 }}
            lg={{ span: 6, offset: 3 }}
            md={{ span: 8, offset: 2 }}
            sm={{ span: 7, offset: 3 }}
          >
            <div className="text-center Logo">
              <img
                className="img-responsive w-75 mx-auto"
                alt="Logo"
                src="/etiquetteLogo.png"
              />
            </div>
            <Card className="border-0 shadow-sm my-4">
              <Card.Body>
                <p>
                  Please take a moment to tell us why you no longer wish to hear
                  from us:
                </p>
                <Form>
                  {[
                    "Round is Closed",
                    "Updating Regularly, don't need reminders",
                    "Platform is not working for them, Why?",
                  ].map((option, index) => (
                    <Form.Check
                      key={index}
                      type="radio"
                      label={
                        <span onClick={() => handleRadioChange(option)}>
                          {option}
                        </span>
                      }
                      name="unsubscribeOption"
                      checked={selectedOptions === option}
                      onChange={() => handleRadioChange(option)}
                    />
                  ))}
                  <Form.Group controlId="additionalComments" className="mt-2">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                      disabled={
                        selectedOptions !==
                        "Platform is not working for them, Why?"
                      }
                    />
                  </Form.Group>
                </Form>
                <div className="d-flex align-items-center justify-content-end mt-3">
                  <Button
                    size="sm"
                    variant="secondary"
                    className="me-1"
                    onClick={() => navigate("/")}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    variant="primary"
                    disabled={
                      !selectedOptions ||
                      (selectedOptions ===
                        "Platform is not working for them, Why?" &&
                        !comments)
                    }
                    onClick={handleUnsubscribeEmail}
                  >
                    Submit
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default UnsubscribeEmail;
