import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

const ConfirmationModal = ({
  isShowModal,
  isSubmitting,
  toggleModal,
  handleModalSubmit,
}) => {
  return (
    <Modal
      size="md"
      md
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isShowModal}
    >
      <Modal.Header>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-primary "
        >
          Confirmation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Kindly test your links in an incognito browser window before
          submitting.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={isSubmitting} onClick={toggleModal}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} onClick={handleModalSubmit}>
          {isSubmitting ? (
            <>
              <Spinner animation="border" size="sm" />{" "}
            </>
          ) : null}
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
