import { useContext, useEffect } from "react";
import { UserContext } from "contexts/UserProvider";

function DataLayer() {
  // Push the userEmail to the dataLayer when it changes
  const user = useContext(UserContext);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "User_status",
      userEmail: user?.email,
      role: user?.role,
    });
  }, [user?.email]);

  return <span id="data-layer"></span>;
}

export default DataLayer;
