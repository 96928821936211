import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
const VoteInstruction = () => {
  const [criteria, setCriteria] = useState(false);
  const [conflictInterest, setConflictInterest] = useState(false);
  return (
    <div className="my-3">
      <h6
        className="mt-3 text-primary"
        onClick={() => setConflictInterest(!conflictInterest)}
        role="button"
      >
        <span className="material-icons-outlined me-2">info</span>
        Conflict of Interest
        {conflictInterest ? (
          <span className="material-icons-outlined">expand_more</span>
        ) : (
          <span className="material-icons-outlined">chevron_right</span>
        )}
      </h6>
      <div>
        <Collapse
          in={conflictInterest}
          className="bg-info border bg-white rounded p-2"
        >
          <div>
            <small>
              <p>
                For the purposes of scoring companies we define a conflict of
                interest as: you (or a family member) work for, or have invested
                in, the company.
              </p>
              <p>
                If you have a conflict of interest please select the "Conflict
                of interest" option. Note that we use spiffy math to ensure you
                do not hurt your company by doing so.
              </p>
            </small>
          </div>
        </Collapse>
      </div>
      <h6
        className="text-primary mt-3"
        onClick={() => setCriteria(!criteria)}
        role="button"
      >
        <span className="material-icons-outlined me-2">info</span>
        Tips for Scoring
        {criteria ? (
          <span className="material-icons-outlined">expand_more</span>
        ) : (
          <span className="material-icons-outlined">chevron_right</span>
        )}
      </h6>
      <Collapse in={criteria} className="bg-info border bg-white rounded p-2">
        <div>
          <small>
            <p>
              If you are new to evaluating syndicated deals, here are some
              trends we’ve noticed that you may wish to take into account:{" "}
            </p>
            <ul className="text-dark">
              <li className="my-1">
                Deals that have $$ invested from the champion angel group do
                best. Deals in due diligence or in term-sheet negotiation often
                struggle.
              </li>
              <li className="mb-1">
                Companies that are "wrapped up and ready to go" do much better.
                By that we mean: they have a diligence package available, have
                all the deal documents ready to go, and a deal lead ready to
                organize follow-up.
              </li>
              <li className="mb-1">
                Diverse teams have proven to outperform non-diverse teams and so
                tend to attract more attention.
              </li>
              <li className="mb-1">
                Convertible notes and SAFEs, particularly when raising more than
                $500k, are automatically rejected by significant numbers of
                angels.
              </li>
            </ul>
          </small>
        </div>
      </Collapse>
    </div>
  );
};

export default VoteInstruction;
