import React from "react";
import { Alert } from "react-bootstrap";
const NoVote = () => {
  return (
    <div>
      <br />

      <Alert className="mx-auto" variant="info">
        <h5 className="text-center">
          "Conflict of Interest" votes are not included in this calculation.{" "}
        </h5>
      </Alert>
    </div>
  );
};

export default NoVote;
