const { REACT_APP_LINKEDIN_CLIENT_ID } = process.env;
const redirectUri = `${window.location.origin}/linkedin`;
const scope = "openid profile email r_liteprofile r_emailaddress";
const state = Math.random().toString(36).substring(2);

export function linkedInOAuthLogin() {
  return new Promise((resolve, reject) => {
    const authorizationUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&scope=${encodeURIComponent(scope)}&state=${state}`;

    // Open the LinkedIn login popup window
    const popupWindow = window.open(
      authorizationUrl,
      "_blank",
      "width=600,height=600"
    );

    // Listen for messages from the popup window
    const receiveMessage = (event) => {
      if (event?.data?.code) {
        const authCode = event?.data?.code;
        resolve(authCode); // Resolve the promise with the authCode
      } else {
        reject(new Error("Failed to receive auth code."));
      }
      popupWindow.close();
      window.removeEventListener("message", receiveMessage);
    };
    window.addEventListener("message", receiveMessage);
  });
}
