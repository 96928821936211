import React, { useContext, useState } from "react";
import {
  Form,
  Modal,
  Button,
  Spinner,
  Nav,
  Alert,
  InputGroup,
  Row,
  Container,
  Col,
} from "react-bootstrap";
import { Formik, Field } from "formik";
import * as yup from "yup";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { post } from "utils/DeApi";
import { UserContext } from "contexts/UserProvider";
import "./Nominate.scss";
function Nominate() {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState("");
  const [error, setError] = useState();
  const user = useContext(UserContext);

  const handleClose = () => {
    setShow(false);
    setShowMsg("");
  };
  const handleShow = () => setShow(true);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Must be a valid email address *")
      .required("Email is required *"),
    emails: yup
      .array()
      .of(yup.string().email("Must be a valid email address *"))
      .required("At least one email is required"),
    groups: yup
      .array()
      .of(yup.string())
      .required("At least one syndication group is required"),
  });

  const initialValues = {
    email: "",
    emails: [],
    groups: [],
  };

  const updateSiteReviewWorkflow = (
    { email, emails, groups },
    setFieldValue
  ) => {
    const nominateEmails = [email, ...emails].filter(
      (email) => email.trim() !== ""
    );
    const emailObjects = nominateEmails.map((email) => ({ email }));
    const groupObjects = groups?.map((group) => ({
      id: group,
    }));

    const combinedData = emailObjects.map((emailObj) => ({
      email: emailObj.email,
      syndicationGroupIds: [...groupObjects],
    }));

    const updateSiteReviewWorkflow = post(`/deal-nomination`, {
      data: combinedData,
      memberId: user ? user?.member?.id || user?.id : null,
    });
    setIsLoading(true);
    setError("");
    setShowMsg("");
    updateSiteReviewWorkflow.promise
      .then((response) => {
        setIsLoading(false);
        setError("");
        const emails = emailObjects.map((obj) => obj.email).join(", ");
        if (response?.error) {
          setError({
            data: {
              error: {
                message: response?.error,
              },
            },
          });
        } else {
          setShowMsg(`${emails} have been successfully nominated.`);
        }
        window.dataLayer.push({
          event: "Email_nominated",
          email: emails,
        });
        setFieldValue.setFieldValue("email", "");
        setFieldValue.setFieldValue("emails", []);
        setFieldValue.setFieldValue("groups", []);
        setFieldValue.setFieldTouched("email", false);
        setFieldValue.setFieldTouched("emails", false);
        setFieldValue.setFieldTouched("groups", false);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
        console.error(error);
      });
  };

  const checkEmails = ({ email, emails }) => {
    const allSame =
      emails?.length > 1 &&
      emails.every(
        (arrayEmail) =>
          arrayEmail !== "" && emails[0] !== "" && arrayEmail === emails[0]
      );
    const inArray = emails.includes(email);
    const duplicateIndices = [];
    const emailSet = new Set();

    for (let i = 0; i < emails.length; i++) {
      if (emailSet.has(emails[i])) {
        duplicateIndices.push(i);
      } else {
        emailSet.add(emails[i]);
      }
    }

    if (inArray) {
      duplicateIndices.push(emails.indexOf(email));
    }

    return [allSame || inArray, duplicateIndices];
  };

  const handleCheckboxChange = (setFieldValue, values, grp) => (e) => {
    const checked = e.target.checked;
    if (checked) {
      setFieldValue("groups", [...values?.groups, grp.id]);
    } else {
      setFieldValue("groups", [
        ...values?.groups?.filter((id) => id !== grp.id),
      ]);
    }
  };

  return (
    <>
      <Nav.Link onClick={() => handleShow()}>Nominate</Nav.Link>

      <Modal show={show} backdrop={"static"} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Nominate a deal</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={(values, setFieldValue) =>
            updateSiteReviewWorkflow(values, setFieldValue)
          }
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <>
              <Modal.Body>
                <div className="nomination-detail">
                  <strong>Nomination Criteria:</strong>
                  <ul>
                    <li>Ensure your group is investing in this round.</li>
                    <li>Actively share due diligence.</li>
                    <li>
                      Advocate for the company at syndication events, if
                      selected.
                    </li>
                    <li>
                      If a company has opened a new funding round since you last
                      nominated them, you may submit a new nomination.
                    </li>
                  </ul>
                  <strong>How to Nominate:</strong>
                  <ul>
                    <li>Select the appropriate syndication groups.</li>
                    <li>Enter the CEO’s email address.</li>
                    <li>
                      To nominate multiple companies, click the “+” button to
                      add additional email fields.
                    </li>
                    <li>
                      Press “SEND EMAIL” to initiate the nomination process.
                    </li>
                  </ul>
                  <strong>Next Steps:</strong>
                  <ul>
                    <li>
                      We will send detailed instructions to the entrepreneur and
                      copy you on the email.
                    </li>
                    <li>
                      The entrepreneur must complete their application promptly.
                    </li>
                    <li>
                      The listing will remain active for up to 60 days, provided
                      the entrepreneur keeps their application current.
                    </li>
                  </ul>
                </div>
                <Form.Group controlId="syndication-group" className="mb-3">
                  <Form.Label>
                    <strong>Choose Syndication Group</strong>
                    <sup className="text-danger px-1 fs-5 top-0">*</sup>
                    <small className="text-muted">(select at least one)</small>
                  </Form.Label>
                  <Field name="groups" value={values.groups}>
                    {({ field }) => (
                      <div className="row mx-1">
                        {user?.syndicationGroups?.map((grp) => (
                          <div key={grp?.id} className="form-check col-6 ps-1">
                            <Form.Check
                              type={"checkbox"}
                              id={`checkbox-${grp?.id}`}
                              label={grp?.title}
                              name={grp?.title}
                              onChange={handleCheckboxChange(
                                setFieldValue,
                                values,
                                grp
                              )}
                              checked={values?.groups?.includes(grp?.id)}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </Field>
                  <Form.Control.Feedback type="invalid">
                    {errors.groups}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Label>Startup CEO's Email</Form.Label>

                <Form.Group controlId="email">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <Form.Control
                        name="email"
                        type="text"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.email && touched.email}
                        placeholder="Enter email"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.email}
                      </Form.Control.Feedback>
                    </div>

                    <button
                      onClick={() =>
                        setFieldValue("emails", [...values.emails, ""])
                      }
                      className="icon-btn rounded-circle bg-transparent border border-primary cursor-pointer mx-2 my-1"
                    >
                      <i className="icon-add material-icons text-primary">
                        add
                      </i>
                    </button>
                  </div>
                </Form.Group>

                <div className="emails-container my-2">
                  {values?.emails?.map((email, index) => (
                    <Form.Group key={index} controlId="emails">
                      <div className="d-flex my-2">
                        <div className="flex-grow-1">
                          <Form.Control
                            type="text"
                            name={`emails[${index}]`}
                            placeholder="Enter email"
                            value={values?.emails?.[index]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              errors?.emails?.[index] &&
                              touched?.emails?.[index]
                            }
                          />
                          {checkEmails(values)?.[0] &&
                          values?.email &&
                          checkEmails(values)?.[1]?.includes(index) ? (
                            <Form.Text type="invalid" className="text-danger">
                              This email is already added!
                            </Form.Text>
                          ) : (
                            <Form.Control.Feedback type="invalid">
                              {errors?.emails?.[index]}
                            </Form.Control.Feedback>
                          )}
                        </div>
                        <button
                          className="icon-btn rounded-circle bg-transparent border border-danger my-1 mx-2 cursor-pointer"
                          onClick={() => {
                            const updatedEmails = [...values.emails];
                            updatedEmails.splice(index, 1);
                            setFieldValue("emails", updatedEmails);
                          }}
                        >
                          <i className="icon-del material-icons text-danger">
                            delete
                          </i>
                        </button>
                      </div>
                    </Form.Group>
                  ))}
                </div>
                {showMsg && (
                  <Alert variant="success">
                    <h4>Success!</h4>
                    <p>{showMsg}</p>
                  </Alert>
                )}
                {error && <ErrorHandler error={error} />}
              </Modal.Body>
              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Close
                </Button>
                <Button
                  size="sm"
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={
                    !values?.email ||
                    !isValid ||
                    checkEmails(values)?.[0] ||
                    !values?.groups?.length
                  }
                >
                  Send Email{"  "}
                  {isLoading && (
                    <Spinner
                      animation="border"
                      variant="white"
                      size="sm"
                      className="ms-3"
                    />
                  )}
                  {error && (
                    <span className="material-icons-outlined md-18 text-danger">
                      error
                    </span>
                  )}
                </Button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default Nominate;
