import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Container,
  Col,
  Row,
  Button,
  Form,
  InputGroup,
  Spinner,
  Card,
} from "react-bootstrap";

import "./EntrepreneurSignup.css";
import { post } from "utils/BeeApi";
import { get } from "utils/DeApi";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import TextLogo from "../TextLogo/TextLogo";
import { isValidEmail, isValidPassword, isNameValid } from "utils/StringUtils";
import RedirectIfAuthenticated from "../RedirectIfAuthenticated/RedirectIfAuthenticated";
import Copyright from "../Copyright/Copyright";

const EntrepreneurSignup = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [emailValid, setEmailValid] = useState();
  const [passwordValid, setPasswordValid] = useState();
  const [firstNameValid, setFirstNameValid] = useState();
  const [lastNameValid, setLastNameValid] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const subscribedPromises = useRef([]);
  const [success, setSuccess] = useState(false);
  const [touched, setTouched] = useState({
    firstNameValidator: false,
    lastNameValidator: false,
    emailValidator: false,
    passwordValidator: false,
  });

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => promises.forEach((promise) => promise.cancel());
  }, []);

  function handleFirstNameChange(event) {
    let _firstName = event.target.value;
    setFirstName(_firstName);
    setFirstNameValid(isNameValid(_firstName));
  }

  function handleLastNameChange(event) {
    let _lastName = event.target.value;
    setLastName(_lastName);
    setLastNameValid(isNameValid(_lastName));
  }

  function handleEmailChange(event) {
    let _email = event.target.value;
    setEmail(_email);
    setEmailValid(isValidEmail(_email));
  }

  function handlePasswordChange(event) {
    let _password = event.target.value;
    setPassword(_password);
    setPasswordValid(isValidPassword(_password));
  }

  function toggleVisiblePassword() {
    setShowPassword(!showPassword);
  }

  function handleSubmit(event) {
    event.preventDefault();
    setFormSubmitted(true);
    if (emailValid && passwordValid && firstNameValid && lastNameValid) {
      setIsLoading(true);
      setError("");
      const fetchNominations = get(`/nominations/${email}`);
      fetchNominations.promise
        .then((resp) => {
          return resp;
        })
        .then((res) => {
          if (!!res?.data?.syndicationGroups?.length) {
            const createPromise = post("/user", {
              firstName: firstName,
              lastName: lastName,
              email: email,
              password: password,
              password_confirmation: password,
            });
            createPromise.promise
              .then((response) => {
                setIsLoading(false);
                setError("");
                setSuccess(true);
              })
              .catch((error) => {
                setIsLoading(false);
                setError(error);
              });
            subscribedPromises.current.push(createPromise);
          } else {
            setIsLoading(false);
            setError({
              data: {
                error: {
                  message:
                    "Please use the nominated email address to create your account",
                },
              },
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setError(error);
        });
    }
  }

  function renderRegistrationForm() {
    return (
      <Form onSubmit={handleSubmit}>
        <div className="text-center">
          <h2 className="pre-title">Create an account</h2>
        </div>
        <hr />
        <Row>
          <Form.Group className="mb-3">
            <Form.Label>First name</Form.Label>
            <Form.Control
              type="text"
              value={firstName}
              required
              onChange={handleFirstNameChange}
              isInvalid={
                !firstNameValid &&
                touched.firstNameValidator &&
                firstName !== ""
              }
              placeholder="Enter first name"
              onBlur={() => {
                setTouched({ firstNameValidator: true });
              }}
            />

            <Form.Control.Feedback type="invalid">
              First name is required that has length greater than 2
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Last name</Form.Label>
            <Form.Control
              type="text"
              value={lastName}
              onChange={handleLastNameChange}
              isInvalid={
                !lastNameValid && touched.lastNameValidator && lastName !== ""
              }
              placeholder="Enter last name"
              onBlur={() => {
                setTouched({ lastNameValidator: true });
              }}
              required
            />
            <Form.Control.Feedback type="invalid">
              Last name is required that has length greater than 2
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={handleEmailChange}
              isInvalid={!emailValid && touched.emailValidator && email !== ""}
              placeholder="Enter email"
              onBlur={() => {
                setTouched({ emailValidator: true });
              }}
              required
            />

            <Form.Control.Feedback type="invalid">
              This should be a valid email address
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <InputGroup>
              <Form.Control
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                isInvalid={
                  !passwordValid && touched.passwordValidator && password !== ""
                }
                placeholder="Password"
                onBlur={() => {
                  setTouched({ passwordValidator: true });
                }}
                required
              />
              <Button
                variant="light"
                className="border-top border-end border-start pt-0 border-bottom"
                onClick={toggleVisiblePassword}
              >
                {showPassword ? (
                  <span className="material-icons-outlined">
                    visibility_off
                  </span>
                ) : (
                  <span className="material-icons-outlined">visibility</span>
                )}
              </Button>

              <Form.Control.Feedback type="invalid">
                Password must contain at least 6 characters, including
                UPPER/lower case and numbers.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          {error &&
            ((error) => {
              if (error.status === 409) {
                return (
                  <Alert variant="warning" className="mt-md mb-md">
                    <h3>Conflict</h3>
                    <p>
                      An account associated with this email address already
                      exists. Please use a different email address.
                    </p>
                  </Alert>
                );
              } else {
                return <ErrorHandler error={error} />;
              }
            })(error)}
          {success && (
            <Alert variant="success d-inline">
              <h4>Success!</h4>
              Your account has been created,
              <Link
                to="/entrepreneur-signin"
                className="font-weight-bold alert-success m-1"
              >
                click here to login.
              </Link>
            </Alert>
          )}
          <Col md={12}>
            <div className="d-grid gap-2 mb-3">
              <Button type="submit" className="text-white" disabled={isLoading}>
                {isLoading && (
                  <Spinner
                    className="me-2"
                    animation="border"
                    size="sm"
                    variant="light"
                  />
                )}
                Create Account
              </Button>
            </div>
            <p className="text-center pt-3">
              <Link to="/entrepreneur-signin">Already have an account?</Link>
            </p>
          </Col>
        </Row>
      </Form>
    );
  }

  return (
    <Container className="Login mt-5 mb-5">
      <RedirectIfAuthenticated />
      <Row>
        <Col
          xl={{ span: 4, offset: 4 }}
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          sm={{ span: 8, offset: 2 }}
        >
          <TextLogo />
          <Card className="border-0 shadow-sm">
            <Card.Body>{renderRegistrationForm()}</Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="my-3">
          <p className="text-center text-dark mb-1">
            <small>
              <Copyright />
            </small>
            <p className="text-dark text-center my-2">
              Powered by PixelEdge.io
            </p>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default EntrepreneurSignup;
