import { useEffect, useState } from "react";

const LINKEDIN_OAUTH2_STATE = "RANDOM_STR";
function parse(search) {
  const query = search.substring(1);
  const vars = query.split("&");
  const parsed = {};
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (pair.length > 1) {
      parsed[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }
  }
  return parsed;
}

export function CustomCallback() {
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    const params = parse(window.location.search);
    if (params.error) {
      const errorMessage =
        params.error_description || "Login failed. Please try again.";
      window.opener &&
        window.opener.postMessage(
          {
            error: params.error,
            state: params.state,
            errorMessage,
            from: "Linked In",
          },
          window.location.origin
        );
      // Close tab if user cancelled login
      if (params.error === "user_cancelled_login") {
        window.close();
      }
    }
    if (params.code) {
      window.opener &&
        window.opener.postMessage(
          { code: params.code, state: params.state, from: "Linked In" },
          window.location.origin
        );
    }
  }, []);
  window.close();
  return <div>{errorMessage}</div>;
}
