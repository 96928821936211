import { useField } from "formik";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import FormText from "react-bootstrap/FormText";
import PhoneInput from "react-phone-input-2";

const CustomPhoneInput = ({ label = "", ...props }) => {
  const [field, meta, helpers] = useField(props);

  const isValid = field.value && !meta.error ? true : false;
  const isInvalid = meta.error && meta.touched ? true : false;

  const handleChange = (value) => {
    helpers.setValue(value);
  };

  return (
    <FormGroup>
      <FormLabel>
        <span className="text-secondary">{label}</span>
        {props.required ? <span className="text-danger">*</span> : null}
      </FormLabel>
      <PhoneInput
        {...field}
        {...props}
        onChange={handleChange}
        isValid={isValid}
        inputProps={{
          id: props.id,
        }}
        inputStyle={{ height: "38px", width: "100%" }}
      />
      {isInvalid ? (
        <FormText className="text-danger">{meta.error}</FormText>
      ) : null}
    </FormGroup>
  );
};

export default CustomPhoneInput;
