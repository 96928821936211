import React, { useContext, useState } from "react";
import { Modal, Button, Nav } from "react-bootstrap";
import { UserContext } from "contexts/UserProvider";
import { UserDispatchContext } from "contexts/UserProvider";
import { post } from "utils/DeApi";
import "./ReadMe.scss";

const ReadMe = () => {
  const user = useContext(UserContext);
  const setUser = useContext(UserDispatchContext);
  const [showIntro, setShowInto] = useState(false);

  const [show, setShow] = useState(!user.isPolicyRead);

  const handleClose = () => {
    !user.isPolicyRead && setPolicyRead();
    setShow(false);
    setShowInto(false);
  };
  const handleShow = () => setShow(true);
  const setPolicyRead = () => {
    const viewPolicyPromise = post(`/members`, {
      isPolicyRead: 1,
      email: user?.email,
    });
    viewPolicyPromise.promise
      .then(() => {
        setUser((prevState) => ({
          ...prevState,
          isPolicyRead: 1,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Nav.Link onClick={() => handleShow()}>Rules</Nav.Link>
      {!showIntro ? (
        <Modal
          show={show}
          backdrop="static"
          size="lg"
          backdropClassName="opacity-75"
        >
          <Modal.Header className="d-flex flex-column">
            <img
              className="img-fluid w-50"
              src={"etiquetteLogo.png"}
              alt="ACA Logo"
            />
            <Modal.Title as="h2" className="text-primary">
              Welcome to the ACA’s Syndication Platform
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="my-3">
                <p className="text-secondary">
                  The ACA Syndication Platform gives ACA Members access to
                  high-quality and vetted deals. It also helps you syndicate
                  deals to fill rounds faster.
                </p>
              </div>
              <div className="my-3">
                <h3 className="text-primary">Rules</h3>
                <ul className="text-secondary">
                  <li>
                    If you like one of the deals listed here contact the CEO and
                    lead investor.
                  </li>
                  <li>
                    Be transparent with entrepreneurs and fellow investors about
                    any presentation fees or potential conflicts of interest.
                  </li>
                  <li>
                    Please Follow the Golden Rule: Treat others (entrepreneurs
                    and fellow investors alike) as you’d like to be treated.
                  </li>
                  <li>
                    If you want to nominate a deal, use the Nominate button in
                    the top navbar
                  </li>
                </ul>
              </div>
              <div className="my-3">
                <h3 className="text-primary">Legal Stuff</h3>
                <p className="text-secondary">
                  THE INFORMATION ON THE PLATFORM IS HIGHLY CONFIDENTIAL. DO NOT
                  SHARE OUTSIDE OF YOUR SYNDICATION GROUP!
                </p>
                <p className="text-secondary">
                  This information is shared under the terms of the{" "}
                  <span
                    className="text-primary text-decoration-underline showPointer"
                    onClick={() => setShowInto(true)}
                  >
                    ACA Syndication Agreement
                  </span>{" "}
                  and is intended to foster cooperation in investments between
                  angel groups. It is an agreement whereby angel groups
                  contemplating the sharing of due diligence materials can give
                  each other a degree of comfort regarding the liability of
                  shared materials. If you use this platform then you agree to
                  operate under the terms of the{" "}
                  <span
                    className="text-primary text-decoration-underline showPointer"
                    onClick={() => setShowInto(true)}
                  >
                    ACA Syndication Agreement
                  </span>
                  .
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            I agree to abide by these rules
            <Button
              variant="primary"
              className="text-white px-2"
              onClick={handleClose}
            >
              Agree
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <Modal show={show} backdrop={"static"} onHide={handleClose} size="lg">
          <Modal.Header className="flex-column-reverse">
            <Modal.Title className="d-flex flex-column align-items-center">
              <img
                className="img-fluid w-50"
                src={"etiquetteLogo.png"}
                alt="ACA Logo"
              />
              <span className="mt-2 fw-bold">MEMORANDUM OF UNDERSTANDING</span>
              <span className="my-0 fw-bold">
                Towards the Syndication of Angel Investments
              </span>
              <p className="mt-3 fw-bold mb-0">By and Among the Undersigned</p>
            </Modal.Title>
          </Modal.Header>
          <>
            <Modal.Body className="modal-body-size">
              <h4 className="text-center fw-bold">INTRODUCTION</h4>
              <p>
                <span className="fw-bold">WHEREAS</span>, the undersigned Angel
                Groups, individual investors, and other participants in the
                startup ecosystem (as hereinafter defined and collectively, the
                “Parties”) believe a thriving, dynamic ecosystem of angel
                investments benefits entrepreneurs, angel investors, and the
                economy as a whole;
              </p>
              <p>
                <span className="fw-bold">WHEREAS</span>, the Parties believe
                that the ecosystem benefits from strong relationships between
                Angel Groups based on mutual trust and shared best practices;
              </p>
              <p>
                <span className="fw-bold">WHEREAS</span>, the Parties desire to
                share opportunities for investing and co-investing in angel
                syndications;
              </p>
              <p>
                <span className="fw-bold">WHEREAS</span>, the Parties have
                executed this non-binding Memorandum of Understanding as of the
                day and year reflected next to the signatories below;
              </p>
              <h4 className="text-center fw-bold">AGREEMENT</h4>
              <p>
                <span className="fw-bold">NOW THEREFORE</span>, the undersigned
                Parties agree as follows:
              </p>
              <p>
                <span className="fw-bold">Non-Binding</span>. This MOU is a
                commitment to principles of syndication, but is not legally
                binding on the Parties.
              </p>
              <p className="fw-bold">Definitions.</p>
              <ul>
                <li>
                  <span className="fw-bold">“Angel Group”</span> – Corporate
                  entity (LLC or Corp.) whose members are individual accredited
                  angel investors and who desires to be a part of the ACASN. In
                  order to be a member of the ACASN, the Angel Group will sign
                  the ACASN MOU.
                </li>
                <li>
                  <span className="fw-bold">“Co-Investor”</span> – Angel Group
                  who desires to consider investment in a Syndicated Deal.
                </li>
                <li>
                  <span className="fw-bold">“Deal Memo”</span> – A document
                  summarizing a Syndicated Deal.
                </li>
                <li>
                  <span className="fw-bold">“Due Diligence Materials”</span> –
                  any background information, whether in written or other form,
                  that was utilized in drafting the Deal Memo or forming the
                  opinions that were underlying the Deal Memo.
                </li>
                <li>
                  <span className="fw-bold">“Individual Investors”</span> –
                  Accredited angel investors who are members of an Angel Group
                  that is a member of the ACASN.
                </li>
                <li>
                  <span className="fw-bold">“Lead Investor”</span> – The person
                  or group that has performed due diligence, written a due
                  diligence report and invested in a Venture Company, and who
                  desires to introduce and share the opportunity with other
                  Parties.
                </li>
                <li>
                  <span className="fw-bold">“Syndicated Deal”</span> – Venture
                  deal that has been shared among one or more Parties.
                </li>
                <li>
                  <span className="fw-bold">“Venture Company”</span> – The
                  entity seeking investment from the Angel Groups and/or
                  Individual Investors.
                </li>
              </ul>
              <p>
                <span className="fw-bold">Objectives</span>. The objectives of
                this MOU are:
              </p>
              <ul>
                <li>
                  To build ongoing relationships between Parties for syndicating
                  investment;
                </li>
                <li>
                  To work together to describe the “best practices” with respect
                  to the Structure, Documents and Process of Syndicated Deals;
                  and
                </li>
                <li>
                  To facilitate venture deal syndication and co-investing
                  opportunities between and among the Parties.
                </li>
              </ul>
              <p>
                <span className="fw-bold">Principles</span>. These principles
                are the basis of our relationships for syndicating investments:
              </p>
              <p>
                <span className="fw-bold">Mutual Trust but Non-Reliance</span>.
                Effective syndication relies on trust between Parties; however,
                every Individual Investor makes their own investment decisions.
                Documents, opinions, and information are shared in good faith,
                but recipients of this information agree to hold harmless any
                Party that provided it.
              </p>
              <p>
                <span className="fw-bold">
                  Mutual Respect and Handling of Sensitive Information
                </span>
                . Syndication can involve sharing sensitive information about a
                deal. This comes in the form of competition- sensitive
                information provided by a Venture Company, as well as sensitive
                information provided by a Lead Investor or other Party, such as
                due diligence opinions. As Parties, we agree to respect and
                handle this sensitive information using the same care and
                procedures that we do for similarly-sensitive information we
                handle for our own deals.
              </p>
              <p>
                <span className="fw-bold">Helping Fund a Venture Company</span>.
                A Lead Investor is an advocate for a Venture Company, but only
                in an informal manner. The Lead Investor is not an agent or
                broker for the Venture Company, although the Lead Investor may
                negotiate terms with the Venture Company. Any Co-Investor can
                choose to negotiate different terms with the Venture Company as
                they see fit.
              </p>
              <p>
                <span className="fw-bold">
                  Timeliness and Respect for Other Parties and for the
                  Entrepreneur
                </span>
                . We recognize that funding a Venture Company can impose
                hardships on the Venture Company when not completed in a timely
                manner. We pledge to be respectful of the Venture Company’s
                timeline.
              </p>
              <p>
                <span className="fw-bold">Being Open and Trustworthy</span>.
                Investing in Venture Companies can create conflict of interests,
                which we agree to disclose with other Parties. These can include
                investments in or other relationships with companies that
                compete with a Venture Company. We pledge to conduct ourselves
                with the integrity, honesty, and openness that we expect from
                other Parties, and we will disclose these conflicts of interest
                when they are discovered.
              </p>
              <p>
                <span className="fw-bold">Publicity of this MOU</span>. The
                Parties celebrate their commitment to best practices and
                syndication as signers of this MOU. Out of courtesy to our
                fellow Parties, we agree to announce direct relationships with
                other Parties only with their permission.
              </p>
              <p>
                This agreement is between (‘Syndicator’), and the person or
                entity accessing and reading this deal memo regarding the
                venture (‘Company’). By reading past the ‘End of Agreement’, you
                are indicating your acceptance of the terms of this agreement
                and agree to be bound by them.
              </p>
              <ol>
                <li>
                  Reps and Warranties – The Syndicator makes no Reps or
                  Warranties regarding the accuracy, timeliness or completeness
                  of the Deal Docs, Deal Memo or Due Diligence Materials.
                </li>
                <li>
                  Non-Reliance - You as a prospective Co-Investor are to conduct
                  your own due diligence independently, and any reliance by
                  Co-Investor upon the due diligence materials provided is at
                  your own risk.
                </li>
                <li>
                  Non-Solicitation – The Syndicator is not soliciting on behalf
                  of, or acting as an agent or broker for the Company.
                </li>
                <li>
                  No Liability – The Syndicator shall not bear any liability
                  with respect to the accuracy or completeness of the
                  information or analysis provided or the ultimate success or
                  failure of the investment.
                </li>
                <li>
                  Non-Disclosure - Deal Memo – The Deal Memo and any other
                  proprietary documents analysing the value of a potential
                  investment in a perspective company by the Syndicator or other
                  syndicating groups or individuals, shall not be disclosed and
                  discussed with the Company, and can only be discussed with
                  members of the Syndicator group or other individuals that have
                  agreed to this license agreement.
                </li>
                <li>
                  Non-Disclosure – Company Information – The confidential
                  information shared on the Company shall only be used for
                  review in connection with an investment into the Company and
                  will not be disclosed to any non-affiliated party without
                  prior written consent by the Syndicator.
                </li>
              </ol>
              <p>
                By reading further you are indicating your acceptance of all the
                terms of the preceding license agreement and you may continue
                reading and will find links to access the confidential
                information available in Syndication. If you DO NOT agree to all
                of the above terms, read no further and do not access the links.
              </p>
            </Modal.Body>
          </>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowInto(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default ReadMe;
