import React, { useMemo, useState, useContext, useEffect, useRef } from "react";
import { NumericFormat } from "react-number-format";
import { Formik, Field } from "formik";
import { UserContext, UserDispatchContext } from "contexts/UserProvider";
import { Container } from "react-bootstrap";
import * as Yup from "yup";

import {
  Button,
  Form,
  Row,
  Col,
  FormLabel,
  FormControl,
  Alert,
  FormGroup,
  Card,
  Modal,
} from "react-bootstrap";

import countryList from "react-select-country-list";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { post } from "../../../utils/DeApi";
import { get } from "../../../utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { Link, useNavigate } from "react-router-dom";
import Header from "components/App/Header/Header";
import ProfileInfo from "../ProfileInformation/ProfileInformation";
import Footer from "components/App/Footer/Footer";
import DataLayer from "utils/DataLayer";
import "./VentureProfile.scss";

const VentureProfileSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .email("Invalid email")
    .required("This is a required field"),

  // Company Information
  companyName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("This is a required field"),
  companyWebsite: Yup.string().url("Please include https:// or http://"),
  country: Yup.string().min(2, "Too Short").max(50, "Too Long"),
  state: Yup.string(),
  description: Yup.string()
    .min(10, "Enter at least 10 characters")
    .max(140, "You can enter only 140 characters")
    .required("This is a required field"),

  // Key Documents
  executiveSummaryLink: Yup.string().url("Please include https:// or http://"),
  slideDeckLink: Yup.string()
    .url("Please include https:// or http://")
    .required("This is a required field"),
  pitchVideoLink: Yup.string().url("Please include https:// or http://"),

  // Deal Information
  dealRoomLink: Yup.string().url("Please include https:// or http://"),
  gustProfileLink: Yup.string().url("Please include https:// or http://"),
  dealumProfileLink: Yup.string().url("Please include https:// or http://"),

  // Round Information

  amount: Yup.string().required("This is a required field"),
  valuation: Yup.string().required("This is a required field"),
  committed: Yup.string().required("This is a required field"),
  securityType: Yup.string().required("This is a required field"),
  reason: Yup.string()
    .min(10, "Enter at least 10 characters")
    .max(140, "You can enter only 140 characters")
    .required("This is a required field"),
  // CEO Contact Information
  ceoName: Yup.string()
    .min(2, "Too Short")
    .max(50, "To Long")
    .required("This is a required field"),
  mobileNumber: Yup.mixed().required("It is a required field"),

  // Nominating Investor Information
  dealLeadEmail: Yup.string()
    .email("Invalid email")
    .required("This is a required field"),
  dealLeadName: Yup.string()
    .min(2, "Too Short")
    .max(50, "Too Long")
    .required("This is a required field"),
  dealLeadGroup: Yup.string()
    .min(2, "Too Short")
    .max(50, "Too Long")
    .required("This is a required field"),
});

// security type options
const securityTypes = [
  { value: "Priced round", label: "Priced round", id: "priced-round" },
  {
    value: "Convertible note",
    label: "Convertible note",
    id: "convertible-note",
  },
  { value: "SAFE", label: "SAFE", id: "safe" },
  { value: "Other", label: "Other", id: "other" },
];

// commited section options
const committedPercentage = [
  {
    value: "Over subscribing (100%)",
    label: "Over subscribing (100%)",
    id: "over-subscribing-100",
  },
  { value: "At least (75%)", label: "At least (75%)", id: "atleast-75" },
  { value: "At least (50%)", label: "At least (50%)", id: "atleast-50" },
  { value: "Less than half", label: "Less than half", id: "less-than-half" },
  { value: "Starting (0%)", label: "Starting (0%)", id: "starting-zero" },
];

// interset qualification options
const specialInterestQualifications = [
  {
    value: "LGBTQ co-founder(s)",
    label: "LGBTQ co-founder(s)",
    id: "lgbtq-co-founders",
  },
  {
    value: "People of color co-founder(s)",
    label: "People of color co-founder(s)",
    id: "people-of-color-co-founders",
  },
  {
    value: "Veteran co-founder(s)",
    label: "Veteran co-founder(s)",
    id: "veteran-co-founders",
  },
  {
    value: "Women co-founder(s)",
    label: "Women co-founder(s)",
    id: "women-co-founders",
  },
  {
    value: "Impact investment",
    label: "Impact investment",
    id: "impact-investment",
  },
];

// NICE TO HAVE A SEPARATE FIlE for options and name them
const options = [
  { name: "Alabama", abbreviation: "AL" },
  { name: "Alaska", abbreviation: "AK" },
  { name: "Arizona", abbreviation: "AZ" },
  { name: "Arkansas", abbreviation: "AR" },
  { name: "California", abbreviation: "CA" },
  { name: "Colorado", abbreviation: "CO" },
  { name: "Connecticut", abbreviation: "CT" },
  { name: "Delaware", abbreviation: "DE" },
  { name: "Florida", abbreviation: "FL" },
  { name: "Georgia", abbreviation: "GA" },
  { name: "Hawaii", abbreviation: "HI" },
  { name: "Idaho", abbreviation: "ID" },
  { name: "Illinois", abbreviation: "IL" },
  { name: "Indiana", abbreviation: "IN" },
  { name: "Iowa", abbreviation: "IA" },
  { name: "Kansas", abbreviation: "KS" },
  { name: "Kentucky", abbreviation: "KY" },
  { name: "Louisiana", abbreviation: "LA" },
  { name: "Maine", abbreviation: "ME" },
  { name: "Maryland", abbreviation: "MD" },
  { name: "Massachusetts", abbreviation: "MA" },
  { name: "Michigan", abbreviation: "MI" },
  { name: "Minnesota", abbreviation: "MN" },
  { name: "Mississippi", abbreviation: "MS" },
  { name: "Missouri", abbreviation: "MO" },
  { name: "Montana", abbreviation: "MT" },
  { name: "Nebraska", abbreviation: "NE" },
  { name: "Nevada", abbreviation: "NV" },
  { name: "New Hampshire", abbreviation: "NH" },
  { name: "New Jersey", abbreviation: "NJ" },
  { name: "New Mexico", abbreviation: "NM" },
  { name: "New York", abbreviation: "NY" },
  { name: "North Carolina", abbreviation: "NC" },
  { name: "North Dakota", abbreviation: "ND" },
  { name: "Ohio", abbreviation: "OH" },
  { name: "Oklahoma", abbreviation: "OK" },
  { name: "Oregon", abbreviation: "OR" },
  { name: "Pennsylvania", abbreviation: "PA" },
  { name: "Rhode Island", abbreviation: "RI" },
  { name: "South Carolina", abbreviation: "SC" },
  { name: "South Dakota", abbreviation: "SD" },
  { name: "Tennessee", abbreviation: "TN" },
  { name: "Texas", abbreviation: "TX" },
  { name: "Utah", abbreviation: "UT" },
  { name: "Vermont", abbreviation: "VT" },
  { name: "Virginia", abbreviation: "VA" },
  { name: "Washington", abbreviation: "WA" },
  { name: "West Virginia", abbreviation: "WV" },
  { name: "Wisconsin", abbreviation: "WI" },
  { name: "Wyoming", abbreviation: "WY" },
];

// Also name the form Venture profile
const VentureProfile = () => {
  const subscribedPromises = useRef([]);
  const profiles = useRef([]);
  const user = useContext(UserContext);
  const setUser = useContext(UserDispatchContext);
  const [venture, setVenture] = useState();
  const [profileData, setProfileData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [value, setValue] = useState("United States");
  const [stateVal, setStateVal] = useState("");
  const [ceoPhone, setCeoPhone] = useState("");
  let country = useMemo(() => countryList().getData(), []);
  const [modalShow, setModalShow] = useState(false);
  const [syndicationGroup, setSyndicationGroup] = useState();
  const [memberDetails, setMemberDetails] = useState();
  const [confirmationModal, setConfirmationModal] = useState(false);

  const handleShowModal = () => setConfirmationModal(true);
  const handleCloseModal = () => setConfirmationModal(false);

  const navigate = useNavigate();

  const refs = {
    companyName: "companyName",
    companyWebsite: "companyWebsite",
    description: "description",
    executiveSummaryLink: "executiveSummaryLink",
    slideDeckLink: "slideDeckLink",
    pitchVideoLink: "pitchVideoLink",
    dealRoomLink: "dealRoomLink",
    gustProfileLink: "gustProfileLink",
    dealumProfileLink: "dealumProfileLink",
    amount: "amount",
    valuation: "valuation",
    ceoName: "ceoName",
    mobileNumber: "mobileNumber",
    dealLeadGroup: "dealLeadGroup",
    reason: "reason",
  };

  // remove the success state and replace with venture state
  // we have to use subscribedpromises no other ref needed
  function convertAmountStringToNumber(amountString) {
    const cleanedString = amountString.replace(/[$,]/g, "");

    return parseFloat(cleanedString);
  }

  const orderErrors = (errors, schema) => {
    const orderedErrors = {};
    const schemaKeys = Object.keys(schema.fields);

    schemaKeys.forEach((key) => {
      if (errors[key]) {
        orderedErrors[key] = errors[key];
      }
    });

    return orderedErrors;
  };

  const focusFirstErrorField = (errors) => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      const firstErrorKey = errorKeys[0];
      const firstErrorElement = document.getElementById(firstErrorKey);
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  };

  const submitData = (values) => {
    setIsLoading(true);
    const createProfile = post("/ventures", {
      firstName: values.firstName,
      lastName: values.lastName,
      amount: convertAmountStringToNumber(values.amount),
      ceoName: values.ceoName,
      committed: values.committed,
      companyWebsite: values.companyWebsite,
      companyName: values.companyName,
      country: values?.country || "United States",
      state: values.state,
      executiveSummaryLink: values?.executiveSummaryLink,
      pitchVideoLink: values?.pitchVideoLink,
      dealRoomLink: values?.dealRoomLink,
      gustProfileLink: values?.gustProfileLink,
      slideDeckLink: values.slideDeckLink,
      dealLeadEmail: values.dealLeadEmail,
      dealLeadName: values.dealLeadName,
      mobileNumber: values.mobileNumber,
      emailAddress: values.emailAddress,
      description: values.description,
      valuation: convertAmountStringToNumber(values.valuation),
      dealLeadGroup: values.dealLeadGroup,
      qualification: values.qualification,
      other:
        values.securityType === "Other" || values.securityType === "other"
          ? values.reason
          : null,
      dealStatus: values.dealStatus,
      securityType: values.securityType,
      isRound: false,
      currentVentureRound: {
        securityType: values.securityType,
        amount: convertAmountStringToNumber(values.amount),
        committed: values.committed,
        valuation: convertAmountStringToNumber(values.valuation),
        other:
          values.securityType === "Other" || values.securityType === "other"
            ? values.reason
            : null,
      },
    });
    createProfile.promise
      .then((response) => {
        setVenture(response?.data);
        setIsLoading(false);
        setSuccess(true);
        navigate("/");
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error);
      });
    subscribedPromises.current.push(createProfile);
  };

  function changeHandler(value) {
    setValue(value);
  }
  function stateHandler(state) {
    setStateVal(state);
  }
  function phoneHandler(phone) {
    setCeoPhone(phone);
  }
  function validate(values) {
    const { securityType } = values;
    if (securityType !== "Other") {
      values.reason = "";
    }
    // const { executiveSummaryLink, pitchVideoLink, slideDeckLink } = values;
    // if (!executiveSummaryLink && !pitchVideoLink && !slideDeckLink) {
    //   return { required: "At least one field is required" };
    // }
  }

  useEffect(() => {
    // wrap in a function fetch ventures loader part of the same function
    setIsLoading(true);
    const fetchVentures = get(`/ventures`);
    const fetchSyndicationGroups = get(`/nominations/${user?.email}`);
    Promise.all([fetchVentures.promise, fetchSyndicationGroups.promise])
      .then((response) => {
        if (!response?.[1]?.data?.syndicationGroups?.length) {
          navigate("/");
        } else {
          const ventureResponse = response[0];
          const syndicationGroupsResponse =
            response?.[1]?.data?.syndicationGroups;

          setSyndicationGroup(syndicationGroupsResponse);
          setMemberDetails(response?.[1]?.data?.member);
          setProfileData(ventureResponse?.data);
          // replace this with find
          const userVal = ventureResponse?.data?.filter(
            (data) => data?.createdBy === user?.userId
          );
          setFormData(userVal);
          setUser((prevUser) => ({
            ...prevUser,
            ventureId: userVal?.[0]?.id,
          }));
        }
        setIsLoading(false);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(fetchVentures, fetchSyndicationGroups);

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [user.userId]);

  const isDisabled = () => {
    // this will chnge accordingly
    return formData.length !== 0;
  };

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  return (
    <>
      <Header />
      <Container className="Main">
        <Row className="MainContent">
          <Col lg={2} className="my-5">
            <ProfileInfo />
          </Col>
          <Col lg={10}>
            <Row className="justify-content-md-center my-4">
              <Col xl={10} lg={10} md={12} sm={12} xs={12}>
                {formData?.length !== 0 &&
                  navigate(`/ventures/${formData[0]?.id}`)}
                <Row>
                  <Card>
                    <Card.Body>
                      <h2 className="text-center text-primary fs-3 my-2 mx-auto">
                        Application To ACA Syndication
                      </h2>
                      <div className="border-bottom border-dark w-75 m-auto"></div>

                      <p className="text-center text-dark   m-auto my-2">
                        A deal lead (an investor willing to coordinate due
                        diligence for syndication) is required to be included in
                        the packet. Your deal lead must be a member of the Angel
                        Capital Association, or a member of a member-group of
                        the Angel Capital Association.{" "}
                      </p>
                    </Card.Body>
                  </Card>
                </Row>

                <Row>
                  <Formik
                    validationSchema={VentureProfileSchema}
                    validate={validate}
                    disabled={isDisabled}
                    initialValues={{
                      emailAddress: user.email,
                      companyName: "",
                      companyWebsite: "",
                      dealRoomLink: "",
                      gustProfileLink: "",
                      dealumProfileLink: "",
                      country: "United States",
                      ceoName: "",
                      description: "",
                      amount: "",
                      mobileNumber: "",
                      qualification: [],
                      dealStatus: [],
                      securityType: "",
                      committed: "",
                      dealLeadEmail: memberDetails?.[1] || "",
                      dealLeadName: memberDetails?.[0] || "",
                      dealLeadGroup: "",
                      valuation: "",
                      slideDeckLink: "",
                      pitchVideoLink: "",
                      executiveSummaryLink: "",
                      state: "",
                      reason: "",
                    }}
                    onSubmit={(values, { resetForm, setValue }) => {
                      setModalShow(true);
                      submitData(values);

                      resetForm({ values: "" });
                      setValue(null);
                      setCeoPhone("");
                      setStateVal("");
                    }}
                  >
                    {({
                      errors,
                      touched,
                      isValid,
                      values,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      resetForm,
                      validateForm,

                      handleReset,
                      setFieldValue,
                    }) => (
                      <>
                        {!modalShow && (
                          <Form className="p-3" onSubmit={handleSubmit}>
                            <div className="d-none">
                              <FormLabel className="text-secondary">
                                Email Address
                                <span className="text-danger mx-1">*</span>
                              </FormLabel>
                              <Field
                                as={FormControl}
                                disabled={true}
                                name="emailAddress"
                                type="email"
                                isInvalid={
                                  errors.emailAddress && touched.emailAddress
                                }
                                isValid={
                                  values.emailAddress && !errors.emailAddress
                                }
                                value={values.emailAddress}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.emailAddress && touched.emailAddress ? (
                                <Form.Control.Feedback type="invalid">
                                  {errors.emailAddress}
                                </Form.Control.Feedback>
                              ) : null}
                            </div>

                            {/* company and key documents information */}
                            <Row className="mb-4">
                              <Card>
                                <Card.Body>
                                  <Row className={"mb-5"}>
                                    <Col xs={12}>
                                      <h4 className="text-primary">
                                        Company Information
                                      </h4>
                                    </Col>
                                    <Form.Group
                                      as={Col}
                                      sm={12}
                                      lg={6}
                                      className="my-2"
                                    >
                                      <FormLabel className="text-secondary">
                                        Company Name
                                        <span className="text-danger mx-1">
                                          *
                                        </span>
                                      </FormLabel>

                                      <Field
                                        as={FormControl}
                                        name="companyName"
                                        disabled={isDisabled()}
                                        className="form-control"
                                        type="text"
                                        isInvalid={
                                          errors.companyName &&
                                          touched.companyName
                                        }
                                        isValid={
                                          values.companyName &&
                                          !errors.companyName
                                        }
                                        value={values.companyName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id={refs.companyName}
                                      />
                                      {errors.companyName &&
                                      touched.companyName ? (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.companyName}
                                        </Form.Control.Feedback>
                                      ) : null}
                                    </Form.Group>

                                    <Form.Group
                                      as={Col}
                                      lg={6}
                                      sm={12}
                                      className="my-2"
                                    >
                                      <FormLabel className="text-secondary">
                                        Website
                                      </FormLabel>
                                      <Field
                                        name="companyWebsite"
                                        as={FormControl}
                                        disabled={formData.length !== 0}
                                        placeholder=""
                                        isInvalid={
                                          errors.companyWebsite &&
                                          touched.companyWebsite
                                        }
                                        isValid={
                                          values.companyWebsite &&
                                          !errors.companyWebsite
                                        }
                                        value={values.companyWebsite}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id={refs.companyWebsite}
                                      />
                                      {errors.companyWebsite &&
                                        touched.companyWebsite && (
                                          <Form.Control.Feedback type="invalid">
                                            {errors.companyWebsite}
                                          </Form.Control.Feedback>
                                        )}
                                    </Form.Group>

                                    <Form.Group
                                      as={Col}
                                      sm={12}
                                      lg={12}
                                      className="my-2"
                                    >
                                      <FormLabel className="text-secondary">
                                        Country
                                        <span className="text-danger mx-1">
                                          *
                                        </span>
                                      </FormLabel>
                                      <Form.Select
                                        as={Form.Control}
                                        value={value}
                                        name="country"
                                        isInvalid={
                                          errors.country && touched.country
                                        }
                                        isValid={
                                          values.country !== "" &&
                                          !errors.country &&
                                          touched.country
                                        }
                                        onChange={(event) => {
                                          changeHandler(event.target.value);
                                          setFieldValue(
                                            "country",
                                            event.target.value
                                          );
                                        }}
                                      >
                                        {country.map((el, key) => {
                                          return (
                                            <option key={key}>
                                              {el?.label}
                                            </option>
                                          );
                                        })}
                                        {errors.country && touched.country && (
                                          <Form.Control.Feedback type="invalid">
                                            {errors.country}
                                          </Form.Control.Feedback>
                                        )}
                                      </Form.Select>
                                    </Form.Group>

                                    {values.country === "United States" && (
                                      <Form.Group
                                        as={Col}
                                        sm={12}
                                        lg={12}
                                        className="my-2"
                                      >
                                        <FormLabel className="text-secondary">
                                          Enter State
                                        </FormLabel>
                                        <Form.Select
                                          as={Form.Control}
                                          value={stateVal}
                                          name="state"
                                          isInvalid={
                                            errors.state && touched.state
                                          }
                                          isValid={
                                            values.state !== "" &&
                                            !errors.state &&
                                            values?.state !== null
                                          }
                                          onChange={(event) => {
                                            stateHandler(event.target.value);
                                            setFieldValue(
                                              "state",
                                              event.target.value
                                            );
                                          }}
                                        >
                                          <option
                                            value=""
                                            disabled={values?.state}
                                          >
                                            Select State
                                          </option>
                                          {options.map((el, key) => {
                                            return (
                                              <option key={key}>
                                                {el?.name}
                                              </option>
                                            );
                                          })}
                                          {errors.state && touched.state && (
                                            <Form.Control.Feedback type="invalid">
                                              {errors.state}
                                            </Form.Control.Feedback>
                                          )}
                                        </Form.Select>
                                      </Form.Group>
                                    )}

                                    <Form.Group
                                      as={Col}
                                      lg={12}
                                      sm={12}
                                      className="my-2"
                                    >
                                      <FormLabel className="text-secondary">
                                        Description{" "}
                                        <span className="text-danger mx-1">
                                          *
                                        </span>
                                      </FormLabel>
                                      <p>
                                        Give us the 1-line (maximum of 140
                                        characters) pitch for your venture.
                                      </p>

                                      <FormControl
                                        as={"textarea"}
                                        style={{ resize: "none" }}
                                        name="description"
                                        placeholder="Give us the 1-line (maximum of 140 characters)"
                                        isInvalid={
                                          touched.description &&
                                          errors.description
                                        }
                                        isValid={
                                          values.description &&
                                          !errors.description
                                        }
                                        value={values.description}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={isDisabled()}
                                        id={refs.description}
                                      />
                                      {errors.description &&
                                      touched.description ? (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.description}
                                        </Form.Control.Feedback>
                                      ) : (
                                        ""
                                      )}
                                    </Form.Group>
                                  </Row>

                                  <Row>
                                    <Col xs={12}>
                                      <h4 className="text-primary">
                                        Key Documents
                                      </h4>
                                      <Form.Text>
                                        Kindly ensure that the investor are able
                                        to access the link.
                                        <span className="text-danger mx-1">
                                          *
                                        </span>
                                      </Form.Text>
                                    </Col>
                                    <Form.Group
                                      as={Col}
                                      lg={4}
                                      sm={12}
                                      className="my-2"
                                    >
                                      <FormLabel className="text-secondary">
                                        Executive Summary Link
                                      </FormLabel>
                                      <Field
                                        name="executiveSummaryLink"
                                        as={FormControl}
                                        disabled={isDisabled()}
                                        isInvalid={
                                          errors.executiveSummaryLink &&
                                          touched.executiveSummaryLink
                                        }
                                        isValid={
                                          values.executiveSummaryLink &&
                                          !errors.executiveSummaryLink
                                        }
                                        value={values.executiveSummaryLink}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id={refs.executiveSummaryLink}
                                      />
                                      {errors.executiveSummaryLink &&
                                        touched.executiveSummaryLink && (
                                          <Form.Control.Feedback type="invalid">
                                            {errors.executiveSummaryLink}
                                          </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                    <Form.Group
                                      as={Col}
                                      lg={4}
                                      sm={12}
                                      className="my-2"
                                    >
                                      <FormLabel className=" text-secondary">
                                        Pitch Deck Link
                                        <span className="text-danger mx-1">
                                          *
                                        </span>
                                      </FormLabel>
                                      <Field
                                        as={FormControl}
                                        name="slideDeckLink"
                                        isInvalid={
                                          touched.slideDeckLink &&
                                          errors.slideDeckLink
                                        }
                                        isValid={
                                          values.slideDeckLink &&
                                          !errors.slideDeckLink
                                        }
                                        disabled={isDisabled()}
                                        id={refs.slideDeckLink}
                                      />
                                      {touched.slideDeckLink &&
                                        errors.slideDeckLink && (
                                          <Form.Control.Feedback type="invalid">
                                            {errors.slideDeckLink}
                                          </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                    <Form.Group
                                      as={Col}
                                      sm={12}
                                      lg={4}
                                      className="my-2"
                                    >
                                      <FormLabel className="text-secondary">
                                        Pitch Video Link
                                      </FormLabel>
                                      <Field
                                        name="pitchVideoLink"
                                        as={FormControl}
                                        isInvalid={
                                          touched.pitchVideoLink &&
                                          errors.pitchVideoLink
                                        }
                                        isValid={
                                          values.pitchVideoLink &&
                                          !errors.pitchVideoLink
                                        }
                                        disabled={isDisabled()}
                                        id={refs.pitchVideoLink}
                                      />
                                      {touched.pitchVideoLink &&
                                      errors.pitchVideoLink ? (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.pitchVideoLink}
                                        </Form.Control.Feedback>
                                      ) : (
                                        ""
                                      )}
                                    </Form.Group>
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Row>

                            {/* deal information */}
                            <Row className="my-4">
                              <Card>
                                <Card.Body>
                                  <Row>
                                    <Col xs={12}>
                                      <h4 className="text-primary ">
                                        Deal Information
                                      </h4>
                                    </Col>
                                    <Form.Group
                                      as={Col}
                                      lg={12}
                                      sm={12}
                                      className="my-2"
                                    >
                                      <FormLabel className="text-secondary">
                                        Deal Room Link
                                      </FormLabel>
                                      <Field
                                        as={FormControl}
                                        name="dealRoomLink"
                                        isInvalid={
                                          errors.dealRoomLink &&
                                          touched.dealRoomLink
                                        }
                                        isValid={
                                          values.dealRoomLink &&
                                          !errors.dealRoomLink
                                        }
                                        onChange={handleChange}
                                        disabled={isDisabled()}
                                        onBlur={handleBlur}
                                        value={values.dealRoomLink}
                                        id={refs.dealRoomLink}
                                      />

                                      {errors.dealRoomLink &&
                                      touched.dealRoomLink ? (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.dealRoomLink}
                                        </Form.Control.Feedback>
                                      ) : (
                                        ""
                                      )}
                                    </Form.Group>

                                    <Form.Group
                                      as={Col}
                                      lg={12}
                                      sm={12}
                                      className="my-2"
                                    >
                                      <FormLabel className="text-secondary">
                                        Gust Profile Link
                                      </FormLabel>
                                      <Field
                                        as={FormControl}
                                        name="gustProfileLink"
                                        isInvalid={
                                          errors.gustProfileLink &&
                                          touched.gustProfileLink
                                        }
                                        isValid={
                                          values.gustProfileLink &&
                                          !errors.gustProfileLink
                                        }
                                        onChange={handleChange}
                                        disabled={isDisabled()}
                                        onBlur={handleBlur}
                                        value={values.gustProfileLink}
                                        id={refs.gustProfileLink}
                                      />

                                      {errors.gustProfileLink &&
                                      touched.gustProfileLink ? (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.gustProfileLink}
                                        </Form.Control.Feedback>
                                      ) : (
                                        ""
                                      )}
                                    </Form.Group>

                                    <Form.Group
                                      as={Col}
                                      lg={12}
                                      sm={12}
                                      className="my-2"
                                    >
                                      <FormLabel className="text-secondary">
                                        Dealum Profile Link
                                      </FormLabel>
                                      <Field
                                        as={FormControl}
                                        name="dealumProfileLink"
                                        isInvalid={
                                          errors.dealumProfileLink &&
                                          touched.dealumProfileLink
                                        }
                                        isValid={
                                          values.dealumProfileLink &&
                                          !errors.dealumProfileLink
                                        }
                                        onChange={handleChange}
                                        disabled={isDisabled()}
                                        onBlur={handleBlur}
                                        value={values.dealumProfileLink}
                                        id={refs.dealumProfileLink}
                                      />

                                      {errors.dealumProfileLink &&
                                      touched.dealumProfileLink ? (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.dealumProfileLink}
                                        </Form.Control.Feedback>
                                      ) : (
                                        ""
                                      )}
                                    </Form.Group>

                                    <Form.Group
                                      as={Col}
                                      lg={12}
                                      className="my-2"
                                    >
                                      <FormLabel className="text-secondary">
                                        Special Interest Qualification
                                      </FormLabel>
                                      <div
                                        role="group"
                                        aria-labelledby="checkbox-group"
                                      >
                                        <Row>
                                          {specialInterestQualifications.map(
                                            (qualification, key) => (
                                              <Form.Group
                                                as={Col}
                                                lg={6}
                                                sm={12}
                                                key={key}
                                              >
                                                <Form.Check
                                                  type={"checkbox"}
                                                  id={qualification.id}
                                                  value={qualification.value}
                                                  label={qualification.label}
                                                  name="qualification"
                                                  checked={values.qualification.includes(
                                                    qualification.value
                                                  )}
                                                  isValid={
                                                    !errors.qualification &&
                                                    touched.qualification
                                                  }
                                                  onChange={handleChange}
                                                  disabled={isDisabled()}
                                                ></Form.Check>
                                                <Form.Control.Feedback type="invalid">
                                                  {errors.qualification}
                                                </Form.Control.Feedback>
                                              </Form.Group>
                                            )
                                          )}
                                        </Row>
                                      </div>
                                    </Form.Group>

                                    <Form.Group
                                      as={Col}
                                      lg={12}
                                      className="my-2"
                                    >
                                      <FormLabel className="text-secondary">
                                        Syndication Group{" "}
                                        <span className="text-danger mx-1">
                                          *
                                        </span>
                                      </FormLabel>
                                      <div
                                        role="group"
                                        aria-labelledby="checkbox-group"
                                      >
                                        <Row>
                                          {syndicationGroup?.map((val, key) => (
                                            <Form.Group
                                              as={Col}
                                              lg={6}
                                              sm={12}
                                              key={key}
                                            >
                                              <Form.Check
                                                type={"checkbox"}
                                                name="syndicationGroups"
                                                key={key}
                                                label={val}
                                                value={val}
                                                checked={true}
                                                disabled
                                              ></Form.Check>
                                            </Form.Group>
                                          ))}
                                        </Row>
                                      </div>
                                    </Form.Group>
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Row>

                            {/* ceo and nominating information */}
                            <Row>
                              <Card>
                                <Card.Body>
                                  <Row>
                                    <Col xs={12}>
                                      <h4 className="text-primary">
                                        CEO Contact Information
                                      </h4>
                                    </Col>
                                    <Form.Group
                                      as={Col}
                                      sm={12}
                                      lg={6}
                                      className="my-2"
                                    >
                                      <FormLabel className="text-secondary">
                                        CEO Name
                                        <span className="text-danger mx-1">
                                          *
                                        </span>
                                      </FormLabel>
                                      <Field
                                        name="ceoName"
                                        type="text"
                                        as={FormControl}
                                        isInvalid={
                                          errors.ceoName && touched.ceoName
                                        }
                                        isValid={
                                          values.ceoName && !errors.ceoName
                                        }
                                        value={values.ceoName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={isDisabled()}
                                        id={refs.ceoName}
                                      />
                                      {errors.ceoName && touched.ceoName ? (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.ceoName}
                                        </Form.Control.Feedback>
                                      ) : (
                                        ""
                                      )}
                                    </Form.Group>
                                    <Form.Group
                                      as={Col}
                                      lg={6}
                                      sm={12}
                                      className="my-2"
                                    >
                                      <FormLabel className="text-secondary">
                                        CEO Mobile Number
                                        <span className="text-danger mx-1">
                                          *
                                        </span>
                                      </FormLabel>
                                      <PhoneInput
                                        as={FormControl}
                                        disabled={isDisabled()}
                                        country={"us"}
                                        inputStyle={{
                                          height: "38px",
                                          width: "100%",
                                        }}
                                        value={ceoPhone}
                                        name="mobileNumber"
                                        onChange={(ceoPhone) => {
                                          // phoneHandler(ceoPhone);
                                          setFieldValue(
                                            "mobileNumber",
                                            ceoPhone
                                          );
                                          // values.mobileNumber = ceoPhone;
                                        }}
                                        onBlur={handleBlur}
                                        inputProps={{
                                          id: refs.mobileNumber,
                                        }}
                                      />

                                      {errors.mobileNumber &&
                                      touched.mobileNumber ? (
                                        <Form.Text
                                          type="invalid"
                                          className="text-danger"
                                        >
                                          This is a required field
                                        </Form.Text>
                                      ) : (
                                        ""
                                      )}
                                    </Form.Group>
                                  </Row>

                                  <Row className="mt-5">
                                    <Col xs={12}>
                                      <h4 className="text-primary ">
                                        Nominating Investor Information
                                      </h4>
                                    </Col>
                                    <Form.Group
                                      as={Col}
                                      lg={6}
                                      sm={12}
                                      className="my-2"
                                    >
                                      <FormLabel className="text-secondary">
                                        Nominating Investor's Angel Group/Fund
                                        <span className="text-danger mx-1">
                                          *
                                        </span>
                                      </FormLabel>
                                      <Field
                                        name="dealLeadGroup"
                                        type="text"
                                        as={FormControl}
                                        isInvalid={
                                          errors.dealLeadGroup &&
                                          touched.dealLeadGroup
                                        }
                                        isValid={
                                          values.dealLeadGroup &&
                                          !errors.dealLeadGroup
                                        }
                                        disabled={isDisabled()}
                                        value={values.dealLeadGroup}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id={refs.dealLeadGroup}
                                      />

                                      {errors.dealLeadGroup &&
                                      touched.dealLeadGroup ? (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.dealLeadGroup}
                                        </Form.Control.Feedback>
                                      ) : (
                                        ""
                                      )}
                                    </Form.Group>
                                    <Form.Group
                                      as={Col}
                                      lg={6}
                                      sm={12}
                                      className="my-2"
                                    >
                                      <FormLabel className="text-secondary">
                                        Nominating Investor's Name
                                        <span className="text-danger mx-1">
                                          *
                                        </span>
                                      </FormLabel>
                                      <Field
                                        name="dealLeadName"
                                        disabled={
                                          values.dealLeadName || isDisabled()
                                        }
                                        type="text"
                                        as={FormControl}
                                        isInvalid={
                                          errors.dealLeadName &&
                                          touched.dealLeadName
                                        }
                                        value={values.dealLeadName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isValid={
                                          values.dealLeadName &&
                                          !errors.dealLeadName
                                        }
                                      />

                                      {errors.dealLeadName &&
                                      touched.dealLeadName ? (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.dealLeadName}
                                        </Form.Control.Feedback>
                                      ) : (
                                        ""
                                      )}
                                    </Form.Group>

                                    <Form.Group
                                      as={Col}
                                      sm={12}
                                      lg={12}
                                      className="my-2"
                                    >
                                      <FormLabel className="text-secondary">
                                        Nominating Investor's Email
                                        <span className="text-danger mx-1">
                                          *
                                        </span>
                                      </FormLabel>
                                      <Field
                                        as={FormControl}
                                        name="dealLeadEmail"
                                        type="email"
                                        isInvalid={
                                          errors.dealLeadEmail &&
                                          touched.dealLeadEmail
                                        }
                                        isValid={
                                          values.dealLeadEmail &&
                                          !errors.dealLeadEmail
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.dealLeadEmail}
                                        disabled={
                                          values.dealLeadEmail || isDisabled()
                                        }
                                      />
                                      {errors.dealLeadEmail &&
                                      touched.dealLeadEmail ? (
                                        <Form.Control.Feedback type="invalid">
                                          {errors.dealLeadEmail}
                                        </Form.Control.Feedback>
                                      ) : (
                                        ""
                                      )}
                                    </Form.Group>

                                    <Form.Group
                                      as={Col}
                                      lg={12}
                                      sm={12}
                                      className="my-2"
                                    ></Form.Group>
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Row>

                            {/* round information */}
                            <Row className="my-4">
                              <Card>
                                <Card.Body>
                                  <Row>
                                    <Col xs={12}>
                                      <h4 className="text-primary">
                                        New Round Information
                                      </h4>
                                    </Col>
                                    <Form.Group
                                      as={Col}
                                      lg={6}
                                      sm={12}
                                      className="my-2"
                                    >
                                      <FormLabel className=" text-secondary">
                                        Raise Amount
                                        <span className="text-danger mx-1">
                                          *
                                        </span>
                                      </FormLabel>
                                      <p className="text-dark">
                                        The total amount you are raising in this
                                        round
                                      </p>
                                      <FormGroup>
                                        <NumericFormat
                                          allowNegative={false}
                                          customInput={Form.Control}
                                          decimalScale={0}
                                          thousandSeparator={true}
                                          prefix={"$"}
                                          onChange={handleChange}
                                          name="amount"
                                          placeholder="Enter Amount"
                                          onBlur={handleBlur}
                                          isInvalid={
                                            errors.amount && touched.amount
                                          }
                                          isValid={
                                            values.amount && !errors.amount
                                          }
                                          value={values.amount}
                                          id={refs.amount}
                                        />
                                        {errors.amount && touched.amount ? (
                                          <Form.Control.Feedback type="invalid">
                                            {errors.amount}
                                          </Form.Control.Feedback>
                                        ) : (
                                          ""
                                        )}
                                      </FormGroup>
                                    </Form.Group>

                                    <Form.Group
                                      as={Col}
                                      lg={6}
                                      sm={12}
                                      className="my-2"
                                    >
                                      <FormLabel className="text-secondary">
                                        Valuation/Cap
                                        <span className="text-danger mx-1">
                                          *
                                        </span>
                                      </FormLabel>
                                      <p className="text-dark">
                                        PRE-Money valuation or PRE-Money cap
                                      </p>
                                      <FormGroup>
                                        <NumericFormat
                                          allowNegative={false}
                                          customInput={Form.Control}
                                          thousandSeparator={true}
                                          decimalScale={0}
                                          prefix={"$"}
                                          onChange={handleChange}
                                          name="valuation"
                                          placeholder="Enter Valuation/Cap"
                                          onBlur={handleBlur}
                                          isInvalid={
                                            errors.valuation &&
                                            touched.valuation
                                          }
                                          isValid={
                                            values.valuation &&
                                            !errors.valuation
                                          }
                                          value={values.valuation}
                                          id={refs.valuation}
                                        />
                                        {errors.valuation &&
                                        touched.valuation ? (
                                          <Form.Control.Feedback type="invalid">
                                            {errors.valuation}
                                          </Form.Control.Feedback>
                                        ) : (
                                          ""
                                        )}
                                      </FormGroup>
                                    </Form.Group>

                                    <FormLabel className="text-primary fw-bold">
                                      Please write out the full amount. Example
                                      1 million should be entered as 1000000
                                    </FormLabel>
                                    <Form.Group
                                      as={Col}
                                      lg={12}
                                      sm={12}
                                      className="my-2"
                                    >
                                      <FormLabel className="text-secondary">
                                        %Committed
                                        <span className="text-danger mx-1">
                                          *
                                        </span>
                                      </FormLabel>
                                      <div
                                        role="group"
                                        aria-labelledby="checkbox-group"
                                      >
                                        <Row>
                                          {committedPercentage.map(
                                            (percentage, key) => (
                                              <Form.Group
                                                as={Col}
                                                sm={6}
                                                key={key}
                                              >
                                                <Form.Check
                                                  disabled={isDisabled()}
                                                  inline
                                                  type="radio"
                                                  name="committed"
                                                  id={percentage.id}
                                                  checked={
                                                    values.committed ===
                                                    percentage.value
                                                  }
                                                  value={percentage.value}
                                                  label={percentage.label}
                                                  onChange={(e) => {
                                                    e.currentTarget.blur();
                                                    handleChange(e);
                                                    setFieldValue(
                                                      "committed",
                                                      percentage.value
                                                    );
                                                  }}
                                                  onBlur={handleBlur}
                                                />
                                              </Form.Group>
                                            )
                                          )}
                                        </Row>
                                      </div>
                                      {errors.committed && touched.committed ? (
                                        <Form.Text
                                          type="invalid"
                                          className="text-danger"
                                        >
                                          {errors.committed}
                                        </Form.Text>
                                      ) : (
                                        ""
                                      )}
                                    </Form.Group>

                                    <Form.Group
                                      as={Col}
                                      sm={12}
                                      lg={12}
                                      className="my-3"
                                    >
                                      <FormLabel className="text-secondary">
                                        Security Type
                                        <span className="text-danger mx-1">
                                          *
                                        </span>
                                      </FormLabel>
                                      <Row>
                                        {securityTypes.map((type, key) => (
                                          <Form.Group as={Col} sm={6} key={key}>
                                            <Form.Check
                                              inline
                                              type="radio"
                                              disabled={isDisabled()}
                                              name="securityType"
                                              id={type.id}
                                              value={type.value}
                                              label={type.label}
                                              checked={
                                                values.securityType ===
                                                type.value
                                              }
                                              onChange={(e) => {
                                                e.currentTarget.blur();
                                                handleChange(e);
                                                setFieldValue(
                                                  "securityType",
                                                  e.target.value
                                                );
                                              }}
                                              onBlur={handleBlur}
                                            />
                                          </Form.Group>
                                        ))}
                                        {values.securityType === "Other" && (
                                          <Form.Group
                                            as={Col}
                                            sm={12}
                                            className="my-2"
                                          >
                                            <FormLabel className="mt-2 text-secondary">
                                              Please specify:
                                            </FormLabel>
                                            <span className="text-danger mx-1">
                                              *
                                            </span>

                                            <Field
                                              type="text"
                                              name="reason"
                                              as={Form.Control}
                                              isValid={
                                                !errors.reason &&
                                                touched.reason &&
                                                values.reason
                                              }
                                              isInvalid={
                                                errors.reason && touched.reason
                                              }
                                              disabled={isDisabled()}
                                              value={
                                                values.securityType === "other"
                                                  ? "Other"
                                                  : values.reason
                                              }
                                              onChange={(e) => {
                                                handleChange(e);
                                              }}
                                              onBlur={handleBlur}
                                              id={refs.reason}
                                            />

                                            {errors.reason &&
                                              touched.reason && (
                                                <Form.Control.Feedback type="invalid">
                                                  {errors.reason}
                                                </Form.Control.Feedback>
                                              )}
                                          </Form.Group>
                                        )}
                                      </Row>
                                      {errors.securityType &&
                                      touched.securityType ? (
                                        <Form.Text
                                          type="invalid"
                                          className="text-danger"
                                        >
                                          {errors.securityType}
                                        </Form.Text>
                                      ) : (
                                        ""
                                      )}
                                    </Form.Group>
                                  </Row>
                                  <hr />
                                  <div className="d-grid gap-3 ">
                                    <Button
                                      type="submit"
                                      className="btn btn-primary text-white w-50 m-auto"
                                      onClick={() =>
                                        validateForm().then((errors) => {
                                          const updatedErrors = { ...errors };
                                          if (values.securityType !== "Other") {
                                            delete updatedErrors.reason;
                                          }
                                          if (
                                            !!Object.keys(updatedErrors)
                                              ?.length &&
                                            Object.values(updatedErrors).every(
                                              (value) => value
                                            )
                                          ) {
                                            focusFirstErrorField(
                                              orderErrors(
                                                updatedErrors,
                                                VentureProfileSchema
                                              )
                                            );
                                          } else {
                                            handleShowModal();
                                          }
                                        })
                                      }
                                    >
                                      Submit
                                    </Button>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Row>
                          </Form>
                        )}
                        <Modal
                          show={confirmationModal}
                          onHide={handleCloseModal}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            Kindly test your links in an incognito browser
                            window before submitting.
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={handleCloseModal}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                handleCloseModal();
                                resetForm({ values: "" });
                                handleReset({ values: "" });
                                if (values?.country !== "United States") {
                                  values.state = "";
                                }
                                setValue("");
                                setCeoPhone("");
                                setStateVal("");
                                setModalShow(true);
                                submitData(values);
                                handleSubmit();
                              }}
                              variant="primary"
                            >
                              Submit
                            </Button>
                          </Modal.Footer>
                        </Modal>
                        {modalShow && (
                          <>
                            <p className="text-center">
                              Kindly ensure that the investor are able to access
                              the link.
                            </p>
                            <Alert variant="success">
                              <h4>Success!</h4>
                              <p>Your Form has been Submitted.</p>
                              <Link to={`/ventures/${venture?.id}`}>
                                View Profile
                              </Link>
                            </Alert>
                          </>
                        )}
                      </>
                    )}
                  </Formik>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};
export default VentureProfile;
