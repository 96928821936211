import React, { useState } from "react";
import { Navbar, Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import "./Help.scss";
const Help = () => {
  const [show, setShow] = useState(false);

  const handleShowModal = () => {
    setShow(true);
  };

  const handleCloseModal = () => {
    setShow(false);
  };

  return (
    <>
      <Navbar.Text
        className="me-3 pointer text-white"
        onClick={handleShowModal}
      >
        <span className="material-icons-outlined m-18 me-2">help_outline</span>{" "}
        Help
      </Navbar.Text>
      <Modal show={show} onHide={handleCloseModal} className="right" size="xl">
        <Modal.Header closeButton className="bg-light">
          <Modal.Title as={"div"}>
            <h4>Help Center</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactPlayer width={"100%"} url={"https://youtu.be/YG1a3_Tf7jM"} />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <div>
            <p>
              <strong>For More Information:
              <a href="mailto:team@angelcatalyst.com"> team@angelcatalyst.com </a></strong>
            </p>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Help;
